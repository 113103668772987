import React from 'react'
import { Footer } from '@shared/components/Footer'
import { NewReleaseBanner } from '@shared/components/NewReleaseBanner'
import Sidebar from '@katana/components/shared/Sidebar'
import TopNavigation from '@katana/components/shared/TopNavigation'
import '@shared/styles/default-layout.css'

function KatanaLayout ({ children, includeTopMenu = true }) {
  const topMenu = includeTopMenu ? <TopNavigation /> : <></>

  return (
    <div className='main-container'>
      <Sidebar />
      <div className='main-content'>
        {topMenu}
        <NewReleaseBanner />
        {children}
        <Footer />
      </div>
    </div>
  )
}

export default React.memo(KatanaLayout)
