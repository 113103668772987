import * as XLSX from 'xlsx'
import { flattenObject } from '@src/shared/services/csvService'

const SHEET_NAMES = {
  purchaseOrders: 'Purchase Order Sample',
  locations: 'Locations',
  suppliers: 'Suppliers',
  products: 'Products',
  taxRates: 'Tax Rates',
}

const calculateColumnWidth = (value) => {
  const defaultWidth = 10
  const padding = 2
  if (value === null) return defaultWidth
  return Math.max(value.toString().length + padding, 10)
}

export const formatToExcel = (data) => {
  const workbook = XLSX.utils.book_new()

  Object.entries(data).forEach(([key, value]) => {
    const sheetData = value[key]
    const flattenedData = sheetData.map((object) => flattenObject(object))
    const headers = Array.from(
      new Set(flattenedData.reduce((allKeys, object) =>
        allKeys.concat(Object.keys(object)), [])),
    )

    const sheetRows = [
      headers,
      ...flattenedData.map((object) =>
        headers.map((header) => object[header] ?? '')),
    ]

    const worksheet = XLSX.utils.aoa_to_sheet(sheetRows)

    const columnWidths = {}
    headers.forEach((header, index) => {
      columnWidths[index] = calculateColumnWidth(header)
    })
    sheetRows.slice(1).forEach((row) => {
      row.forEach((cell, index) => {
        const cellWidth = calculateColumnWidth(cell)
        if (cellWidth > columnWidths[index]) {
          columnWidths[index] = cellWidth
        }
      })
    })
    worksheet['!cols'] = Object.values(columnWidths).map((width) => ({ width }))

    XLSX.utils.book_append_sheet(workbook, worksheet, SHEET_NAMES[key] || key)
  })

  const excelBuffer = XLSX.write(workbook, {
    type: 'array',
    bookType: 'xlsx',
  })

  return excelBuffer
}

export const downloadExcel = (data, filename) => {
  const buffer = formatToExcel(data)
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = filename
  link.click()
  window.URL.revokeObjectURL(url)
}
