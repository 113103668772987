import { backendRequest } from '@shared/utils/axiosInstance'

export const reportService = {
  // GET
  fetchInventoryAllocations: async ({ historyDate, companyId }) => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: `/obius/views/inventory-allocations?history_date=${historyDate}&company_id=${companyId}&limit=100000`,
      })
      return response.data
    } catch (error) {
      console.error('Error Fetching Inventory Allocations:', error)
      throw error
    }
  },
  fetchInventoryHistories: async ({ historyDate, companyId }) => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: `/obius/obius-inventory-histories?populate=product,location&fields=qty_stock,qty_committed,qty_incoming,cost,product.product_name,product.product_variant_name,product.product_sku,product.is_purchasable,location.location_name&history_date=${historyDate}&company_id=${companyId}&limit=100000`,
      })
      return response.data
    } catch (error) {
      console.error('Error Fetching Inventory Histories:', error)
      throw error
    }
  },
}
