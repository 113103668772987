import { useState, useCallback, useEffect, useContext } from 'react'
import { DataUpdateContext } from '@shared/context/context'
import { fetchProduction, updateProduction } from '@shared/services/productionService'
import { fetchActiveBoms, fetchBomMaterials } from '@shared/services/bomService'
import { fetchLocations } from '@shared/services/locationService'
import { objectToId } from '@shared/utils'
import { JOB_STATUS } from '@bom/utils/constants'

export const useProductionDetails = (
  productionId,
  setIsSaving,
) => {
  const minOutputQuantity = 1
  const [bom, setBom] = useState({
    location_id: undefined,
    output_quantity: minOutputQuantity,
  })
  const [boms, setBoms] = useState([])
  const [products, setProducts] = useState([])
  const [materials, setMaterials] = useState([])
  const [locations, setLocations] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingComponents, setIsLoadingComponents] = useState(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const [isLocationError, setIsLocationError] = useState(false)
  const [showOutputProductRequiredError, setShowOutputProductRequiredError] = useState(false)
  const [error, setError] = useState(null)
  const [showBanner, setShowBanner] = useState(false)
  const [showErrorBanner, setShowErrorBanner] = useState(false)
  const { companyId, appBridge, isAppBridgeReady, jobStatus } = useContext(DataUpdateContext)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    if (!productionId || !companyId || !isAppBridgeReady) return

    try {
      const { locations } = await fetchLocations(companyId, appBridge)
      setLocations(locations)

      if (productionId === 'new') {
        const { boms } = await fetchActiveBoms(companyId, appBridge)

        setBoms(boms)
        setProducts(
          boms.map((bom) => ({
            ...bom.output_product,
            refBomId: bom.bom_id,
          })),
        )
      } else {
        const production = await fetchProduction(productionId, appBridge)

        setBom({
          ...production,
          location_id: production.output_location_id,
        })
        setMaterials(production.consumptions)
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }, [productionId, companyId, appBridge, isAppBridgeReady])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    setIsSaveButtonDisabled(
      !bom?.output_product ||
      isNaN(bom?.output_quantity) ||
      !bom?.location_id,
    )
  }, [bom])

  useEffect(() => {
    switch (jobStatus) {
      case JOB_STATUS.FAILED:
        setShowBanner(false)
        setShowErrorBanner(true)
        break
      case JOB_STATUS.SUCCEEDED:
        setShowBanner(false)
        setShowErrorBanner(false)
        break
      case JOB_STATUS.IN_PROGRESS:
        setShowBanner(true)
        break
      default:
    }
  }, [jobStatus])

  const prepareProductionDataToSave = useCallback((
    locationId,
    productId,
    productionDate,
    bomId,
    quantity,
    companyId,
    materials,
  ) => {
    const productionId = objectToId({ outputLocationId: locationId, outputProductId: productId, productionDate })
    const consumptionId = objectToId({ productId, productionId })

    return {
      production_date: productionDate,
      production_id: productionId,
      output_location_id: locationId,
      bom_id: bomId,
      output_quantity: quantity,
      company_id: companyId,
      output_product_id: productId,
      status: 'pending',
      consumptions: materials.map(({ bom_id: _, external_material_id: __, ...material }) => ({
        ...material,
        production_id: productionId,
        consumption_id: consumptionId,
        quantity: material.quantity * quantity,
        location_id: locationId,
      })),
    }
  }, [])

  const handleSave = useCallback(async () => {
    setIsSaving(true)
    setError(null)

    try {
      const productionDataToSave = prepareProductionDataToSave(
        bom.location_id,
        bom.output_product.product_id,
        new Date().toISOString(),
        bom.bom_id,
        bom.output_quantity,
        companyId,
        materials,
      )

      const updatedProduction = await updateProduction(productionDataToSave, appBridge)

      return updatedProduction.production_id
    } catch (err) {
      setError(err.message)
    } finally {
      setIsSaving(false)
    }
  }, [appBridge, prepareProductionDataToSave, setIsSaving, bom, companyId, materials])

  const handleQuantityChange = useCallback((newQuantity) => {
    setBom((prevBom) => ({
      ...prevBom,
      output_quantity: newQuantity,
    }))
  }, [])

  const handleLocationChange = useCallback((locationId) => {
    setIsLocationError(false)

    setBom((prevBom) => ({
      ...prevBom,
      location_id: locationId,
    }))

    if (!bom?.output_product_id) setShowOutputProductRequiredError(true)
  }, [bom])

  const handleOutputProductSave = useCallback(async ([newProduct]) => {
    if (!newProduct) return
    if (!bom?.location_id) setIsLocationError(true)

    const newBom = boms.find((bom) => bom.bom_id === newProduct.refBomId)

    setBom((prevBom) => ({
      ...newBom,
      output_quantity: prevBom?.output_quantity,
      location_id: prevBom?.location_id,
    }))

    try {
      setIsLoadingComponents(true)
      const { materials: _materials } = await fetchBomMaterials(newBom.bom_id, appBridge)
      setMaterials(_materials)
    } catch (err) {
      setError(err.message)
    } finally {
      setIsLoadingComponents(false)
    }
  }, [bom, boms, appBridge])

  const handleRemoveOutputProduct = useCallback(() => {
    setBom((prevBom) => ({
      output_quantity: minOutputQuantity,
      location_id: prevBom?.location_id,
    }))
    setMaterials([])
    setShowOutputProductRequiredError(true)
  }, [])

  return {
    bom,
    products,
    materials,
    locations,
    isLoading,
    isLoadingComponents,
    isSaveButtonDisabled,
    isLocationError,
    error,
    handleSave,
    handleQuantityChange,
    handleLocationChange,
    handleOutputProductSave,
    handleRemoveOutputProduct,
    showOutputProductRequiredError,
    showBanner,
    showErrorBanner,
    setShowBanner,
    setShowErrorBanner,
  }
}
