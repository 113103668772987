import { useEffect, useState } from 'react'
import { Box, Banner } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export const NewReleaseBanner = () => {
  const { t } = useTranslation()
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (!registration) return
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          console.debug('controllerchange')
          setShowBanner(true)
        })
      })
    }
  }, [])

  return (
    <div>
      {showBanner && (
        <Box align='center'>
          <Box padding='200' maxWidth='950px' align='left'>
            <Banner
              title={t('banner.new_version.title')}
              onDismiss={() => setShowBanner(false)}
              tone='info'
            >
              {t('banner.new_version.content')}
            </Banner>
          </Box>
        </Box>
      )}
    </div>
  )
}
