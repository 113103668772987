import {
  Box,
  Select,
  SkeletonBodyText,
  InlineStack,
  LegacyCard,
} from '@shopify/polaris'

export function SnapshotDateSelector ({
  label,
  options,
  onChange,
  onClick,
  value,
  isOpen,
  isLoading,
  isLabelInline = true,
}) {
  let content

  if (isLoading) {
    content = (
      <Box minWidth='187px'>
        <LegacyCard sectioned>
          <SkeletonBodyText lines={1} />
        </LegacyCard>
      </Box>
    )
  } else {
    content = (
      <Select
        label={label}
        options={options}
        onChange={onChange}
        onClick={onClick}
        value={value}
        open={isOpen}
        labelInline={isLabelInline}
        disabled={options.length === 0}
      />
    )
  }

  return (
    <InlineStack>
      <Box paddingInlineStart='500'>
        {content}
      </Box>
    </InlineStack>
  )
}
