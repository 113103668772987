import { parse } from 'papaparse'

export const flattenObject = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const fullKey = prefix ? `${prefix}.${key}` : key
    const value = obj[key]

    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      Object.assign(acc, flattenObject(value, fullKey))
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        Object.assign(acc, flattenObject(item, `${fullKey}[${index}]`))
      })
    } else {
      acc[fullKey] = value
    }

    return acc
  }, {})
}

const reconstructObject = (flatObject) => {
  const reconstructed = {}

  Object.keys(flatObject).forEach((key) => {
    const keys = key.split('.')

    keys.reduce((acc, currKey, index) => {
      if (!acc[currKey]) {
        acc[currKey] = index === keys.length - 1 ? flatObject[key] : {}
      }

      return acc[currKey]
    }, reconstructed)
  })

  return reconstructed
}

export const formatToCsv = (data) => {
  const flattenedData = data.map((object) => flattenObject(object))
  const headers = Array.from(
    new Set(flattenedData.reduce((allKeys, object) => allKeys.concat(Object.keys(object)), [])),
  )

  // Properly escape CSV values
  const escapeCSV = (value) => {
    if (value === null || value === undefined) return ''
    const stringValue = String(value)
    if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
      return `"${stringValue.replace(/"/g, '""')}"`
    }
    return stringValue
  }

  const csvRows = [
    headers.join(','),
    ...flattenedData.map((object) => headers.map((header) => escapeCSV(object[header])).join(','),
    ),
  ]
  return csvRows.join('\n')
}

export const parseCsvToObjects = (csvData) => {
  const { data, errors } = parse(csvData, {
    header: true,
    skipEmptyLines: true,
  })
  if (errors.length) throw new Error(`CSV parsing error: ${errors.map((e) => e.message).join(', ')}`)
  return data.map((row) => reconstructObject(row))
}

export const createBlob = (csvData, fileName) => {
  const BOM = '\uFEFF' // Add BOM (Byte Order Mark) to indicate UTF-8 encoding
  const csvWithBOM = BOM + csvData
  const blob = new Blob([csvWithBOM], { type: 'text/csv;charset=utf-8;' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}
