import React from 'react'
import DataBackupCard from '@src/apps/katana/components/data-backup/DataBackupCard'
import {
  Page,
  Text,
  Layout,
  BlockStack,
} from '@shopify/polaris'
import '@shared/styles/ag-grid.css'
import { useTranslation } from 'react-i18next'

const DataBackupView = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <BlockStack gap="400">
        <Text variant="heading3xl" as="h1">
          {t('data_backup.title')}
        </Text>
        <Layout>
          <Layout.Section>
            <DataBackupCard />
          </Layout.Section>
        </Layout>
      </BlockStack>
    </Page>
  )
}

export default DataBackupView
