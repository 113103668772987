import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { getCookieDomain } from '@shared/utils'
import {
  BlockStack,
  Card,
  Box,
  Text,
  TextField,
  Form,
  FormLayout,
  Link,
  Button,
  InlineStack,
  Bleed,
  Banner,
  Page,
  Icon,
} from '@shopify/polaris'
import '@src/shared/styles/login.css'
import { useTranslation } from 'react-i18next'
import { LanguageSelector } from '@katana/components/shared/LanguageSelector'
import { ArrowRightIcon } from '@shopify/polaris-icons'
import { authService } from '@shared/services/authService'
import { TOS_URL } from '@shared/utils/constant'
import LoginFooter from '@shared/components/LoginFooter'
import LoginHeader from '@shared/components/LoginHeader'
import LoginEmptyState from '@shared/components/LoginEmptyState'

function SignUpView () {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState(null)
  const [isSignUpDisabled, setIsSignUpDisabled] = useState(true)
  const [isSignUpButtonLoading, setIsSignUpButtonLoading] = useState(false)
  const [bannerState, setBannerState] = useState({
    show: false,
    status: '',
    message: '',
  })
  const [isEmptyState, setIsEmptyState] = useState(true)

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language)
    Cookies.set('preferredLanguage', language, { expires: 365 })
  }

  useEffect(() => {
    const loadTimeMs = 400
    const timer = setTimeout(() => {
      setIsEmptyState(false)
    }, loadTimeMs)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const savedLanguage = Cookies.get('preferredLanguage')
    if (savedLanguage) i18n.changeLanguage(savedLanguage)
    else i18n.changeLanguage('en')
  }, [i18n])

  useEffect(() => {
    if (Cookies.get('authToken')) {
      navigate('/dashboard')
    }
  }, [navigate])

  useEffect(() => {
    if (email && password && firstName && lastName && confirmPassword && !confirmPasswordError) return setIsSignUpDisabled(false)
    return setIsSignUpDisabled(true)
  }, [email, password, firstName, lastName, confirmPassword, confirmPasswordError])

  useEffect(() => {
    if (password === confirmPassword || !password || !confirmPassword) return setConfirmPasswordError(null)
    setConfirmPasswordError(t('sign_up.confirm_password_error'))
  }, [t, password, confirmPassword])

  const handleSignUp = async () => {
    setBannerState({ ...bannerState, show: false })
    setIsSignUpButtonLoading(true)

    try {
      const signUpData = await authService.signUp({
        data: {
          user: {
            first_name: firstName,
            last_name: lastName,
            users_email: email,
          },
          password,
        },
      })
      if (!signUpData?.token) throw new Error('Invalid token in sign up')

      const cookieDomain = getCookieDomain()
      Cookies.set('authToken', signUpData.token, { domain: cookieDomain, expires: 365 })

      window.location.reload()
    } catch (error) {
      if (error.status === 409) { // Email already exists for this user
        setBannerState({
          show: true,
          status: 'critical',
          message: t('sign_up.email_already_exists'),
        })
        return
      }
      console.error('Sign up error:', error)
      setBannerState({
        show: true,
        status: 'critical',
        message: error.message,
      })
    } finally {
      setIsSignUpButtonLoading(false)
    }
  }

  const content = (
    <Page
      primaryAction={
        <LanguageSelector
          value={i18n.language}
          onChange={handleLanguageChange}
        />
      }
    >
      <Card padding='1000'>
        <BlockStack gap='4' inlineAlign='center'>
          <BlockStack align='center'>
            <LoginHeader
              title={t('sign_up.title')}
              subtitle={t('sign_up.subtitle')}
            />
            {bannerState.show && (
              <Box paddingBlockEnd='600' maxWidth='400px'>
                <Banner
                  title={bannerState.message}
                  onDismiss={() => setBannerState({ ...bannerState, show: false })}
                  tone={bannerState.status}
                />
              </Box>
            )}
            <Box width='400px'>
              <Form onSubmit={handleSignUp}>
                <FormLayout>
                  <TextField
                    label={t('sign_up.email')}
                    type='email'
                    value={email}
                    onChange={setEmail}
                    autoComplete='email'
                    inputMode='email'
                  />
                  <Bleed marginBlockEnd='300'>
                    <InlineStack align='space-between'>
                      <TextField
                        label={t('sign_up.first_name')}
                        type='text'
                        value={firstName}
                        onChange={setFirstName}
                        autoComplete='given-name'
                        inputMode='text'
                        width='200px'
                      />
                      <TextField
                        label={t('sign_up.last_name')}
                        type='text'
                        value={lastName}
                        onChange={setLastName}
                        autoComplete='family-name'
                        inputMode='text'
                      />
                    </InlineStack>
                  </Bleed>
                  <Text as='p' variant='bodyXs'>
                    {t('sign_up.name_notice')}
                  </Text>
                  <TextField
                    label={t('sign_up.password')}
                    type='password'
                    value={password}
                    onChange={setPassword}
                    autoComplete='current-password'
                    inputMode='text'
                  />
                  <TextField
                    label={t('sign_up.confirm_password')}
                    type='password'
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                    autoComplete='off'
                    inputMode='text'
                    error={confirmPasswordError}
                  />
                </FormLayout>
                <Box paddingBlock='600'>
                  <InlineStack align='center'>
                    <Button
                      variant='primary'
                      fullWidth
                      size='large'
                      disabled={isSignUpDisabled}
                      loading={isSignUpButtonLoading}
                      submit
                    >
                      {t('sign_up.action_button_label')}
                    </Button>
                  </InlineStack>
                </Box>
              </Form>
              <BlockStack align='center'>
                <Box>
                  <InlineStack gap='200' blockAlign='center' align='center'>
                    <Text as='span' variant='bodyXs'>
                      {t('sign_up.existing_account')}
                    </Text>
                    <Link
                      url='/login'
                      removeUnderline
                    >
                      <InlineStack blockAlign='center'>
                        <Text as='span' variant='bodyXs'>
                          {t('login.title')}
                        </Text>
                        <Icon
                          source={ArrowRightIcon}
                          tone="base"
                        />
                      </InlineStack>
                    </Link>
                  </InlineStack>
                </Box>
                <Box paddingBlock='600'>
                  <InlineStack blockAlign='center' align='left' gap='100'>
                    <Text as='p' variant='bodyXs' tone='subdued'>
                      {t('sign_up.proceeding_notice')}
                    </Text>
                    <Link
                      url={TOS_URL}
                      removeUnderline
                      target='_blank'
                    >
                      <Text variant='bodyXs'>
                        {t('sign_up.terms_of_service')}
                      </Text>
                    </Link>
                  </InlineStack>
                </Box>
                <LoginFooter />
              </BlockStack>
            </Box>
          </BlockStack>
        </BlockStack>
      </Card>
    </Page>
  )

  const displayContent = () => isEmptyState ? <LoginEmptyState nBodyLines={12} nFooterLines={2} /> : content

  return (
    <div id='login-container'>
      {displayContent()}
    </div>
  )
}

export default SignUpView
