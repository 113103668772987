import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonGroup,
  Button,
} from '@shopify/polaris'
import { BILLING_INTERVAL } from '@shared/utils/constant'

const BillingInterval = ({ billingInterval, handleMonthlyClick, handleYearlyClick }) => {
  const { t } = useTranslation()

  return (
    <ButtonGroup variant="segmented" fullWidth>
      <Button
        size='large'
        pressed={billingInterval === BILLING_INTERVAL.MONTHLY.id}
        onClick={handleMonthlyClick}
      >
        {t('plan.price_selection.monthly')}
      </Button>
      <Button
        size='large'
        pressed={billingInterval === BILLING_INTERVAL.YEARLY.id}
        onClick={handleYearlyClick}
      >
        {t('plan.price_selection.yearly')}
      </Button>
    </ButtonGroup>
  )
}

export default BillingInterval
