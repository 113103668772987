import { backendRequest } from '@shared/utils/axiosInstance'
import { objectToId } from '@shared/utils'

export const cronJobService = {
  fetchAlertSettings: async (companyId, appBridge) => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: `/job/cron-jobs?company_id=${companyId}&job_type=replenishment_alert&fields=uuid,data,active,schedule`,
        data: {},
        appBridge,
      })
      return response.data
    } catch (error) {
      console.error('Error fetching alert settings:', error)
      throw error
    }
  },

  saveAlertSettings: async (companyId, appBridge, schedule, emailAddress) => {
    try {
      const response = await backendRequest({
        method: 'PUT',
        url: `/job/cron-jobs/${objectToId({ companyId, jobType: 'replenishment_alert' })}`,
        data: {
          name: `Replenishment Alert for company ${companyId}`,
          category: 'alert',
          job_type: 'replenishment_alert',
          company_id: companyId,
          schedule,
          active: true,
          data: { email: emailAddress },
        },
        appBridge,
      })
      return response.data
    } catch (error) {
      console.error('Error saving alert settings:', error)
      throw error
    }
  },
}
