import { Frame, Modal, Text } from '@shopify/polaris'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const CreateProductionConfirmationModal = ({
  open,
  setIsOpen,
  onSave,
}) => {
  const { t } = useTranslation()

  const handleCloseModal = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])
  const handleSaveModal = useCallback(() => {
    setIsOpen(false)
    onSave()
  }, [setIsOpen, onSave])

  return (
    <div style={{ height: '500px' }}>
      <Frame>
        <Modal
          open={open}
          onClose={handleCloseModal}
          title={t('productions.creation_confirmation_modal.title')}
          primaryAction={{
            content: t('productions.creation_confirmation_modal.primary_action'),
            onAction: handleSaveModal,
          }}
          secondaryActions={[
            {
              content: t('productions.creation_confirmation_modal.secondary_action'),
              onAction: handleCloseModal,
            },
          ]}
        >
          <Modal.Section>
            <Text>
              {t('productions.creation_confirmation_modal.description')}
            </Text>
          </Modal.Section>
        </Modal>
      </Frame>
    </div>
  )
}
