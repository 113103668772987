import { AgGridTable } from '@src/shared/components/ag-grid/AGGridTable'

export const ReportGrid = ({
  error,
  noDataTextPlaceholder,
  data,
  columns,
  sideBar,
  onGridReady,
  gridOptions,
  gridSize = 'l',
}) => {
  return (
    <div className={`items-section obius-grid-size-${gridSize}`}>
      {error && <p>{noDataTextPlaceholder}</p>}
      {!error && (
        <AgGridTable
          rowData={data}
          colDefs={columns}
          grandTotalRow="top"
          pivotMode
          sideBar={sideBar}
          onGridReady={onGridReady}
          gridOptions={gridOptions}
        />
      )}
    </div>
  )
}
