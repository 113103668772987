import React from 'react'

import { BellIcon, GearIcon } from '@standalone/img/icons'
import '@shared/styles/top-navigation.css'
import { CompanySelector } from '@src/shared/components/CompanySelector'
import { InlineStack } from '@shopify/polaris'

const TopNavigation = () => {
  return (
    <div className='top-navigation'>
      <div className='search-bar'>
        {/* <i className="material-icons">search</i>
        <span>Search an item</span> */}
      </div>
      <InlineStack>
        <div className='action-buttons'>
          <button className='icon-button'>
            <span className='icon'><BellIcon /></span>
          </button>
          <button className='icon-button'>
            <span className='icon'><GearIcon /></span>
          </button>
        </div>
        <CompanySelector />
      </InlineStack>
    </div>
  )
}

export default TopNavigation
