import { ExportButton } from '@src/shared/components/ag-grid/params/buttons/ExportButton'
import { ResetButton } from '@src/shared/components/ag-grid/params/buttons/ResetButton'

export const ReportActionButtons = ({ gridRef }) => {
  return (
    <div className='exports-container'>
      <div className='button-items-section'>
        <div className='button-container'>
          <ExportButton gridRef={gridRef} />
        </div>
        <div>
          <ResetButton gridRef={gridRef} />
        </div>
      </div>
    </div>
  )
}
