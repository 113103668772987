import React, { useState, useCallback } from 'react'
import {
  Page,
  InlineGrid,
  SkeletonPage,
  SkeletonBodyText,
  Toast,
  Frame,
  BlockStack,
  Box,
  Banner,
} from '@shopify/polaris'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ProductSelectionModal } from '@bom/components/ProductSelectionModal'
import { useProductionDetails } from '@bom/hooks/useProductionDetails'
import { MaterialsCard } from '@bom/components/MaterialsCard'
import { ProductCard } from '@bom/components/ProductCard'
import { LocationSelectionCard } from '@bom/components/LocationSelectionCard'
import { CreateProductionConfirmationModal } from '@bom/components/CreateProductionConfirmationModal'
import { HELP_DESK_URL } from '@shared/utils/constant'

export const ProductionDetailsView = () => {
  const { id: productionId } = useParams()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const isReadonly = productionId !== 'new'

  const [showOutputProductModal, setShowOutputProductModal] = useState(false)
  const [showCreateProductionConfirmationModal, setShowCreateProductionConfirmationModal] = useState(false)
  const [outputProductSearchValue, setOutputProductSearchValue] = useState('')
  const [forbiddenProductIds, setForbiddenProductIds] = useState([])

  function formatDate (timestamp, language = i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language || 'en') {
    return new Date(timestamp).toLocaleDateString(language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  const {
    bom,
    products,
    materials,
    locations,
    isLoading,
    isLoadingComponents,
    isSaveButtonDisabled,
    isLocationError,
    error,
    handleSave,
    handleQuantityChange,
    handleLocationChange,
    handleOutputProductSave,
    handleRemoveOutputProduct,
    showOutputProductRequiredError,
    showBanner,
    showErrorBanner,
    setShowBanner,
    setShowErrorBanner,
  } = useProductionDetails(productionId, setIsSaving, forbiddenProductIds, setForbiddenProductIds)

  const handleOutputProductSearch = useCallback((value) => {
    setOutputProductSearchValue(value)
    setShowOutputProductModal(true)
  }, [])

  const handleOutputProductModalClose = useCallback(() => {
    setShowOutputProductModal(false)
    setOutputProductSearchValue('')
  }, [])

  const handleSaveWithToast = useCallback(async () => {
    setIsSaving(true)
    try {
      const newProductionId = await handleSave()

      setShowToast(true)

      if (newProductionId && productionId === 'new') navigate(`/productions/${newProductionId}`)
    } catch (error) {
      console.error('Error saving production:', error)
    }
  }, [handleSave, navigate, productionId])

  const handleShowCreateProductionConfirmationModal = useCallback(() => {
    setShowCreateProductionConfirmationModal(true)
  }, [])

  const toggleToast = useCallback(() => setShowToast((active) => !active), [])

  const handleBackClick = useCallback(() => {
    navigate('/productions')
  }, [navigate])

  if (isLoading) {
    return (
      <SkeletonPage primaryAction>
        <InlineGrid columns={['1fr', '2fr']} gap="400">
          <SkeletonBodyText lines={3} />
          <SkeletonBodyText lines={5} />
        </InlineGrid>
      </SkeletonPage>
    )
  }

  if (error) {
    return <Page title={t('productions.createProduction')}>{error}</Page>
  }

  const pageTitle = bom?.output_product
    ? `${bom.output_product.product_name}`
    : t('productions.createProduction')

  const pageSubTitle = bom?.production_date
    ? `${formatDate(bom.production_date)}`
    : ''

  const toastMarkup = showToast
    ? (
      <Toast content={t('productions.productionSaved')} onDismiss={toggleToast} />
    )
    : null

  const primaryAction = isReadonly
    ? undefined
    : {
      content: t('productions.save'),
      onAction: handleShowCreateProductionConfirmationModal,
      loading: isSaving,
      disabled: isSaveButtonDisabled,
    }

  return (
    <Frame>
      <Page
        title={pageTitle}
        subtitle={pageSubTitle}
        primaryAction={primaryAction}
        backAction={{ content: t('productions.back'), onAction: handleBackClick }}
      >
        {showBanner && (
          <div>
            <Box paddingBlock='400'>
              <Banner
                title={t('banner.bom.job_status')}
                onDismiss={() => setShowBanner(false)}
                tone='warning'
              />
            </Box>
          </div>
        )}
        {showErrorBanner && (
          <div>
            <Box paddingBlock='400'>
              <Banner
                title={t('error_banner.title')}
                onDismiss={() => setShowErrorBanner(false)}
                tone='warning'
                action={{
                  content: t('error_banner.button'),
                  url: HELP_DESK_URL,
                }}
              >
                <Box paddingBlockStart='400' paddingBlockEnd='400'>
                  {t('error_banner.content')}
                </Box>
              </Banner>
            </Box>
          </div>
        )}
        <InlineGrid
          columns={['oneThird', 'twoThirds']}
          gap="400"
          alignItems="start"
        >
          <BlockStack gap="200">
            <ProductCard
              bom={bom}
              onSelectOutputProduct={handleOutputProductSearch}
              onRemoveOutputProduct={handleRemoveOutputProduct}
              showRequiredError={showOutputProductRequiredError}
              onQuantityChange={handleQuantityChange}
              isReadonly={isReadonly}
            />
            <LocationSelectionCard
              onChange={handleLocationChange}
              locationId={bom?.location_id}
              locations={locations || []}
              isLocationError={isLocationError}
              isReadonly={isReadonly}
            />
          </BlockStack>
          <MaterialsCard
            materials={materials || []}
            isReadonly={isReadonly}
            outputQuantity={bom?.output_quantity || 1}
            isLoadingComponents={isLoadingComponents}
          />
        </InlineGrid>
        <ProductSelectionModal
          open={showOutputProductModal}
          onClose={handleOutputProductModalClose}
          onSave={handleOutputProductSave}
          products={products}
          title={t('productions.all_products')}
          allowMultiple={false}
          initialSearchValue={outputProductSearchValue}
          initialSelectedProducts={[]}
          forbiddenProductIds={['']}
          onUpdateForbiddenIds={() => {}}
          hidden={isReadonly}
          doFilterOnlyFinishedProduct
        />
        <CreateProductionConfirmationModal
          open={showCreateProductionConfirmationModal}
          setIsOpen={setShowCreateProductionConfirmationModal}
          onSave={handleSaveWithToast}
        />
        {toastMarkup}
      </Page>
    </Frame>
  )
}
