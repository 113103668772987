import { useState, useEffect, useCallback } from 'react'
import {
  OptionList,
  Popover,
  Button,
} from '@shopify/polaris'
import { LanguageTranslateIcon } from '@shopify/polaris-icons'

export function LanguageSelector ({ value, onChange }) {
  const [selectedOption, setSelectedOption] = useState(value || 'en')
  const [popoverActive, setPopoverActive] = useState(false)

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  )

  const options = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
  ]

  useEffect(() => {
    setSelectedOption(value)
  }, [value])

  const handleSelection = (selected) => {
    setSelectedOption(selected)
    setPopoverActive(false)
    onChange(selected)
  }

  const activator = (
    <Button icon={LanguageTranslateIcon} onClick={togglePopoverActive} disclosure>
      {selectedOption}
    </Button>
  )

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
    >
      <OptionList
        options={options}
        selected={selectedOption}
        onChange={handleSelection}
      />
    </Popover>
  )
}
