import { useTranslation } from 'react-i18next'
import { dateTimeFormatter } from '@shared/utils/formatter'

export const ReportTitle = ({ lastUpdate, title }) => {
  const { t, i18n } = useTranslation()
  const updatedAt = dateTimeFormatter(lastUpdate, i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language || 'en')

  return (
    <div className='dashboard-section'>
      <div className='dashboard-title dashboard-dropdown'>
        <div>
          <h1>{title}</h1>
          <h2 className='subtitle is-6 mt-1'>
            {t('common.updated')} {updatedAt}
          </h2>
        </div>
      </div>
    </div>
  )
}
