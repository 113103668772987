import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  BlockStack,
  Card,
  Box,
  TextField,
  Form,
  FormLayout,
  Button,
  InlineStack,
  Banner,
  Page,
} from '@shopify/polaris'
import '@src/shared/styles/login.css'
import { useTranslation } from 'react-i18next'
import { LanguageSelector } from '@katana/components/shared/LanguageSelector'
import { BACKTRACK_STATES } from '@shared/utils/constant'
import { authService } from '@shared/services/authService'
import LoginFooter from '@shared/components/LoginFooter'
import LoginHeader from '@shared/components/LoginHeader'
import LoginEmptyState from '@shared/components/LoginEmptyState'

function SendResetPasswordEmailView () {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const [email, setEmail] = useState('')
  const [isSendEmailDisabled, setIsSendEmailDisabled] = useState(true)
  const [isSendEmailButtonLoading, setIsSendEmailButtonLoading] = useState(false)
  const [bannerState, setBannerState] = useState({
    show: false,
    status: '',
    message: '',
  })
  const [isEmptyState, setIsEmptyState] = useState(true)

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language)
    Cookies.set('preferredLanguage', language, { expires: 365 })
  }

  useEffect(() => {
    if (Cookies.get('authToken')) {
      navigate('/dashboard')
    }
  }, [navigate])

  useEffect(() => {
    const loadTimeMs = 400
    const timer = setTimeout(() => {
      setIsEmptyState(false)
    }, loadTimeMs)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const savedLanguage = Cookies.get('preferredLanguage')
    if (savedLanguage) i18n.changeLanguage(savedLanguage)
    else i18n.changeLanguage('en')
  }, [i18n])

  useEffect(() => {
    if (email) return setIsSendEmailDisabled(false)
    return setIsSendEmailDisabled(true)
  }, [email])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const emailParam = params.get('email')
    setEmail(emailParam)
  }, [location])

  const handleSendResetPasswordEmail = async () => {
    try {
      if (!email) throw new Error('Email must be defined')

      setBannerState({ ...bannerState, show: false })
      setIsSendEmailButtonLoading(true)

      await authService.resetPassword({
        data: {
          email,
          language: Cookies.get('preferredLanguage'),
        },
        requestHeaders: {
          'x-app': process.env.REACT_APP_SHOPIFY_APP,
        },
      })

      const successStateParam = encodeURIComponent(BACKTRACK_STATES.RESET_PASSWORD_BY_EMAIL.SUCCESS)
      navigate(`/login?backtrack_state=${successStateParam}`)
    } catch (error) {
      console.error('Reset password error:', error)
      setBannerState({
        show: true,
        status: 'critical',
        message: t('reset_password_by_email.send_email_failure'),
      })
    } finally {
      setIsSendEmailButtonLoading(false)
    }
  }

  const content = (
    <Page
      backAction={{
        url: '/login',
      }}
      primaryAction={
        <LanguageSelector
          value={i18n.language}
          onChange={handleLanguageChange}
        />
      }
    >
      <Card padding='1000'>
        <BlockStack gap='4' inlineAlign='center'>
          <BlockStack align='center'>
            <LoginHeader
              title={t('reset_password_by_email.title')}
              subtitle={t('reset_password_by_email.subtitle')}
            />
            {bannerState.show && (
              <Box paddingBlockEnd='600' maxWidth='400px'>
                <Banner
                  title={bannerState.message}
                  onDismiss={() => setBannerState({ ...bannerState, show: false })}
                  tone={bannerState.status}
                />
              </Box>
            )}
            <Box width='400px'>
              <Form onSubmit={handleSendResetPasswordEmail}>
                <FormLayout>
                  <TextField
                    label={t('reset_password_by_email.email')}
                    type='email'
                    value={email}
                    onChange={setEmail}
                    autoComplete='email'
                    inputMode='email'
                  />
                </FormLayout>
                <Box paddingBlock='600'>
                  <InlineStack align='center'>
                    <Button
                      variant='primary'
                      fullWidth
                      size='large'
                      disabled={isSendEmailDisabled}
                      loading={isSendEmailButtonLoading}
                      submit
                    >
                      {t('reset_password_by_email.send_reset_password_email')}
                    </Button>
                  </InlineStack>
                </Box>
              </Form>
              <LoginFooter />
            </Box>
          </BlockStack>
        </BlockStack>
      </Card>
    </Page>
  )

  const displayContent = () => isEmptyState ? <LoginEmptyState nBodyLines={4} nFooterLines={0} nPageActions={2} /> : content

  return (
    <div id='login-container'>
      {displayContent()}
    </div>
  )
}

export default SendResetPasswordEmailView
