import {
  BlockStack,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris'
import { HELP_DESK_URL, TOS_URL } from '@shared/utils/constant'
import { useTranslation } from 'react-i18next'

function LoginFooter () {
  const { t } = useTranslation()

  return (
    <BlockStack align='center'>
      <InlineStack gap='200' blockAlign='center'>
        <Link
          url={HELP_DESK_URL}
          monochrome
          removeUnderline
          target='_blank'
        >
          <Text as='span' variant='bodyXs' tone='subdued'>
            {t('login.help')}
          </Text>
        </Link>
        <Link
          url={TOS_URL}
          monochrome
          removeUnderline
          target='_blank'
        >
          <Text as='span' variant='bodyXs' tone='subdued'>
            {t('login.terms')}
          </Text>
        </Link>
      </InlineStack>
    </BlockStack>
  )
}

export default LoginFooter
