import React from 'react'
import { Button } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import {
  XCircleIcon,
} from '@shopify/polaris-icons'

export const ResetButton = ({ gridRef }) => {
  const { t } = useTranslation()

  const resetFilters = () => {
    if (!gridRef.current) return
    gridRef.current.setFilterModel(null)
  }

  return (
    <Button icon={XCircleIcon} onClick={resetFilters} destructive>
      {t('button.clear')}
    </Button>
  )
}
