import { backendRequest } from '@shared/utils/axiosInstance'

const handleErrorMessagePropagation = ({ error, header }) => {
  let errorMessage
  if (error?.response?.data?.errors) {
    errorMessage = error.response.data.errors
      .map((err) => err.message)
      .filter(Boolean)
      .join('; ')
  } else {
    errorMessage = error?.response?.data?.error?.message
  }
  const errorStatus = error?.status
  throw new Error(`${errorStatus}—${header}—${errorMessage}`)
}

export const katanaService = {
  // GET
  fetchPurchaseOrderSample: async () => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: '/katana-api/purchase-orders',
      })
      return response.data
    } catch (error) {
      handleErrorMessagePropagation({
        error,
        header: 'Katana Service: Error Fetching PO Sample',
      })
    }
  },
  fetchSuppliersSample: async () => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: '/katana-api/suppliers?limit=1',
        requestHeaders: {
          'x-obius-format': 'csv',
        },
      })
      return response.data
    } catch (error) {
      handleErrorMessagePropagation({
        error,
        header: 'Katana Service: Error Fetching Suppliers Sample',
      })
    }
  },
  fetchSuppliers: async () => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: '/katana-api/suppliers',
        requestHeaders: {
          'x-obius-format': 'csv',
        },
      })
      return response.data
    } catch (error) {
      handleErrorMessagePropagation({
        error,
        header: 'Katana Service: Error Fetching Suppliers',
      })
    }
  },
  fetchSnapshotDates: async ({ companyId, limit }) => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: `/job/jobs?job_type=katana_end_to_end&status=succeeded&limit=${limit}&company_id=${companyId}&sort=-start_date&fields=start_date`,
      })
      return response.data
    } catch (error) {
      throw new Error(`Katana Service: Error fetching snapshot dates: ${error.message}`)
    }
  },

  // POST
  createPurchaseOrders: async ({ data }) => {
    try {
      const response = await backendRequest({
        method: 'POST',
        url: '/katana-api/purchase-orders',
        requestHeaders: {
          'x-obius-format': 'csv',
        },
        data,
      })
      return response.data
    } catch (error) {
      handleErrorMessagePropagation({
        error,
        header: 'Katana Service: Error Creating PO',
      })
    }
  },
  createSuppliers: async ({ data }) => {
    try {
      const response = await backendRequest({
        method: 'POST',
        url: '/katana-api/suppliers',
        requestHeaders: {
          'x-obius-format': 'csv',
        },
        data,
      })
      return response.data
    } catch (error) {
      handleErrorMessagePropagation({
        error,
        header: 'Katana Service: Error Creating Supplier',
      })
    }
  },
}
