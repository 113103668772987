import React from 'react'
import { Page, Card, Text, BlockStack, Link, Button, InlineStack, List } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EcosystemCard } from '@shared/components/EcosystemCard'
import { HELP_DESK_URL, OBIUS_URL } from '@shared/utils/constant'

export const WelcomeView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleNavigateToReplenishment = () => {
    navigate('/replenishment')
  }

  const handleNavigateToSettings = () => {
    navigate('/settings')
  }

  return (
    <Page
      hasSubtitleMaxWidth
      title={t('welcome.replen.title')}
    >
      <BlockStack gap='400'>
        <Card sectioned>
          <BlockStack gap='300' inlineAlign="start">
            <Link url={OBIUS_URL} target="_blank">
              Obius.co
            </Link>
            <Text variant="headingXl" as="h2">
              {`👋 ${t('welcome.replen.intro_card.title')}`}
            </Text>
            <Text variant="headingMd" as="p">
              {t('welcome.replen.intro_card.content')}
            </Text>
            <Text variant="headingLg" as="h3">
              {t('welcome.replen.intro_card.subtitle1')}
            </Text>
            <List>
              <List.Item>{t('welcome.replen.intro_card.features.email')}</List.Item>
              <List.Item>{t('welcome.replen.intro_card.features.alerts')}</List.Item>
              <List.Item>{t('welcome.replen.intro_card.features.quantity')}</List.Item>
            </List>
            <Text variant="headingLg" as="h3">
              {t('welcome.replen.intro_card.subtitle2')}
            </Text>
            <List type='number'>
              <List.Item>
                <Text variant="headingMd" as="p">
                  {t('welcome.replen.intro_card.steps.check.title')}
                </Text>
                {t('welcome.replen.intro_card.steps.check.content')}
              </List.Item>
              <List.Item>
                <Text variant="headingMd" as="p">
                  {t('welcome.replen.intro_card.steps.configure_email.title')}
                </Text>
                {t('welcome.replen.intro_card.steps.configure_email.content')}
              </List.Item>
              <List.Item>
                <Text variant="headingMd" as="p">
                  {t('welcome.replen.intro_card.steps.compute.title')}
                </Text>
                {t('welcome.replen.intro_card.steps.compute.content')}
              </List.Item>
            </List>
            <InlineStack gap='300' blockAlign='center'>
              <Button variant="primary" onClick={handleNavigateToReplenishment}>{t('welcome.replen.intro_card.button.get_started')}</Button>
              <Button onClick={handleNavigateToSettings}>{t('welcome.replen.intro_card.button.configure')}</Button>
            </InlineStack>
          </BlockStack>
        </Card>
        <EcosystemCard isReplenInstalled />
        <Card sectioned>
          <BlockStack gap='300' inlineAlign="start">
            <Text variant='headingXl' as="h2">
              {t('welcome.replen.setup_card.title')}
            </Text>
            <Text variant='headingSm' as="p">
              {t('welcome.replen.setup_card.content')}
            </Text>
            <InlineStack gap='300' blockAlign='center'>
              <Button
                variant="primary"
                url={HELP_DESK_URL}
                external
              >
                {t('button.send_us_a_message')}
              </Button>
              <Button
                url='https://meetings.hubspot.com/maxime-lavoie/obius-15min?uuid=0e6235af-29b5-4859-8b8f-5d9da4b33ba5'
                external
              >
                {t('button.book_a_call')}
              </Button>
              <Link url={OBIUS_URL} target="_blank">
                {t('link.visit_our_website')}
              </Link>
            </InlineStack>
          </BlockStack>
        </Card>
      </BlockStack>
    </Page>
  )
}
