import { backendRequest } from '@shared/utils/axiosInstance'

export const fetchProducts = async (companyId, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/products?company_id=${companyId}&sort=product_name,product_variant_name&status=active&limit=50000`,
      data: {},
      appBridge,
    })
    return response.data.products
  } catch (error) {
    throw new Error(`Error fetching products: ${error.message}`)
  }
}

export const fetchProductVariants = async (companyId, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/products?company_id=${companyId}&fields=product_name,product_variant_name,external_variant_id&status=active&limit=50000`,
      data: {},
      appBridge,
    })
    return response?.data
  } catch (error) {
    throw new Error('Error fetching product variants', error.message)
  }
}
