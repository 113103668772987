import { backendRequest } from '@shared/utils/axiosInstance'

export const userService = {
  modifyUser: async ({ id, data }) => {
    try {
      const response = await backendRequest({
        method: 'PATCH',
        url: `/user/${id}`,
        data,
      })
      return response.data
    } catch (error) {
      console.error('Error modifying user:', error.message)
      throw error
    }
  },
  modifyUserPassword: async ({ id, data, requestHeaders }) => {
    try {
      const response = await backendRequest({
        method: 'PUT',
        url: `/user/${id}/password`,
        data,
        requestHeaders,
      })
      return response.data
    } catch (error) {
      console.error('Error modifying user password:', error.message)
      throw error
    }
  },
}
