import { useState, useCallback, useEffect, useContext } from 'react'
import { DataUpdateContext } from '@shared/context/context'
import { fetchProductionsList } from '@shared/services/productionService'
import { FREE_PLAN_PRODUCTION_LIMIT } from '@bom/utils/constants'
import { LARGE_REQUEST_LIMIT } from '@shared/utils/constant'

export const useProductionsList = (limit = 20) => {
  const [productions, setProductions] = useState([])
  const [filteredProductions, setFilteredProductions] = useState([])
  const [currentPageProductions, setCurrentPageProductions] = useState([])
  const [totalProductions, setTotalProductions] = useState(0)
  const [offset, setOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [queryValue, setQueryValue] = useState('')
  const [hasNextPage, setHasNextPage] = useState(false)
  const [hasPreviousPage, setHasPreviousPage] = useState(false)
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)
  const { companyId, appBridge, isAppBridgeReady, subscriptionPlan } = useContext(DataUpdateContext)

  const fetchProductionsData = useCallback(async () => {
    if (!companyId || !isAppBridgeReady) return
    const offset = 0
    setIsLoading(true)
    setError(null)
    try {
      const planLimit = subscriptionPlan === 'free' ? FREE_PLAN_PRODUCTION_LIMIT : LARGE_REQUEST_LIMIT
      const data = await fetchProductionsList(companyId, planLimit, offset, appBridge)

      setProductions(data.productions)
      setFilteredProductions(data.productions)
      setTotalProductions(data.total)
      setCurrentPageProductions(data.productions.slice(offset, offset + limit))
      setHasNextPage(0 + limit < data.total)
      setHasPreviousPage(offset > 0)
      if (subscriptionPlan === 'free' && data.total >= FREE_PLAN_PRODUCTION_LIMIT) setIsCreateButtonDisabled(true)
    } catch (err) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }, [companyId, appBridge, isAppBridgeReady, limit, subscriptionPlan])

  useEffect(() => {
    fetchProductionsData()
  }, [fetchProductionsData])

  const handleNextPage = useCallback(() => {
    const newOffset = Math.min(offset + limit, totalProductions)

    setOffset(newOffset)
    setHasNextPage(newOffset + limit < totalProductions)
    setHasPreviousPage(newOffset > 0)
    setCurrentPageProductions(filteredProductions.slice(newOffset, newOffset + limit))
  }, [offset, limit, totalProductions, filteredProductions])

  const handlePreviousPage = useCallback(() => {
    const newOffset = Math.max(0, offset - limit)

    setOffset(newOffset)
    setHasNextPage(newOffset + limit < totalProductions)
    setHasPreviousPage(newOffset > 0)
    setCurrentPageProductions(filteredProductions.slice(newOffset, newOffset + limit))
  }, [offset, limit, totalProductions, filteredProductions])

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value)
    setOffset(0)

    const newFilteredProductions = productions.filter((production) =>
      production.output_product.product_name?.toLowerCase().includes(value.toLowerCase()) ||
      production.output_product.product_variant_name?.toLowerCase().includes(value.toLowerCase()),
    )

    setFilteredProductions(newFilteredProductions)
    setTotalProductions(newFilteredProductions.length)
    setCurrentPageProductions(newFilteredProductions.slice(0, limit))
    setHasNextPage(limit < newFilteredProductions.length)
    setHasPreviousPage(false)
  }, [productions, limit])

  return {
    productions,
    filteredProductions,
    currentPageProductions,
    totalProductions,
    isLoading,
    error,
    offset,
    handleNextPage,
    handlePreviousPage,
    hasNextPage,
    hasPreviousPage,
    handleFiltersQueryChange,
    queryValue,
    isCreateButtonDisabled,
  }
}
