import Cookies from 'js-cookie'
import { useState, useCallback, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { DataUpdateContext } from '@shared/context/context'
import {
  BlockStack,
  Button,
  Popover,
  ActionList,
  Bleed,
  Box,
  LegacyCard,
  SkeletonBodyText,
} from '@shopify/polaris'
import {
  BlankFilledIcon,
  BlankIcon,
} from '@shopify/polaris-icons'
import { userService } from '@src/shared/services/userService'
import { getUserId } from '@shared/utils/getUserInfo'
import { companyService } from '@src/shared/services/companyService'

export function CompanySelector () {
  const { t } = useTranslation()
  const { companyId, setCompanyId } = useContext(DataUpdateContext)
  const [active, setActive] = useState(false)
  const [availableCompanies, setAvailableCompanies] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const recentCompaniesCookieStackKey = 'recentCompanies'
  const [recentCompaniesStack, setRecentCompaniesStack] = useState([])

  const toggleActive = useCallback(() => setActive((active) => !active), [])

  const activator = (
    <Button onClick={toggleActive} disclosure>
      {availableCompanies.filter((company) => company.company_id === companyId)?.[0]?.company_name}
    </Button>
  )

  const setRecentCompany = useCallback(({ newCompany }) => {
    try {
      const cookieValue = Cookies.get(recentCompaniesCookieStackKey)
      const recentCompaniesStack = cookieValue ? JSON.parse(cookieValue) : []
      const filteredCompaniesStack = recentCompaniesStack.filter((recentCompany) => recentCompany !== newCompany)
      const newRecentCompaniesStack = [newCompany, ...filteredCompaniesStack]
      const stringifiedCompaniesStack = JSON.stringify(newRecentCompaniesStack)
      Cookies.set(recentCompaniesCookieStackKey, stringifiedCompaniesStack)
      setRecentCompaniesStack(newRecentCompaniesStack)
    } catch (error) {
      console.warn(error.message)
      setRecentCompaniesStack([])
    }
  }, [])

  const fetchAvailableCompanies = useCallback(async () => {
    if (!companyId) return
    try {
      setRecentCompany({ newCompany: companyId })
      const { companies } = await companyService.fetchCompanyIds()
      if (!companies) throw new Error('Failed to fetch Company IDs')
      setAvailableCompanies(companies)
    } catch (error) {
      setAvailableCompanies([])
      if (error.status === 401) return // normal user, return early without logging error
      console.error('Failed to fetch Company IDs:', error)
    } finally {
      setIsLoading(false)
    }
  }, [companyId, setRecentCompany])

  useEffect(() => {
    fetchAvailableCompanies()
  }, [fetchAvailableCompanies])

  const switchCompany = useCallback(async ({ newCompanyId }) => {
    try {
      const userId = getUserId()
      if (!userId) throw new Error('User ID is undefined in JWT')
      const updatedUser = await userService.modifyUser({
        id: userId,
        data: {
          company_id: newCompanyId,
        },
      })
      setCompanyId(updatedUser.company_id)
    } catch (error) {
      console.error(`Cannot switch company to ${newCompanyId}: ${error.message}`)
    } finally {
      toggleActive()
    }
  }, [setCompanyId, toggleActive])

  const content = (
    <Bleed marginBlockEnd='1000'>
      <BlockStack align='center' inlineAlign='center'>
        <Bleed marginBlockEnd='300'>
          <Popover
            active={active}
            activator={activator}
            autofocusTarget="first-node"
            onClose={toggleActive}
          >
            <ActionList
              actionRole="menuitem"
              sections={[
                {
                  title: t('top_menu.company_selector.current_company_title'),
                  items: availableCompanies
                    .filter((company) => company.company_id === companyId)
                    .map((company) => ({
                      content: company.company_name,
                      icon: BlankFilledIcon,
                      onAction: () => toggleActive(),
                    })),
                },
                {
                  title: t('top_menu.company_selector.available_companies_title'),
                  items: [
                    ...new Set(
                      [
                        ...recentCompaniesStack,
                        ...availableCompanies.map((company) => company.company_id),
                      ].filter((id) => availableCompanies.some((company) => company.company_id === id)), // last filter is only useful if cookies are ahead of DB data (in case of company dismissal)
                    ),
                  ]
                    .filter((id) => id !== companyId)
                    .map((id) => ({
                      content: availableCompanies.filter((company) => company.company_id === id)?.[0]?.company_name,
                      icon: BlankIcon,
                      onAction: () => switchCompany({ newCompanyId: id }),
                    })),
                },
              ]}
            />
          </Popover>
        </Bleed>
      </BlockStack>
    </Bleed>
  )

  const emptyState = (
    <Box minWidth='105px'>
      <LegacyCard sectioned>
        <SkeletonBodyText lines={1} />
      </LegacyCard>
    </Box>
  )

  const displayContent = () => {
    if (isLoading) return emptyState
    if (availableCompanies.length === 0) return <></> // for user role
    return content
  }

  return (
    <div>
      {displayContent()}
    </div>
  )
}
