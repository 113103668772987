import { backendRequest } from '@shared/utils/axiosInstance'

export const companyService = {
  saveCompany: async ({ companyId, appBridge, update }) => {
    try {
      const response = await backendRequest({
        method: 'PATCH',
        url: `/obius/companies/${companyId}`,
        data: update,
        appBridge,
      })
      return response.data
    } catch (error) {
      console.error('Error saving company:', error)
      throw error
    }
  },
  fetchCompanyIds: async () => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: '/obius/companies?fields=company_id,company_name&sort=-company_id',
      })
      return response.data
    } catch (error) {
      if (error.status === 401) throw error // normal user, rethrow early without logging error
      console.error('Error fetching company IDs from company:', error)
      throw error
    }
  },
}
