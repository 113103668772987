import {
  useState,
  useCallback,
  Fragment,
  useContext,
} from 'react'
import {
  Card,
  Box,
  Text,
  Autocomplete,
  BlockStack,
  Icon,
  InlineStack,
  Button,
  InlineError,
  Link,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { SearchIcon, XSmallIcon } from '@shopify/polaris-icons'
import { DataUpdateContext } from '@src/shared/context/context'
import { getProductsUrl } from '@src/shared/utils'
import { OUTPUT_PRODUCT_MAX_LENGTH } from '@bom/utils/constants'
import { trimProductName } from '@bom/utils'

export const BomCard = ({
  bom,
  showRequiredError,
  onSelectOutputProduct,
  onRemoveOutputProduct,
}) => {
  const { t } = useTranslation()
  const { shopName } = useContext(DataUpdateContext)
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = useCallback((value) => {
    setInputValue(value)
    if (value.length > 0) {
      onSelectOutputProduct(value)
      setInputValue('')
    }
  }, [onSelectOutputProduct])

  const outputProduct = bom?.output_product

  const outputProductDescription = (outputProduct) => {
    const productName = (
      <Text variant="bodyMd" fontWeight="bold" as="h3">
        {trimProductName(outputProduct.product_name, OUTPUT_PRODUCT_MAX_LENGTH)}
      </Text>
    )

    const variantName = (
      <Fragment>
        {outputProduct.product_variant_name && (
          <Text variant="bodySm" color="subdued">
            {outputProduct.product_variant_name}
          </Text>
        )}
      </Fragment>
    )

    const textContent = (
      <Fragment>
        {productName}
        {variantName}
      </Fragment>
    )

    if (!shopName) return textContent

    const externalProductId = outputProduct?.external_product_id
    if (!externalProductId) return textContent

    const externalVariantId = outputProduct?.external_variant_id
    if (!externalVariantId) return textContent

    const productsUrl = getProductsUrl(shopName)
    if (!productsUrl) return textContent

    return (
      <Fragment>
        <Link
          monochrome
          removeUnderline
          url={`${productsUrl}/${externalProductId}/variants/${externalVariantId}`}
          target="_blank"
        >
          {productName}
        </Link>
        {variantName}
      </Fragment>
    )
  }

  return (
    <Card>
      <BlockStack gap='200'>
        <Text as="h2" variant="headingMd">
          {t('bundles.bundle')}
        </Text>
        {!outputProduct && (
          <BlockStack gap='100'>
            <Autocomplete
              id="outputProductSearch"
              options={[]}
              selected={[]}
              onSelect={() => {}}
              textField={
                <Autocomplete.TextField
                  onChange={handleInputChange}
                  value={inputValue}
                  placeholder={t('bundles.search_for_products')}
                  autoComplete="off"
                  prefix={<Icon source={SearchIcon} />}
                />
              }
            />
            {!!showRequiredError && (
              <InlineError
                message={t('bundles.bundled_product_required')}
                fieldID="outputProductSearch"
              />
            )}
          </BlockStack>
        )}
      </BlockStack>
      {outputProduct && (
        <InlineStack align="space-between">
          <Box padding="200" borderColor="black">
            {outputProductDescription(outputProduct)}
          </Box>
          <Button
            onClick={() => onRemoveOutputProduct()}
            plain
            icon={XSmallIcon}
            variant="tertiary"
          />
        </InlineStack>
      )}
    </Card>
  )
}
