import {
  Card,
  Box,
  Button,
  FormLayout,
  InlineStack,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonThumbnail,
} from '@shopify/polaris'
import CryptoJS from 'crypto-js'

function LoginEmptyState ({ nBodyLines, nFooterLines, nPageActions = 1 }) {
  const pageActionsAlignment = nPageActions > 1 ? 'space-between' : 'right'

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <InlineStack align={pageActionsAlignment}>
            {Array.from({ length: nPageActions }, () => CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex)).map((id) => (
              <SkeletonThumbnail key={id} size='small' />
            ))}
          </InlineStack>
        </Layout.Section>
        <Layout.Section>
          <Card sectioned>
            <Box paddingBlock='500' width='400px'>
              <SkeletonThumbnail size='medium' />
            </Box>
            <Box paddingBlock='400'>
              <SkeletonDisplayText size='medium' />
            </Box>
            <FormLayout>
              <Box paddingBlock='300'>
                <SkeletonBodyText lines={nBodyLines} />
              </Box>
              <Box paddingBlock='500'>
                <Button
                  fullWidth
                  loading
                />
              </Box>
            </FormLayout>
            {nFooterLines !== 0 && (
              <Box paddingBlock='500'>
                <SkeletonBodyText lines={nFooterLines} />
              </Box>
            )}
            <Box paddingBlock='400'>
              <SkeletonDisplayText size='medium' />
            </Box>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default LoginEmptyState
