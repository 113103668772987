import React, { useState } from 'react'
import {
  Card,
  ButtonGroup,
  Button,
  Text,
  Banner,
  Box,
  BlockStack,
} from '@shopify/polaris'
import { parseCsvToObjects } from '@src/shared/services/csvService'
import { katanaService } from '@shared/services/katanaService'
import { downloadExcel } from '@src/shared/services/excelService'
import { useTranslation } from 'react-i18next'

const PurchaseOrdersImportExportCard = () => {
  const { t } = useTranslation()
  const [isLoadingImportSample, setIsLoadingImportSample] = useState(false)
  const [isLoadingImport, setIsLoadingImport] = useState(false)
  const [bannerState, setBannerState] = useState({
    show: false,
    status: '',
    message: '',
  })

  const handleImport = async () => {
    try {
      const input = document.createElement('input')

      input.type = 'file'
      input.accept = '.csv'

      const filePromise = new Promise((resolve, reject) => {
        input.onchange = (event) => {
          const file = event.target.files[0]
          const reader = new FileReader()

          reader.onload = (e) => {
            try {
              setIsLoadingImport(true)

              const csvData = e.target.result

              if (!csvData || csvData.trim() === '') {
                throw new Error('The uploaded file is empty or contains no data.')
              }

              const parsedObjects = parseCsvToObjects(csvData)

              if (!Array.isArray(parsedObjects) || parsedObjects.length === 0) {
                throw new Error('The parse file does not contain valid data rows.')
              }

              resolve(parsedObjects)
            } catch (error) {
              reject(new Error(error))
            }
          }

          reader.readAsText(file)
        }
      })

      input.click()

      const parsedObjects = await filePromise
      const createPurchaseOrdersResponse = await katanaService.createPurchaseOrders({
        data: {
          csvData: parsedObjects,
        },
      })

      setBannerState({
        show: true,
        status: 'success',
        message: `${createPurchaseOrdersResponse.total} PO(s) successfully imported.`,
      })
    } catch (error) {
      setBannerState({
        show: true,
        status: 'critical',
        message: `Import failed: ${error.message}`,
      })
      console.error('Import error details:', error)
    } finally {
      setIsLoadingImport(false)
    }
  }

  const handleDownloadSample = async () => {
    try {
      setIsLoadingImportSample(true)
      const data = await katanaService.fetchPurchaseOrderSample()
      downloadExcel(data, 'PO_sample.xlsx')

      setBannerState({
        show: true,
        status: 'success',
        message: 'Import sample successfully downloaded.',
      })
    } catch (error) {
      setBannerState({
        show: true,
        status: 'critical',
        message: `Download import sample failed. ${error}`,
      })
    } finally {
      setIsLoadingImportSample(false)
    }
  }

  const bannerTitle = bannerState.status === 'success' ? 'Success' : 'Error'

  return (
    <Card>
      <BlockStack gap="400">
        <Text variant="heading2xl" as="h2">
          {t('data_import_export.purchase_orders')}
        </Text>

        {bannerState.show && (
          <Banner
            title={bannerTitle}
            tone={bannerState.status}
            onDismiss={() => setBannerState({ ...bannerState, show: false })}
          >
            <Text as='p'>{bannerState.message}</Text>
          </Banner>
        )}

        <Box>
          <ButtonGroup>
            <Button
              variant='primary'
              onClick={handleImport}
              loading={isLoadingImport}
            >
              {t('data_import_export.import')}
            </Button>
            <Button
              variant='secondary'
              onClick={handleDownloadSample}
              loading={isLoadingImportSample}
            >
              {t('data_import_export.download_import_sample')}
            </Button>
          </ButtonGroup>
        </Box>
      </BlockStack>
    </Card>
  )
}

export default PurchaseOrdersImportExportCard
