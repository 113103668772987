import React, { useState, useEffect, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { axiosWithCredentials } from '@shared/utils/axiosInstance'
import { currencyFormatter, integerFormatter, dateTimeFormatter } from '@shared/utils/formatter'
import { DataUpdateContext } from '@shared/context/context'
import '@shared/styles/items.css'
import { AgGridTable } from '@src/shared/components/ag-grid/AGGridTable'
import { ExportButton } from '@src/shared/components/ag-grid/params/buttons/ExportButton'
import { ResetButton } from '@src/shared/components/ag-grid/params/buttons/ResetButton'

const ItemsView = () => {
  const { t, i18n } = useTranslation()
  const [products, setProducts] = useState([])
  const [notification, setNotification] = useState('')
  const notificationTimeoutRef = useRef(null)
  const { lastUpdate, companyId } = useContext(DataUpdateContext)
  const gridApi = useRef(null)

  useEffect(() => {
    if (!companyId) return
    axiosWithCredentials
      .get(`/obius/products?company_id=${companyId}&status=active&limit=10000`)
      .then((response) => {
        if (!response.data) {
          throw new Error('Failed to fetch data')
        }
        const { products } = response.data
        setProducts(products)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [companyId])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }
  const columns = React.useMemo(
    () => [
      {
        headerName: t('product.name'),
        cellStyle: { textAlign: 'left' },
        filter: 'agSetColumnFilter',
        field: 'product_name',
        pinned: 'left',
      },
      {
        headerName: t('product.variant'),
        cellStyle: { textAlign: 'left' },
        filter: 'agSetColumnFilter',
        field: 'product_variant_name',
      },
      {
        headerName: t('product.sku'),
        filter: 'agSetColumnFilter',
        field: 'product_sku',
      },
      {
        headerName: t('product.supplier'),
        filter: 'agSetColumnFilter',
        field: 'product_supplier',
      },
      {
        headerName: t('product.sell_price'),
        filter: 'agNumberColumnFilter',
        valueGetter: ({ data }) => +data.sell_price,
        valueFormatter: ({ value }) => currencyFormatter.format(value),
      },
      {
        headerName: t('product.cost'),
        filter: 'agNumberColumnFilter',
        valueGetter: ({ data }) => +data.cost,
        valueFormatter: ({ value }) => currencyFormatter.format(value),
      },
      {
        headerName: t('product.inventory_type'),
        field: 'inventory_type',
        cellRenderer: ({ value }) => ['product', 'material', 'finished_product'].includes(value) ? t(`product.${value}`) : null,
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: (params) => params.value ? t(`product.${params.value}`) : null,
          values: ['product', 'material', 'finished_product', null],
        },
      },
      {
        headerName: t('product.lead_time'),
        filter: 'agNumberColumnFilter',
        field: 'lead_time',
        valueFormatter: ({ value }) => integerFormatter.format(value),
        editable: true,
      },
      {
        headerName: t('product.moq'),
        filter: 'agNumberColumnFilter',
        field: 'moq',
        valueFormatter: ({ value }) => integerFormatter.format(value),
        editable: true,
      },
      {
        headerName: t('product.target_days_inventory'),
        filter: 'agNumberColumnFilter',
        field: 'target_days_inventory',
        valueFormatter: ({ value }) => integerFormatter.format(value),
        editable: true,
      },
      {
        headerName: t('product.min_days_inventory'),
        filter: 'agNumberColumnFilter',
        field: 'min_days_inventory',
        valueFormatter: ({ value }) => integerFormatter.format(value),
        editable: true,
      },
      {
        headerName: t('product.is_inventory_tracked'),
        field: 'is_inventory_tracked',
        cellDataType: 'boolean',
        filter: 'agSetColumnFilter',
      },
      {
        headerName: t('product.product_status'),
        field: 'product_status',
        filter: 'agSetColumnFilter',
      },
    ],
    [t],
  )
  const onCellValueChanged = async (event) => {
    const { colDef, newValue, data } = event
    const field = colDef.field
    const productId = data.product_id
    if (productId) {
      try {
        await axiosWithCredentials.patch(`/obius/products/${productId}`, `{ "${field}": ${newValue} }`)
        setNotification(`${t('product.saved')}`)
        clearTimeout(notificationTimeoutRef.current)
        notificationTimeoutRef.current = setTimeout(() => {
          setNotification('')
        }, 2000)
      } catch (error) {
        console.error('Error saving data:', error)
      }
    } else {
      console.error('No productId found in data:', data)
    }
  }
  useEffect(() => {
    return () => {
      clearTimeout(notificationTimeoutRef.current)
    }
  }, [])
  return (
    <div className='dashboard-container'>
      {notification && (
        <div className='obius-notification notification'>
          {notification}
        </div>
      )}
      <div className='dashboard-section'>
        <div className='dashboard-title dashboard-dropdown'>
          <div>
            <h1>{t('sidebar.items')}</h1>
            <h2 className='subtitle is-6 mt-1'>
              {t('common.updated')} {dateTimeFormatter(lastUpdate, i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language || 'en')}
            </h2>
          </div>
        </div>
      </div>
      <div className='exports-container'>
        <div className='button-items-section'>
          <div className='button-container'>
            <ExportButton gridRef={gridApi} />
          </div>
          <div>
            <ResetButton gridRef={gridApi} />
          </div>
        </div>
      </div>
      <div className='items-section obius-grid-size-m'>
        <AgGridTable
          colDefs={columns}
          rowData={products}
          onCellValueChanged={onCellValueChanged}
          onGridReady={onGridReady}
        />

      </div>
    </div>
  )
}

export default ItemsView
