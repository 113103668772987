import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { getUserId } from '@shared/utils/getUserInfo'
import { getCookieDomain } from '@shared/utils'
import { axiosWithCredentials } from '@shared/utils/axiosInstance'
import {
  DashboardIcon,
  ForecastIcon,
  InventoryIcon,
  ItemsIcon,
  ReplenishmentIcon,
  AnalyticsIcon,
} from '@standalone/img/icons'
import logoImage from '@standalone/img/logo_obius.png'
import '@shared/styles/sidebar.css'

const Sidebar = () => {
  const { t, i18n } = useTranslation()
  const [userInfo, setUserInfo] = useState([])

  const handleLogout = () => {
    const cookieDomain = getCookieDomain()
    Cookies.remove('authToken', { domain: cookieDomain, path: '/' })

    window.location.href = '/login'
  }

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language)
  }

  useEffect(() => {
    const userID = getUserId()

    axiosWithCredentials
      .get(`/user/${userID}`)
      .then((response) => {
        if (!response.data) {
          throw new Error('Failed to fetch data')
        }
        setUserInfo(response.data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  return (
    <div className='sidebar'>
      <div className='sidebar-bg'>
        <div className='logo'>
          <img src={logoImage} alt='Logo' />
        </div>
        <div className='obius-buttons'>
          <NavLink className='nav-button' to='/dashboard'>
            <span className='icon'><DashboardIcon /></span>{' '}
            {t('sidebar.dashboard')}
          </NavLink>
          <NavLink className='nav-button' to='/report'>
            <span className='icon'><AnalyticsIcon /></span>{' '}
            {t('sidebar.reporting')}
          </NavLink>
          <NavLink className='nav-button' to='/collection'>
            <span className='icon'><AnalyticsIcon /></span>{' '}
            {t('sidebar.collection')}
          </NavLink>
          <NavLink className='nav-button' to='/replenishment'>
            <span className='icon'><ReplenishmentIcon /></span>{' '}
            {t('sidebar.replenishment')}
          </NavLink>
          <NavLink className='nav-button' to='/items'>
            <span className='icon'><ItemsIcon /></span> {t('sidebar.items')}
          </NavLink>
          <NavLink className='nav-button' to='/sales'>
            <span className='icon'><ForecastIcon /></span>{' '}
            {t('sidebar.sales')}
          </NavLink>
          <NavLink className='nav-button' to='/inventory'>
            <span className='icon'><InventoryIcon /></span>{' '}
            {t('sidebar.inventory')}
          </NavLink>
          {/* <NavLink className='nav-button' to='/sales-forecast'>
            <span className='icon'><ForecastIcon /></span>{' '}
            {t('sidebar.sales_forecast')}
          </NavLink>
          <NavLink className='nav-button' to='/inventory-forecast'>
            <span className='icon'><ForecastIcon /></span>{' '}
            {t('sidebar.inventory_forecast')}
          </NavLink> */}
        </div>
        <div className='avatar'>
          <div className='avatar-image' />
          <div className='avatar-info'>
            <div className='avatar-name'>{userInfo.users_name}</div>
            {/* <div className="avatar-title">{userInfo.users_role}</div> */}
            <div className='avatar-title'>
              <button onClick={handleLogout}>{t('common.logout')}</button>
            </div>
            <select
              value={i18n.language}
              onChange={(e) => handleLanguageChange(e.target.value)}
            >
              <option value='en'>English</option>
              <option value='fr'>Français</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
