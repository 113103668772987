exports.concatArrayWithoutDupes = (firstArray, secondArray) => {
  return [
    ...new Set([
      ...firstArray,
      ...secondArray,
    ]),
  ]
}

exports.trimProductName = (productName, maxLength) => {
  if (productName.length <= maxLength) {
    return productName
  }

  return `${productName.slice(0, maxLength)}...`
}
