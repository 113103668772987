import { getBannerStatus } from '@katana/utils'
import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Text,
} from '@shopify/polaris'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

function ConnectCard () {
  const { t } = useTranslation()
  const [bannerState, setBannerState] = useState({
    show: false,
    status: '',
    message: '',
  })

  return (
    <Card>
      <BlockStack gap="400">
        <Text variant="heading2xl" as="h2">
          {t('connect.subtitle')}
        </Text>
        {bannerState.show && (
          <Banner
            title={getBannerStatus({ bannerState })}
            tone={bannerState.status}
            onDismiss={() => setBannerState({ ...bannerState, show: false })}
          >
            <Text as='p'>{bannerState.message}</Text>
          </Banner>
        )}
        <Box>
          <Box paddingblock='250' paddingInlineEnd='500'>
            <BlockStack gap='200'>
              <Text>
                {t('connect.context')}
              </Text>
              <Text>
                {t('connect.action')}
              </Text>
            </BlockStack>
          </Box>
          <Box paddingBlockStart='500' paddingBlockEnd='300'>
            <Button
              variant='primary'
            >
              {t('connect.button_label')}
            </Button>
          </Box>
        </Box>
      </BlockStack>
    </Card>
  )
}

export default ConnectCard
