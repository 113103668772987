import { Fragment, useCallback, useContext, useState } from 'react'
import {
  ResourceList,
  ResourceItem,
  Text,
  Layout,
  Page,
  Card,
  EmptyState,
  SkeletonPage,
  SkeletonBodyText,
  SkeletonDisplayText,
  BlockStack,
  Box,
  Filters,
  InlineStack,
  Badge,
  Button,
  ButtonGroup,
  Banner,
} from '@shopify/polaris'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useBomsList } from '@bom/hooks/useBomsList'
import { UpgradeButton } from '@shared/components/UpgradeButton'
import { DataUpdateContext } from '@shared/context/context'
import { LARGE_REQUEST_LIMIT } from '@shared/utils/constant'
import { ExportIcon, ImportIcon } from '@shopify/polaris-icons'
import { exportBoms } from '@src/shared/services/bomService'
import { formatToCsv } from '@src/shared/services/csvService'

export const BundleListView = () => {
  const { t } = useTranslation()
  const {
    boms,
    currentPageBoms,
    isLoading,
    error,
    handleNextPage,
    handlePreviousPage,
    hasNextPage,
    hasPreviousPage,
    handleFiltersQueryChange,
    queryValue,
    isCreateButtonDisabled,
  } = useBomsList()
  const navigate = useNavigate()
  const { companyId, appBridge } = useContext(DataUpdateContext)
  const [exportStatus, setExportStatus] = useState({
    showBanner: false,
    title: null,
    tone: null,
  })
  const [isLoadingExportButton, setIsLoadingExportButton] = useState(false)

  const handleCreate = useCallback(() => {
    navigate('/bundles/new')
  }, [navigate])

  const emptyStateMarkup = (
    <EmptyState
      heading={t('bundles.create_bundles_to_get_started')}
      action={{ content: t('bundles.create_bundles'), onAction: handleCreate }}
      image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
    >
      <p>
        {t('bundles.contact_support_to_upload_bundles')}
      </p>
    </EmptyState>
  )

  if (isLoading) {
    return (
      <SkeletonPage primaryAction>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SkeletonDisplayText size="large" />
              <BlockStack gap='200'>
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    )
  }

  if (error) {
    return (
      <Page title={t('bundles.bundles')}>
        <Card>
          <Text variant="bodyMd" color="critical">{t('bundles.error')}: {error}</Text>
        </Card>
      </Page>
    )
  }

  const handleImport = () => {
    navigate('/bundles/import')
  }

  const handleExport = async (appBridge) => {
    setExportStatus({ showBanner: false, ...exportStatus })
    setIsLoadingExportButton(true)

    try {
      const response = await exportBoms(companyId, LARGE_REQUEST_LIMIT, appBridge)
      const csvData = formatToCsv(response.boms)
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      const fileName = `${t('bundles.exported_csv_filename')}.csv`
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)

      setExportStatus({
        showBanner: true,
        title: response.total + t('bundles.banner.export.success'),
        tone: 'success',
      })
    } catch (error) {
      console.error('Export Failed:', error.message)

      setExportStatus({
        showBanner: true,
        title: t('bundles.banner.export.failure'),
        tone: 'critical',
      })
    } finally {
      setIsLoadingExportButton(false)
    }
  }

  const emptyState = boms.length === 0 ? emptyStateMarkup : undefined
  const getBomStatusBadgeTone = (bomStatus) => bomStatus === 'active' ? 'success' : 'info'

  return (
    <Page
      title={t('bundles.bundles')}
      primaryAction={{
        content: t('bundles.create_bundle'),
        onAction: handleCreate,
        disabled: isCreateButtonDisabled,
      }}
      secondaryActions={
        <Fragment>
          <Box as='span' paddingInlineEnd='200' hidden={isCreateButtonDisabled}>
            <ButtonGroup>
              <Button
                icon={ExportIcon}
                variant='monochromePlain'
                onClick={() => handleExport(appBridge)}
                loading={isLoadingExportButton}
              >
                {t('bundles.export')}
              </Button>
              <Button
                icon={ImportIcon}
                variant='monochromePlain'
                onClick={() => handleImport()}
              >
                {t('bundles.import')}
              </Button>
            </ButtonGroup>
          </Box>
          <Box as='span' hidden={!isCreateButtonDisabled}>
            <UpgradeButton />
          </Box>
        </Fragment>
      }
      divider
    >
      { exportStatus.showBanner &&
        (
          <Box paddingBlockEnd='300'>
            <Banner
              title={exportStatus.title}
              tone={exportStatus.tone}
              onDismiss={() => setExportStatus({ ...exportStatus, showBanner: false })}
            />
          </Box>
        )
      }
      <Card>
        <Layout>
          <Layout.Section>
            <Card>
              <ResourceList
                filterControl={
                  <Filters
                    filters={[]}
                    queryValue={queryValue}
                    onQueryChange={handleFiltersQueryChange}
                  />
                }
                emptyState={emptyState}
                resourceName={{ singular: t('bundles.bundle'), plural: t('bundles.bundles') }}
                items={currentPageBoms}
                renderItem={(item) => {
                  const bomId = item.bom_id
                  const outputProductName = item.output_product.product_name
                  const productVariant = item.output_product.product_variant_name
                  const bomStatus = item.status

                  return (
                    <ResourceItem
                      id={bomId}
                      onClick={() => {
                        navigate(`/bundles/${bomId}`)
                      }}
                      accessibilityLabel={`${t('bundles.view_details_for')} ${outputProductName}`}
                    >
                      <InlineStack gap='200' blockAlign="center" align="space-between">
                        <Box>
                          <Text variant="bodyMd" fontWeight="bold" as="h3">
                            {outputProductName}
                          </Text>
                          <div>{productVariant}</div>
                        </Box>
                        <Badge tone={getBomStatusBadgeTone(bomStatus)}>
                          {t(`status_card.statuses.${bomStatus}`)}
                        </Badge>
                      </InlineStack>
                    </ResourceItem>
                  )
                }}
                pagination={{
                  hasNext: hasNextPage,
                  onNext: handleNextPage,
                  hasPrevious: hasPreviousPage,
                  onPrevious: handlePreviousPage,
                }}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Card>
    </Page>
  )
}
