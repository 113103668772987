import { http, HttpResponse, delay } from 'msw'
import { objectToId } from '@shared/utils'
import { FREE_PLAN_LIMIT } from '@src/apps/bom/utils/constants'
import { LARGE_REQUEST_LIMIT } from '@src/shared/utils/constant'
import Cookies from 'js-cookie'

const testShopify401 = false
const testShopifyStatus = 'premium'
const testShopifySubscriptionStatus = null
const testNoLastUpdate = false
const testShowBanner = false
const testShowErrorBanner = false
const subscriptionPlan = 'free'
const testNullParams = true
const noProducts = false
const returnJobSnapshots = true
const enableAuthToken = true
const testInvalidLogin = false
const testInvalidSignUp = false
const testInvalidResetPassword = false
const testTokenWithoutCompany = false

let numberOfJobRequest = 0

const authTokenStub = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1NCwidXNlcl9yb2xlIjoiQURNSU4iLCJjb21wYW55X2lkIjoxMDcsImlhdCI6MTc0MTIxMzEyNCwiZXhwIjoxNzQzODA1MTI0fQ.uOYZOU3vIeF8EUoJw7gZEfX91ZFJ4zFd5hm4xSIWAA0'
const authTokenWithoutCompanyStub = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5NiwidXNlcl9yb2xlIjoiVXNlciIsImNvbXBhbnlfaWQiOm51bGwsImlhdCI6MTc0MzAxODg2MSwiZXhwIjoxNzQ1NjEwODYxfQ.IdYdjY5NZ78byTifRT81lIahadPnnNCjw-Xt-0LnhQ8'
let token = testTokenWithoutCompany ? authTokenWithoutCompanyStub : authTokenStub
if (enableAuthToken) Cookies.set('authToken', token)
else Cookies.remove('authToken')

const inventoryAllocationStub = {
  mo_name: 'WMC-01 / 1',
  project_name: 'WMC-07',
  location_name: 'warehouse A',
  mo_status: 'NOT_STARTED',
  mo_product_sku: 'SKU-1',
  mo_product_name: 'WR2 ROBOT CONTROL BOX',
  mo_product_variant_name: 'product variant name',
  mo_planned_quantity: '3.000',
  mo_actual_quantity: '3.000',
  mo_calculated_quantity: '3.000',
  item_product_sku: 'SKU-2',
  item_product_name: 'VRS-LEGS-AOS-10384',
  item_product_variant_name: 'variant name',
  item_planned_quantity_per_unit: '3.000',
  item_actual_quantity: '2.000',
  item_calculated_quantity: '6.000',
  inventory_value_per_unit: '125.36',
  item_total_cost: '650.00',
  calculated_total_cost: '650.00',
  item_availability: 'EXPECTED',
  value_type: 'native'
}

const inventoryAllocationsStub = Array.from({ length: 1000 }, (_, index) => ({
  ...inventoryAllocationStub,
  mo_name: `WMC-${String(Math.floor(Math.random() * 50) + 1).padStart(2, '0')} / ${index + 1}`,
  project_name: `WMC-${String(Math.floor(Math.random() * 30) + 1).padStart(2, '0')}`,
  location_name: `Warehouse ${String.fromCharCode(65 + (Math.floor(Math.random() * 3)))}`,
  mo_status: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED', 'ON_HOLD'][Math.floor(Math.random() * 4)],
  mo_product_sku: `SKU-${String(Math.floor(Math.random() * 1000) + 1).padStart(3, '0')}`,
  mo_product_name: `Product ${String(Math.floor(Math.random() * 1000) + 1).padStart(3, '0')}`,
  mo_product_variant_name: `Variant ${String(Math.floor(Math.random() * 3) + 1)}`,
  mo_planned_quantity: (Math.random() * 100 + 3).toFixed(3),
  mo_actual_quantity: (Math.random() * 80 + 2).toFixed(3),
  mo_calculated_quantity: (Math.random() * 120 + 4).toFixed(3),
  item_product_sku: `COMP-${String(Math.floor(Math.random() * 1000)).padStart(3, '0')}`,
  item_is_purchasable: [true, false][Math.floor(Math.random() * 2)],
  item_product_name: `Component ${String(Math.floor(Math.random() * 1000)).padStart(3, '0')}`,
  item_product_variant_name: `Comp Variant ${String(Math.floor(Math.random() * 3) + 1)}`,
  item_planned_quantity_per_unit: (Math.random() * 20 + 3).toFixed(3),
  item_actual_quantity: (Math.random() * 18 + 2).toFixed(3),
  item_calculated_quantity: (Math.random() * 25 + 5).toFixed(3),
  inventory_value_per_unit: (Math.random() * 900 + 100).toFixed(2),
  item_total_cost: (Math.random() * 1000 + 500).toFixed(2),
  calculated_total_cost: (Math.random() * 1200 + 600).toFixed(2),
  item_availability: ['DONE', 'PROCESSED', 'PICKED', 'IN_STOCK', 'EXPECTED', 'NOT_AVAILABLE'][Math.floor(Math.random() * 6)],
  order_date: `${2023 + Math.floor(Math.random() * 2)}-${String(Math.floor(Math.random() * 12) + 1).padStart(2, '0')}-${String(Math.floor(Math.random() * 28) + 1).padStart(2, '0')}`,
  done_date: `${2023 + Math.floor(Math.random() * 2)}-${String(Math.floor(Math.random() * 12) + 1).padStart(2, '0')}-${String(Math.floor(Math.random() * 28) + 1).padStart(2, '0')}`,
  value_type: ['native', 'adjustment'][Math.floor(Math.random() * 2)],
  supplier_name: ['Acme Supplies', 'Global Components', 'Tech Parts Inc', 'Quality Materials', 'FastShip Logistics'][Math.floor(Math.random() * 5)],
  lead_time: Math.floor(Math.random() * 30) + 1,
  item_expected_date: `${2023 + Math.floor(Math.random() * 2)}-${String(Math.floor(Math.random() * 12) + 1).padStart(2, '0')}-${String(Math.floor(Math.random() * 28) + 1).padStart(2, '0')}`
}))

const inventoryHistoryStub = {
  product: {
    product_name: 'WR2 ROBOT CONTROL BOX',
    product_variant_name: 'variant name',
    product_sku: 'SKU-1',
    is_purchasable: true
  },
  location: {
    location_name: 'Warehouse B'
  },
  qty_stock: 25.000,
  qty_committed: 15.000,
  qty_incoming: 10.000,
  cost: 125.36
}

const inventoryHistoriesStub = Array.from({ length: 1000 }, (_, index) => (
  {
    ...inventoryHistoryStub,
    product: {
      product_name: `Product ${String(Math.floor(Math.random() * 1000) + 1).padStart(3, '0')}`,
      product_variant_name: `Variant ${String(Math.floor(Math.random() * 3) + 1)}`,
      product_sku: `SKU-${String(Math.floor(Math.random() * 1000) + 1).padStart(3, '0')}`,
      is_purchasable: Math.random() > 0.3 // 70% true, 30% false
    },
    location: {
      location_name: `Warehouse ${String.fromCharCode(65 + (Math.floor(Math.random() * 3)))}`
    },
    qty_stock: (Math.random() * 100 + 20).toFixed(3),
    qty_committed: (Math.random() * 80 + 10).toFixed(3),
    qty_incoming: (Math.random() * 50 + 5).toFixed(3),
    cost: (Math.random() * 900 + 100).toFixed(2)
  }
))

const purchaseOrderStub = {
  'Purchase Order No*': 'PO-39 drexma',
  'Supplier ID*': 147483647,
  'Contact Name [Unused in import]': 'McMaster-Carr',
  'Created Date [YYYY-MM-DD]': '11/11/2024',
  'Expected Arrival [YYYY-MM-DD]': '11/26/2024',
  'Variant ID*': 123851916,
  'Variant Code [Unused in import]': 'SKU-1',
  'Description [Unused in import]': 'materials - tube',
  'Quantity*': 100,
  'Price Per Unit*': 1,
  'Tax Rate [Unused in import]': 14.975,
  'Total Price Without Tax [Unused in import]': 100,
  'Total Price Tax Included [Unused in import]': 114.98,
  'Received Date [YYYY-MM-DD]': '',
  'Landed Cost Per Unit [Unused in import]': 1,
  'Additional Info': 'Rush order',
  'Order Currency': 'CAD',
  'Converted Total Price Tax Included [Unused in import]': 114.98,
  'Conversion Rate': 1,
  'Conversion Date & Time [Unused in import]': '',
  'Additional Cost Reference [Unused in import]': '',
  'Delivery No [Unused in import]': '/1',
  'Location ID*': 214748364,
  'Tax Rate ID [Import only]': 951815714,
  'Purchase Unit of Measure [Import only]': 'meters',
  'Entity Type [Import only]': 'regular',
  'Status [Import only]': 'NOT_RECEIVED',
  'Tracking Location ID [Import only if entity type is outsourced]': 214748365
}

const supplierStub = {
  'Supplier ID': 1179828,
  'Company Name*': 'Gino Inc.',
  'Phone number': '514 738-9844',
  Email: 'luke.skywalker@example.com',
  Comment: 'The best supplier ever!',
  Currency: 'USD',
  'Street address': 'Grande Allée',
  'Additional details': '3 trucks available',
  'City/Town': 'Montréal',
  'State/Region': 'Québec',
  'Zip/Postal code': 'HVH 1X1',
  Country: 'Canada'
}

const productStub = {
  'Product ID': '9432353243450',
  'Product Name': 'A product',
  'Variant ID': '48995385082170',
  'Variant Name': 'A variant'
}

const locationStub = {
  'Location ID': 65400864855,
  'Location Name': 'Entrepôt St-Augustin'
}

const taxRateStub = {
  'Tax Rate ID': 834770864855,
  'Tax Rate Name': 'Qc Tax',
  'Tax Rate': 14.975
}

const backupMetadataStubs = {
  backupsMetadata: [
    {
      id: 1,
      company_id: 123,
      created_at: new Date(),
      source: 'KATANA',
      key: `company_123/${new Date().toISOString().split('T')[0]}`,
      url: '',
      urlExpiryDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
    },
    {
      id: 2,
      company_id: 123,
      created_at: new Date(),
      source: 'KATANA',
      key: `company_123/${new Date().toISOString().split('T')[0]}`,
      url: '',
      urlExpiryDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
    },
    {
      id: 3,
      company_id: 123,
      created_at: new Date(),
      source: 'KATANA',
      key: `company_123/${new Date().toISOString().split('T')[0]}`,
      url: '',
      urlExpiryDate: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
    }
  ],
  total: 3
}

const backupUrlStub = {
  url: 'https://obiuss3.blob.core.windows.net/katana-backup/2024-02-25.zip?sp=r&st=2025-02-26T02:02:20Z&se=2025-03-04T10:02:20Z&spr=https&sv=2022-11-02&sr=b&sig=zT5VimgaXofybuVZi49p7NnuHC3tte%2B0sbGQUIhJQ7A%3D'
}

const createBoms = (total) => {
  const boms = []
  for (let i = 1; i <= total; i++) {
    const bom = {
      bom_id: `bom-${i}`,
      status: ['active', 'draft'][Math.floor(2 * Math.random())],
      output_quantity: i,
      bom_name: `BOM ${i}`,
      location_id: '695be724-9d8e-197d-3491-47a2af58f0b7',
      output_product: {
        product_id: `50fcb093-c9c2-87c2-0ec2-5b38b647f8cd_${i}`,
        product_name: `Product ${i}`,
        product_variant_name: `Different Variant ${i + 1}`,
        inventory_type: 'finished_product'
      },
      materials: [
        {
          company_id: i,
          material_id: '5f72a234-c140-218e-d943-a7f5e6da444a',
          product_id: 'eead3572-0d18-780f-1281-935913069f14',
          product: {
            product_name: `Material ${i}`,
            product_variant_name: `Material Variant ${i + 1}`,
            external_product_id: '9022348329274',
            external_variant_id: '50546645860666'
          },
          quantity: 3.50
        },
        {
          company_id: i + 1,
          material_id: '5f72a234-c140-218e-d943-a7f5e6da444a',
          product_id: 'eead3572-0d18-780f-1281-935913069f14',
          product: {
            product_name: `Material ${i + 1}`,
            product_variant_name: `Material Variant ${i + 2}`,
            external_product_id: '9748173685050',
            external_variant_id: '50487439458618'
          },
          quantity: 333.333
        }
      ]
    }
    boms.push(bom)
  }
  return boms
}

function getRandomProductName () {
  const products = ['Skateboard', 'Snowboard', 'Bicycle', 'Rollerblades', 'Helmet', 'A very long product name']
  return products[Math.floor(Math.random() * products.length)]
}

function getRandomDate () {
  const start = new Date(2024, 0, 1)
  const end = new Date(2024, 11, 31)
  const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
  return date.toISOString().split('T')[0]
}

function dashboardResponse (query) {
  const metrics = query.metric_name === 'loss_sales_for_low_stock'
    ? []
    : [
        {
          location_name: 'Warehouse 1',
          '2024-W03': '1800.00',
          '2024-W04': '1100.25'
        },
        {
          location_name: 'Warehouse 2',
          '2024-W03': '2000.00',
          '2024-W04': '2300.25'
        }
      ]

  const pivotColumns = query.metric_name === 'loss_sales_for_low_stock'
    ? [
        '2024-W01',
        '2024-W02',
        '2024-W03',
        '2024-W04',
        '2024-W05',
        '2024-W06',
        '2024-W07'
      ]
    : Object.keys(metrics[0] ?? {}).filter(key => key.match(/W\d{2}$/))

  return HttpResponse.json({
    metrics,
    pivotColumns,
    total: metrics.length
  })
}

function dashboardResponseNew (query) {
  const metrics = [
    {
      unique_id: 'b5e33a3b-4529-d847-77a7-e904ef31e547',
      location_name: 'POP UNDERWEAR',
      metric_name: 'weekly_sales',
      '2024-W17': '55.0',
      '2024-W18': '56.0',
      '2024-W19': '0',
      '2024-W20': '0',
      '2024-W21': '0'
    },
    {
      unique_id: 'b5e33a3b-4529-d847-77a7-e904ef31e547',
      location_name: 'POP UNDERWEAR',
      metric_name: 'inventory_turnover',
      '2024-W17': '100',
      '2024-W18': '100',
      '2024-W19': '100',
      '2024-W20': '100',
      '2024-W21': '100'
    },
    {
      unique_id: 'b5e33a3b-4529-d847-77a7-e904ef31e547',
      location_name: 'POP UNDERWEAR 2ND LOCATION',
      metric_name: 'inventory_turnover',
      '2024-W17': '110',
      '2024-W18': '110',
      '2024-W19': '110',
      '2024-W20': '110',
      '2024-W21': '110'
    },
    {
      unique_id: 'b5e33a3b-4529-d847-77a7-e904ef31e547',
      location_name: 'POP UNDERWEAR 2ND LOCATION',
      metric_name: 'inventory_value',
      '2024-W17': '120',
      '2024-W18': '120',
      '2024-W19': '120',
      '2024-W20': '120',
      '2024-W21': '120'
    },
    {
      unique_id: 'b5e33a3b-4529-d847-77a7-e904ef31e547',
      location_name: 'POP UNDERWEAR 2ND LOCATION',
      metric_name: 'low_stock_losses',
      '2024-W17': '130',
      '2024-W18': '130',
      '2024-W19': '130',
      '2024-W20': '130',
      '2024-W21': '130'
    },
    {
      unique_id: 'b5e33a3b-4529-d847-77a7-e904ef31e547',
      location_name: 'POP UNDERWEAR 2ND LOCATION',
      metric_name: 'out_of_stock_losses',
      '2024-W17': '140',
      '2024-W18': '140',
      '2024-W19': '140',
      '2024-W20': '140',
      '2024-W21': '140'
    },
    {
      unique_id: 'b5e33a3b-4529-d847-77a7-e904ef31e547',
      location_name: 'Deleted Location',
      metric_name: 'out_of_stock_losses',
      '2024-W17': '140',
      '2024-W18': '140',
      '2024-W19': '140',
      '2024-W20': '140',
      '2024-W21': '140',
      '2024-W22': '140',
      '2024-W23': '140',
      '2024-W24': '140',
      '2024-W25': '0',
      '2024-W26': '0',
      '2024-W27': '0',
      '2024-W28': '0',
      '2024-W29': '0'
    }
  ]

  const pivotColumns = [...new Set(
    [].concat(...metrics.map(metric => Object.keys(metric).filter(key => key.match(/W\d{2}$/))))
  ).values()].sort()

  return HttpResponse.json({
    metrics,
    pivotColumns,
    total: metrics.length
  })
}

function salesResponse (query) {
  const metrics = query['period_start_date>'] === '2024-01-01'
    ? [
        {
          product_name: 'hot shirt rouge 1',
          product_sku: 'sku-1',
          product_supplier: 'supplier 1',
          product_variant1: 'product variant 1',
          product_status: 'active',
          is_inventory_tracked: 'f',
          '2024-W03': 180,
          '2024-W04': 180
        },
        {
          product_name: 'hot shirt rouge 2',
          product_sku: 'sku-2',
          product_supplier: 'supplier 2',
          product_variant1: null,
          is_inventory_tracked: 't',
          '2024-W03': '180.00',
          '2024-W04': '110.25'
        },
        {
          product_name: 'hot shirt rouge 2',
          product_sku: 'sku-2',
          product_supplier: 'supplier 2',
          product_variant1: null,
          is_inventory_tracked: 't',
          '2024-W03': '180.00',
          '2024-W04': '110.25'
        }, {
          product_name: 'hot shirt rouge 2',
          product_sku: 'sku-2',
          product_supplier: 'supplier 2',
          product_variant1: null,
          is_inventory_tracked: 't',
          '2024-W03': '180.00',
          '2024-W04': '110.25'
        }
      ]
    : query['period_start_date>'] === '2023-01-01'
      ? [
          {
            product_name: 'hot shirt rouge 3',
            product_sku: 'sku-3',
            product_supplier: 'supplier 3',
            product_variant1: null,
            '2023-W03': '110.25',
            '2023-W04': '110.25'
          }
        ]
      : []

  const pivotColumns = Object.keys(metrics[0] ?? {}).filter(key => key.match(/W\d{2}$/))

  return HttpResponse.json({
    metrics,
    pivotColumns,
    total: metrics.length
  })
}
function reportsResponse (query) {
  const metrics = query['period_start_date>'] === '2024-01-01'
    ? Array(5)
      .fill(0)
      .map((_, index) => ({
        collection_name: `collection ${index}`,
        product_name: `hot shirt ${index}`,
        product_variant_name: 'rouge',
        product_sku: `sku-${index}`,
        product_supplier: 'supplier 1',
        product_variant1: `product variant ${index}`,
        is_inventory_tracked: 't',
        product_status: 'active',
        [`2024-${query.metric_name.startsWith('weekly_') ? 'W' : ''}03`]: 100 + Math.ceil(80 * Math.random()),
        [`2024-${query.metric_name.startsWith('weekly_') ? 'W' : ''}04`]: 100 + Math.ceil(80 * Math.random())
      }))
    : query['period_start_date>'] === '2023-01-01'
      ? [
          {
            collection_name: 'collection 2023',
            product_name: 'hot shirt rouge 2023',
            product_variant_name: 'rouge 2',
            product_sku: 'sku-2023',
            product_supplier: 'supplier 2023',
            product_variant1: null,
            is_inventory_tracked: 't',
            product_status: 'active',
            [`2023-${query.metric_name.startsWith('weekly_') ? 'W' : ''}03`]: 100 + Math.ceil(80 * Math.random()),
            [`2023-${query.metric_name.startsWith('weekly_') ? 'W' : ''}04`]: 100 + Math.ceil(80 * Math.random())
          }
        ]
      : []

  const pivotColumns = Object.keys(metrics[0] ?? {}).filter(key => key.match(/\d{2}$/))

  return HttpResponse.json({
    metrics,
    pivotColumns,
    total: metrics.length
  })
}

function pivotViewResponse (query) {
  const metrics = [
    {
      collection_name: 'collection 1',
      location_name: 'loc 1',
      product_name: 'hot shirt 1',
      product_variant_name: 'rouge',
      product_sku: 'sku-1',
      product_supplier: 'supplier 1',
      period_label: `2024-${query.metric_name.startsWith('weekly_') ? 'W' : ''}01`,
      metric_name: query.metric_name,
      metric_value: '125.00',
      is_inventory_tracked: 't'
    },
    {
      collection_name: 'collection 1',
      location_name: 'loc 1',
      product_name: 'hot shirt 1',
      product_variant_name: 'rouge 2',
      product_sku: 'sku-2',
      product_supplier: 'supplier 2',
      period_label: `2024-${query.metric_name.startsWith('weekly_') ? 'W' : ''}02`,
      metric_name: query.metric_name,
      metric_value: '200.00',
      is_inventory_tracked: 't'
    },
    {
      collection_name: 'collection 1',
      location_name: 'loc 1',
      product_name: 'hot shirt rouge 1',
      product_variant_name: 'rouge 2',
      product_sku: 'sku-2',
      product_supplier: 'supplier 2',
      period_label: `2024-${query.metric_name.startsWith('weekly_') ? 'W' : ''}03`,
      metric_name: query.metric_name,
      metric_value: '250.00',
      is_inventory_tracked: 'f',
      product_status: 'draft'
    },
    {
      collection_name: 'collection 2',
      location_name: 'loc 1',
      product_name: 'hot shirt rouge 2',
      product_variant_name: 'rouge 2',
      product_sku: 'sku-2',
      product_supplier: 'supplier 2',
      period_label: `2024-${query.metric_name.startsWith('weekly_') ? 'W' : ''}01`,
      metric_name: query.metric_name,
      metric_value: '350.00',
      is_inventory_tracked: 't',
      product_status: 'active'
    },
    {
      collection_name: 'collection 3',
      location_name: 'loc 1',
      product_name: 'hot shirt rouge 3',
      product_variant_name: 'rouge 2',
      product_sku: 'sku-3',
      product_supplier: 'supplier 3',
      period_label: `2023-${query.metric_name.startsWith('weekly_') ? 'W' : ''}02`,
      metric_name: query.metric_name,
      metric_value: '150.00',
      is_inventory_tracked: 't'
    }
  ]
  return HttpResponse.json({
    metrics,
    total: metrics.length
  })
}
async function replenishmentResponse (query, length) {
  const metrics = Array.from({ length }, () => ({
    sell_price: (Math.floor(Math.random() * 100) + 1).toString(),
    inventory_status: ['replenish_soon', 'replenish_now', 'overstock', 'deadstock', 'ok'][Math.floor(5 * Math.random())],
    product_name: getRandomProductName(),
    is_material: Math.random() >= 0.5,
    product_sku: (Math.floor(Math.random() * 100) + 1).toString(),
    daily_velocity: (Math.random() * 100).toFixed(2),
    day_inventory: (Math.random() * 100 - 50).toFixed(2),
    loss_sales: (Math.random() * 1000).toFixed(2),
    inventory_value: (Math.random() * 1000).toFixed(2),
    next_order_date: getRandomDate(),
    quantity_to_order: (Math.random() * 100).toFixed(2),
    inventory_quantity: (Math.random() * 10).toFixed(2),
    inventory_incoming_quantity: (Math.random() * 10).toFixed(2),
    inventory_projected_quantity: (Math.random() * 10).toFixed(2),
    product_variant_name: 'Variant ' + (Math.floor(Math.random() * 5) + 1),
    location_name: 'Location ' + (Math.floor(Math.random() * 10) + 1),
    product_supplier: 'Supplier ' + (Math.floor(Math.random() * 10) + 1),
    moq: ['0', null][Math.floor(2 * Math.random())],
    lead_time: ['15', null][Math.floor(2 * Math.random())],
    target_days_inventory: ['15', null][Math.floor(2 * Math.random())],
    min_days_inventory: ['10', null][Math.floor(2 * Math.random())],
    is_inventory_tracked: Math.random() > 0.95 ? 'f' : 't',
    product_status: ['draft', 'active'][Math.floor(2 * Math.random())]
  }))

  const extraMetrics = Array.from({ length: 1 }, () => ({
    inventory_status: ['replenish_soon', 'replenish_now', 'overstock', 'deadstock', 'ok'][Math.floor(5 * Math.random())],
    product_name: getRandomProductName(),
    is_material: Math.random() >= 0.5,
    product_sku: (Math.floor(Math.random() * 100) + 1).toString(),
    daily_velocity: (Math.random() * 100).toFixed(2),
    day_inventory: (Math.random() * 100 - 50).toFixed(2),
    loss_sales: (Math.random() * 1000).toFixed(2),
    inventory_value: (Math.random() * 1000).toFixed(2),
    next_order_date: getRandomDate(),
    quantity_to_order: (Math.random() * 100).toFixed(2),
    inventory_quantity: (Math.random() * 10).toFixed(2),
    inventory_incoming_quantity: (Math.random() * 10).toFixed(2),
    inventory_projected_quantity: (Math.random() * 10).toFixed(2),
    product_variant_name: 'Variant ' + (Math.floor(Math.random() * 5) + 1),
    location_name: 'Deleted Location',
    product_supplier: 'Supplier ' + (Math.floor(Math.random() * 10) + 1),
    moq: 10,
    lead_time: 10,
    target_days_inventory: 20,
    min_days_inventory: 10,
    is_inventory_tracked: 't',
    product_status: 'active'
  }))

  const pivotColumns = Object.keys(metrics[0])
    .filter(key => key !== 'product_name' &&
      key !== 'product_sku' &&
      key !== 'is_material' &&
      key !== 'product_supplier' &&
      key !== 'location_name')
  await delay(1000)
  return HttpResponse.json({
    metrics: [...metrics, ...extraMetrics],
    pivotColumns,
    total: metrics.length,
    last_updated: query.metric_date
  })
}

function inventoryResponse (query) {
  const products = ['hot shirt rouge', 'cool pants blue', 'stylish hat green', 'elegant coat black', 'comfy socks yellow']
  const suppliers = ['supplier 1', 'supplier 2', 'supplier 3', 'supplier 4', 'supplier 5']
  const variants = ['variant 1', 'variant 2', 'variant 3', 'variant 4', 'variant 5']

  const generateMetrics = () => {
    return Array.from({ length: 100 }, (_, i) => {
      const productIndex = Math.floor(Math.random() * products.length)
      const supplierIndex = Math.floor(Math.random() * suppliers.length)
      const variantIndex = Math.floor(Math.random() * variants.length)
      const baseMetrics = {
        product_name: `${products[productIndex]} ${i + 1}`,
        product_sku: `sku-${i + 1}`,
        product_supplier: suppliers[supplierIndex],
        product_variant_name: `product ${variants[variantIndex]}`
      }

      // Generate sequential weekly data for the years before 2024
      const weeklyMetrics = {}
      const year = 2024
      for (let week = 1; week <= 52; week++) {
        const weekLabel = `${year}-W${week.toString().padStart(2, '0')}`
        weeklyMetrics[weekLabel] = (Math.random() * 200).toFixed(2)
      }
      return { ...baseMetrics, ...weeklyMetrics }
    })
  }

  const metrics = query['period_start_date>'] === '2024-01-01'
    ? generateMetrics()
    : []

  const pivotColumns = Object.keys(metrics[0] ?? {}).filter(key => key.match(/\d{4}-W\d{2}/))

  return HttpResponse.json({
    metrics,
    pivotColumns,
    total: metrics.length
  })
}
function forecastMonthlyMetrics (query) {
  const metrics = query['period_start_date>'] === '2024-01-01'
    ? [
        {
          product_name: 'hot shirt rouge 1',
          product_sku: 'sku-1',
          is_inventory_tracked: 't',
          '2024-05': '110.25',
          '2024-06': '120.25'
        },
        {
          product_name: 'hot shirt rouge 2',
          product_sku: 'sku-2',
          is_inventory_tracked: 't',
          '2024-06': '20.25'
        }]
    : query['period_start_date>'] === '2023-01-01'
      ? [
          {
            product_name: 'hot shirt rouge 3',
            product_sku: 'sku-3',
            is_inventory_tracked: 't',
            '2023-03': '150.00',
            '2023-04': '100.25'
          }
        ]
      : []

  const pivotColumns = Object.keys(metrics[0] ?? {}).filter(key => key.match(/-\d{2}$/))
  return HttpResponse.json({
    metrics,
    pivotColumns,
    total: metrics.length
  })
}
function InventoryForecastMetrics (query) {
  const metrics = query['period_start_date>'] === '2024-01-01'
    ? [
        {
          product_name: 'hot shirt rouge 1',
          product_sku: 'sku-33',
          is_inventory_tracked: 't',
          '2024-W20': '33.33',
          '2024-W21': '33.33'
        },
        {
          product_name: 'hot shirt rouge 44',
          product_sku: 'sku-44',
          is_inventory_tracked: 't',
          '2024-W21': '44.44'
        }
      ]
    : query['period_start_date>'] === '2023-01-01'
      ? [
          {
            product_name: 'hot shirt rouge 55',
            product_sku: 'sku-55',
            is_inventory_tracked: 't',
            '2023-W12': '55.55',
            '2023-W13': '55.55'
          }
        ]
      : []

  const pivotColumns = Object.keys(metrics[0] ?? {}).filter(key => key.match(/-W\d{2}$/))
  return HttpResponse.json({
    metrics,
    pivotColumns,
    total: metrics.length
  })
}

function salesMonthlyMetrics (query) {
  const metrics = query['period_start_date>'] === '2024-01-01'
    ? [
        {
          product_name: 'hot shirt rouge 1',
          product_sku: 'sku-1',
          is_inventory_tracked: 't',
          '2024-03': '180.00',
          '2024-04': '110.25'
        },
        {
          product_name: 'hot shirt rouge 2',
          product_sku: 'sku-2',
          is_inventory_tracked: 't',
          '2024-03': '180.00',
          '2024-04': '110.25'
        }]
    : query['period_start_date>'] === '2023-01-01'
      ? [
          {
            product_name: 'hot shirt rouge 3',
            product_sku: 'sku-3',
            is_inventory_tracked: 't',
            '2023-03': '180.00',
            '2023-04': '110.25'
          }
        ]
      : []

  const pivotColumns = Object.keys(metrics[0] ?? {}).filter(key => key.match(/-\d{2}$/))
  return HttpResponse.json({
    metrics,
    pivotColumns,
    total: metrics.length
  })
}

const handlers = [
  http.get('/api/obius/metrics', (req) => {
    const [, search] = req.request.url.split('?')
    const query = Object.fromEntries(
      search
        .split('&')
        .map(x => x
          .split('=')
          .map(decodeURIComponent)
        )
    )

    const fields = query.fields?.split(',') ?? []

    if (query.fields.includes('metric_value')) {
      return pivotViewResponse(query)
    } else if (fields.includes('location_name') && [
      'loss_sales_for_out_of_stock', 'loss_sales_for_low_stock', 'inventory_value', 'weekly_sales', 'inventory_turnover'
    ].includes(query.metric_name)) {
      return dashboardResponse(query)
    } else if (query.metric_name === 'low_stock_losses,out_of_stock_losses,inventory_value,weekly_sales,inventory_turnover') {
      return dashboardResponseNew(query)
    } else if (query.fields === 'collection_name,product_name,product_variant_name,product_sku,product_supplier,is_inventory_tracked,product_status') {
      return reportsResponse(query)
    } else if (query.fields === 'inventory_quantity' || query.metric_name === 'inventory_value') {
      return inventoryResponse(query)
    } else if (parseInt(query.limit) === LARGE_REQUEST_LIMIT && query.metric_name === 'day_inventory,inventory_value,daily_velocity,loss_sales,inventory_quantity,quantity_to_order,next_order_date,inventory_projected_quantity,inventory_incoming_quantity') {
      return replenishmentResponse(query, 100)
    } else if (query.metric_name === 'weekly_sold_quantity') {
      return salesResponse(query)
    } else if (query.metric_name === 'monthly_sales_quantity_forecast') {
      return forecastMonthlyMetrics(query)
    } else if (query.metric_name === 'monthly_sold_quantity') {
      return InventoryForecastMetrics(query)
    } else if (query.metric_name === 'inventory_quantity_forecast' || query.metric_name === 'inventory_quantity') {
      return salesMonthlyMetrics(query)
    } else if (parseInt(query.limit) === FREE_PLAN_LIMIT) {
      return replenishmentResponse(query, FREE_PLAN_LIMIT - 1)
    } else {
      return HttpResponse.json({
        metrics: [],
        pivotColumns: [],
        total: 0
      })
    }
  }),
  http.get('/api/obius/views/inventory-allocations', async () => {
    await delay(500)
    return HttpResponse.json({
      inventoryAllocations: inventoryAllocationsStub,
      total: inventoryAllocationsStub.length
    })
  }),
  http.get('/api/obius/obius-inventory-histories', async () => {
    await delay(500)
    return HttpResponse.json({
      inventoryHistories: inventoryHistoriesStub,
      total: inventoryHistoriesStub.length
    })
  }),
  http.get('/api/obius/locations', async (req) => {
    const locations = [
      {
        location_id: '4f27ad22-1bce-060c-4bb5-84b6be7fe55f',
        location_name: 'Toronto Store'
      },
      {
        location_id: '695be724-9d8e-197d-3491-47a2af58f0b7',
        location_name: 'Montréal Warehouse'
      }
    ]
    await delay(3000)
    return HttpResponse.json({ locations })
  }),

  http.get('/api/obius/products', (req) => {
    // const products = [
    //   {
    //     product_id: '1227d3d6-067e-938d-18ca-361b0b965884',
    //     product_sku: 'yes',
    //     product_name: 'ROPE + INITIALE K OR 19.5" / D',
    //     product_supplier: 'Obius Test Store',
    //     product_status: 'draft',
    //     product_variant_name: 'some result 1',
    //     product_variant1: '19.5"',
    //     product_variant2: 'D',
    //     product_variant3: null,
    //     product_barcode: null,
    //     product_seasonal: false,
    //     type: 'bundle',
    //     company_id: 1,
    //     moq: 0,
    //     target_days_inventory: 15,
    //     min_days_inventory: 15,
    //     lead_time: 15,
    //     sell_price: '50.00',
    //     cost: '0.00',
    //     inventory_type: 'material',
    //     created_at: '2024-03-13T09:59:05.214Z',
    //     updated_at: '2024-03-13T09:59:05.214Z'
    //   },
    //   {
    //     product_id: '05586671-2d70-b67e-d215-982a09416a79',
    //     product_sku: 'no',
    //     product_name: 'Jeans 501 / 28 / black',
    //     product_supplier: 'Obius Test Store',
    //     product_status: 'active',
    //     product_variant_name: 'some result 2',
    //     product_variant1: '501',
    //     product_variant2: '28',
    //     product_variant3: 'black',
    //     product_barcode: '',
    //     product_seasonal: false,
    //     type: '',
    //     company_id: 1,
    //     moq: 0,
    //     target_days_inventory: 15,
    //     min_days_inventory: 15,
    //     lead_time: 15,
    //     sell_price: '0.00',
    //     cost: '0.00',
    //     inventory_type: 'product',
    //     created_at: '2024-03-13T09:59:04.694Z',
    //     updated_at: '2024-03-13T09:59:04.694Z'
    //   },
    //   {
    //     product_id: '054586671-2d70-b67e-d215-982a09416a79',
    //     product_sku: 'no',
    //     product_name: 'Jeans 505 / 28 / black',
    //     product_supplier: 'Obius Test Store',
    //     product_status: 'active',
    //     product_variant_name: 'some result 3',
    //     product_variant1: '501',
    //     product_variant2: '28',
    //     product_variant3: 'blue',
    //     product_barcode: '',
    //     product_seasonal: false,
    //     type: '',
    //     company_id: 1,
    //     moq: 0,
    //     target_days_inventory: 15,
    //     min_days_inventory: 15,
    //     lead_time: 15,
    //     sell_price: '0.00',
    //     cost: '0.00',
    //     inventory_type: 'finished_product',
    //     created_at: '2024-03-13T09:59:04.694Z',
    //     updated_at: '2024-03-13T09:59:04.694Z'
    //   },
    //   {
    //     product_id: '05458663371-2d70-b67e-d215-982a09416a79',
    //     product_sku: 'no',
    //     product_name: 'Snowboard 505 / 28 / blue',
    //     product_supplier: 'Obius Test Store',
    //     product_status: 'active',
    //     product_variant_name: 'Blue',
    //     product_variant1: '501',
    //     product_variant2: '28',
    //     product_variant3: 'blue',
    //     product_barcode: '',
    //     product_seasonal: false,
    //     type: '',
    //     company_id: 1,
    //     moq: 0,
    //     target_days_inventory: 25,
    //     min_days_inventory: 24,
    //     lead_time: 25,
    //     sell_price: '0.00',
    //     cost: '0.00',
    //     inventory_type: null,
    //     created_at: '2024-03-13T09:59:04.694Z',
    //     updated_at: '2024-03-13T09:59:04.694Z'
    //   }
    // ]
    let products = []
    if (!noProducts) {
      products = Array.from({ length: 100 }, () => ({
        product_name: getRandomProductName(),
        product_sku: (Math.floor(Math.random() * 100) + 1).toString(),
        product_variant_name: 'Variant ' + (Math.floor(Math.random() * 5) + 1),
        product_supplier: 'Supplier ' + (Math.floor(Math.random() * 10) + 1),
        moq: ['0', null][Math.floor(2 * Math.random())],
        lead_time: ['15', null][Math.floor(2 * Math.random())],
        target_days_inventory: ['15', null][Math.floor(2 * Math.random())],
        min_days_inventory: ['15', null][Math.floor(2 * Math.random())],
        is_inventory_tracked: ['t', 'f'][Math.floor(2 * Math.random())],
        product_status: ['draft', 'active'][Math.floor(2 * Math.random())],
        product_id: objectToId({ ramdom1: Math.random(), random2: Math.random() }),
        external_variant_id: Math.floor(Math.random() * 1e14).toString().padStart(14, '0'),
        external_product_id: '9022348329274'
      }))
    }
    return HttpResponse.json({ products, total: products.length })
  }),
  http.get('/api/user/null', () => {
    return HttpResponse.json({
      users_name: 'John Doe'
    })
  }),
  http.patch('/api/user/54', () => {
    return HttpResponse.json({
      company_id: Math.floor(Math.random() * 100) + 1
    })
  }),
  http.get('/api/job/jobs', async () => {
    if (returnJobSnapshots) {
      const snapshotDates = {
        "jobs": [
            {
                "start_date": "2025-03-06T15:35:12.613Z"
            },
            {
                "start_date": "2025-03-06T12:13:53.621Z"
            },
            {
                "start_date": "2025-03-06T10:07:25.713Z"
            },
            {
                "start_date": "2025-03-06T10:01:55.583Z"
            },
            {
                "start_date": "2025-03-05T18:25:11.828Z"
            },
            {
                "start_date": "2025-03-05T16:04:44.244Z"
            },
            {
                "start_date": "2025-03-03T19:15:15.366Z"
            },
            {
                "start_date": "2025-03-03T10:51:47.780Z"
            },
            {
                "start_date": "2025-02-18T13:27:50.650Z"
            },
            {
                "start_date": "2025-02-18T13:08:02.872Z"
            },
            {
                "start_date": "2025-02-18T12:42:39.743Z"
            },
            {
                "start_date": "2025-02-18T11:14:07.209Z"
            },
            {
                "start_date": "2025-02-13T09:15:33.861Z"
            },
            {
                "start_date": "2025-02-12T18:22:18.074Z"
            },
            {
                "start_date": "2025-02-12T17:31:22.336Z"
            },
            {
                "start_date": "2025-02-07T17:20:04.963Z"
            },
            {
                "start_date": "2025-02-07T17:05:19.850Z"
            },
            {
                "start_date": "2025-02-07T16:32:45.271Z"
            },
            {
                "start_date": "2025-02-07T16:17:44.778Z"
            },
            {
                "start_date": "2025-02-07T15:55:30.398Z"
            }
        ],
        "total": 20
      }
      await delay(1000)
      return HttpResponse.json(snapshotDates)
    } else {
      ++numberOfJobRequest
      const steps = ['step-1', 'step-2', 'step-3', 'step-4', 'step-5']
      const jobs = [
        {
          start_date: '2024-04-09T07:01:13.670Z',
          status: (testShowBanner && numberOfJobRequest < 5) ? 'in_progress' : testShowErrorBanner ? 'failed' : 'succeeded',
          steps: steps.slice(0, numberOfJobRequest),
          job_type: 'initial_end_to_end'
        }
      ]
      await delay(100)
      return HttpResponse.json({ jobs })
    }
  }),
  http.patch('/api/obius/products/:productId', (req) => {
    const products = [{}]
    return HttpResponse.json(products)
  }),
  http.patch('/api/obius/companies/:companyId', (req) => {
    const company = {}
    return HttpResponse.json(company)
  }),
  http.get('/api/obius/companies/my-company', async () => {
    const company = {
      last_update: testNoLastUpdate ? null : '2024-04-09T07:01:13.670Z',
      company_id: 2,
      company_status: testShopifyStatus,
      shop_name: 'quickstart-c6bc741d.myshopify.com',
      subscription_status: testShopifySubscriptionStatus,
      subscription_plan: subscriptionPlan,
      params: testNullParams
        ? null
        : {
            dailyVelocityThreshold: 0,
            replenishSoonDayThreshold: 0,
            deadstockDayThreshold: 0,
            overstockDayThreshold: 0,
            leadTime: 0,
            minDaysInventory: null,
            moq: 10
          }
    }
    await delay(1500)
    return HttpResponse.json(company)
  }),
  http.get('/api/obius/companies', () => {
    const companyIds = {
      companies: Array.from({ length: 100 }, (_, index) => ({
        company_id: index + 1,
        company_name: `Company ${index + 1}`
      })),
      total: 100
    }
    return HttpResponse.json(companyIds)
  }),
  http.put('/api/obius/boms/:bomId', async () => {
    const bom = {
      bom_id: '0075901b-edca-c3d0-bc55-4f6456261b09',
      status: 'active',
      output_quantity: 1,
      output_product: {
        product_id: '1be1f6c6-e918-af59-3da3-fea34253ec8d',
        product_name: 'Different',
        product_variant_name: '19.5 / D'
      },
      materials: [
        {
          bom_id: '0075901b-edca-c3d0-bc55-4f6456261b09',
          material_id: '1be1f6c6-e918-af59-3da3-fea34253ec8v',
          product: {
            moq: 0,
            product_name: 'Changed',
            product_id: 'xyz'
          },
          quantity: 50,
          product_id: 'product-id'
        },
        {
          bom_id: '0075901b-edca-c3d0-bc55-4f6456261b09',
          material_id: '1be1f6c6-e918-af59-3da3-fea34253ec8r',
          product: {
            moq: 0,
            product_name: 'Argent (PF: 34$)',
            product_id: 'http'
          },
          quantity: 6,
          product_id: 'product-id-2'
        }
      ]
    }
    await delay(1500)
    return HttpResponse.json(bom)
  }),
  http.put('/api/obius/boms', async () => {
    const total = 27
    const boms = createBoms(total)
    await delay(1500)
    return HttpResponse.json(({ boms, total }))
  }),
  http.put('/api/obius/productions/:id', async () => {
    const production = {
      production_id: 'prod-10',
      output_quantity: 1,
      production_date: new Date().toISOString(),
      output_product: {
        product_name: 'PUT Product',
        product_variant_name: 'Different PUT Variant'
      }
    }
    await delay(1500)
    return HttpResponse.json(production)
  }),
  http.get('/api/obius/boms/:bomId', async ({ params }) => {
    const { bomId } = params
    const bom = {
      bom_id: '0075901b-edca-c3d0-bc55-4f6456261b09',
      status: 'draft',
      output_quantity: 10,
      location_id: '695be724-9d8e-197d-3491-47a2af58f0b7',
      production_date: Date.now(),
      output_product_id: '1be1f6c6-e918-af59-3da3-fea34253ec8d',
      output_product: {
        product_id: '1be1f6c6-e918-af59-3da3-fea34253ec8d',
        product_name: `ANNABELLE - Earrings - Argent_bomId=${bomId}`,
        external_product_id: '9022348329274',
        external_variant_id: '50546645860666',
        product_variant_name: '19.5 / Earings',
        inventory_type: 'finished_product'
      },
      materials: [
        {
          bom_id: '0075901b-edca-c3d0-bc55-4f6456261b06',
          material_id: '1be1f6c6-e918-af59-3da3-fea34253ec8v',
          product: {
            moq: 0,
            product_name: 'ANNABELLE coeur - Pendentif Argent (PF: 34$)',
            product_variant_name: 'Argent (PF: 34$)',
            external_product_id: '9748173685050',
            external_variant_id: '50487439458618'
          },
          quantity: 2,
          product_id: 'product-id'
        },
        {
          bom_id: '0075901b-edca-c3d0-bc55-4f6456261b09',
          material_id: '1be1f6c6-e918-af59-3da3-fea34253ec8r',
          product: {
            moq: 0,
            product_name: 'Argent (PF: 34$)',
            product_variant_name: 'Lustré',
            external_product_id: '9748173685050',
            external_variant_id: '50487439458618'
          },
          quantity: 2,
          product_id: 'product-id-2'
        }
      ]
    }
    await delay(1500)
    return HttpResponse.json(bom)
  }),
  http.get('/api/obius/productions', async ({ request }) => {
    const url = new URL(request.url)
    const limit = parseInt(url.searchParams.get('limit') || '50')
    const skip = parseInt(url.searchParams.get('skip') || '0')

    const createdProductions = (total) => {
      const productions = []
      const startDate = new Date('2024-06-21T00:00:00Z').getTime()
      const endDate = new Date().getTime()

      for (let i = 1; i <= total; i++) {
        const production = {
          production_id: `prod-${i}`,
          output_quantity: i + i / 100, // Adding extra since numeric(10, 3) in the db
          production_date: (new Date(startDate + Math.random() * (endDate - startDate))).toISOString(),
          output_product: {
            product_name: `Product ${i}`,
            product_variant_name: `Different Variant ${i + 1}`,
            inventory_type: 'finished_product'
          },
          consumptions: [
            {
              status: 'done',
              product: {
                moq: 0,
                cost: 0,
                type: '',
                lead_time: null,
                company_id: 1,
                created_at: '2024-04-19T14:54:50.86389+00:00',
                product_id: 'ab808285-9068-416f-d301-b8af04c93b56',
                sell_price: 0,
                updated_at: '2024-09-26T17:36:39.843714+00:00',
                is_material: false,
                product_sku: '',
                product_name: 'Necklace Or',
                inventory_type: 'material',
                product_status: 'active',
                product_barcode: '',
                product_seasonal: false,
                product_supplier: 'Obius Test Store',
                product_variant1: '',
                product_variant2: null,
                product_variant3: null,
                min_days_inventory: null,
                external_product_id: '9057071628602',
                external_variant_id: '47939804430650',
                is_inventory_tracked: true,
                product_variant_name: '',
                target_days_inventory: 15,
                external_inventory_item_id: '49958816907578'
              },
              quantity: 10,
              company_id: 1,
              created_at: '2024-06-21T14:49:00.383+00:00',
              product_id: 'ab808285-9068-416f-d301-b8af04c93b56',
              updated_at: '2024-06-25T15:53:49.614215+00:00',
              location_id: 'e366cd93-6025-3067-995e-cf267efb41dc',
              material_id: '246a352f-ad01-7364-0202-af6cbce94f39',
              production_id: 'eeafba84-b028-8a6c-f075-67d775c19bea',
              consumption_id: '40cc1f65-5bef-74ce-c6b7-b2ea5ac209eb'
            },
            {
              status: 'done',
              product: {
                moq: 0,
                cost: 0,
                type: '',
                lead_time: null,
                company_id: 1,
                created_at: '2024-04-19T14:54:50.300833+00:00',
                product_id: 'fa83bb5c-8436-ddde-bac6-eddf41619f36',
                sell_price: 5,
                updated_at: '2024-09-26T17:35:38.074803+00:00',
                is_material: false,
                product_sku: 'DIV-CLEAR',
                product_name: 'Divine Pendentif',
                inventory_type: 'material',
                product_status: 'active',
                product_barcode: '',
                product_seasonal: false,
                product_supplier: 'Obius Test Store',
                product_variant1: 'clear',
                product_variant2: null,
                product_variant3: null,
                min_days_inventory: null,
                external_product_id: '9057068941626',
                external_variant_id: '47939786604858',
                is_inventory_tracked: true,
                product_variant_name: 'clear',
                target_days_inventory: 15,
                external_inventory_item_id: '49958799081786'
              },
              quantity: 10,
              company_id: 1,
              created_at: '2024-06-21T14:49:00.874+00:00',
              product_id: 'fa83bb5c-8436-ddde-bac6-eddf41619f36',
              updated_at: '2024-06-25T15:53:50.528636+00:00',
              location_id: 'e366cd93-6025-3067-995e-cf267efb41dc',
              material_id: 'e158d3d2-d7a9-12b0-4b66-b8945f6fb251',
              production_id: 'eeafba84-b028-8a6c-f075-67d775c19bea',
              consumption_id: 'baeb1dfe-357d-26c9-aa6e-7124ced293d7'
            }
          ]
        }

        productions.push(production)
      }

      return productions
    }

    const totalProductions = 48 // Total number of Productions in the "database"
    const allProductions = createdProductions(totalProductions)
    const paginatedProductions = allProductions.slice(skip, skip + limit)

    await delay(1500)

    return HttpResponse.json({
      productions: paginatedProductions,
      total: totalProductions
    })
  }),
  http.get('/api/obius/productions/:id', async ({ params }) => {
    const { id } = params
    const bomId = id.substring(5)
    const production = {
      bom_id: `bom-${bomId}`,
      output_location_id: parseInt(bomId) % 2 === 0 ? '695be724-9d8e-197d-3491-47a2af58f0b7' : '4f27ad22-1bce-060c-4bb5-84b6be7fe55f',
      output_quantity: '7.512',
      output_product: {
        product_id: '975a108d-ff0c-c1e7-13f3-a8f55fa83a8d',
        product_sku: '',
        product_name: 'Necklace Or Divine (BOM Test)',
        product_supplier: 'Obius Test Store',
        product_status: 'active',
        product_variant1: '',
        product_variant2: null,
        product_variant3: null,
        product_barcode: '',
        product_seasonal: false,
        type: '',
        company_id: 1,
        moq: 0,
        target_days_inventory: 15,
        min_days_inventory: null,
        lead_time: null,
        sell_price: 1000,
        cost: 0,
        is_material: false,
        created_at: '2024-06-21T14:47:27.511799+00:00',
        updated_at: '2024-09-26T17:36:39.838589+00:00',
        product_variant_name: '',
        is_inventory_tracked: false,
        external_product_id: '9411596812602',
        external_variant_id: '48917324300602',
        external_inventory_item_id: '50932252115258',
        inventory_type: 'bundled_product'
      },
      consumptions: [
        {
          status: 'done',
          product: {
            moq: 0,
            cost: 0,
            type: '',
            lead_time: null,
            company_id: 1,
            created_at: '2024-04-19T14:54:50.86389+00:00',
            product_id: 'ab808285-9068-416f-d301-b8af04c93b56',
            sell_price: 0,
            updated_at: '2024-09-26T17:36:39.843714+00:00',
            is_material: false,
            product_sku: '',
            product_name: 'Necklace Or',
            inventory_type: 'material',
            product_status: 'active',
            product_barcode: '',
            product_seasonal: false,
            product_supplier: 'Obius Test Store',
            product_variant1: '',
            product_variant2: null,
            product_variant3: null,
            min_days_inventory: null,
            external_product_id: '9057071628602',
            external_variant_id: '47939804430650',
            is_inventory_tracked: true,
            product_variant_name: '',
            target_days_inventory: 15,
            external_inventory_item_id: '49958816907578'
          },
          quantity: 10,
          company_id: 1,
          created_at: '2024-06-21T14:49:00.383+00:00',
          product_id: 'ab808285-9068-416f-d301-b8af04c93b56',
          updated_at: '2024-06-25T15:53:49.614215+00:00',
          location_id: 'e366cd93-6025-3067-995e-cf267efb41dc',
          material_id: '246a352f-ad01-7364-0202-af6cbce94f39',
          production_id: 'eeafba84-b028-8a6c-f075-67d775c19bea',
          consumption_id: '40cc1f65-5bef-74ce-c6b7-b2ea5ac209eb'
        },
        {
          status: 'done',
          product: {
            moq: 0,
            cost: 0,
            type: '',
            lead_time: null,
            company_id: 1,
            created_at: '2024-04-19T14:54:50.300833+00:00',
            product_id: 'fa83bb5c-8436-ddde-bac6-eddf41619f36',
            sell_price: 5,
            updated_at: '2024-09-26T17:35:38.074803+00:00',
            is_material: false,
            product_sku: 'DIV-CLEAR',
            product_name: 'Divine Pendentif',
            inventory_type: 'material',
            product_status: 'active',
            product_barcode: '',
            product_seasonal: false,
            product_supplier: 'Obius Test Store',
            product_variant1: 'clear',
            product_variant2: null,
            product_variant3: null,
            min_days_inventory: null,
            external_product_id: '9057068941626',
            external_variant_id: '47939786604858',
            is_inventory_tracked: true,
            product_variant_name: 'clear',
            target_days_inventory: 15,
            external_inventory_item_id: '49958799081786'
          },
          quantity: 15,
          company_id: 1,
          created_at: '2024-06-21T14:49:00.874+00:00',
          product_id: 'fa83bb5c-8436-ddde-bac6-eddf41619f36',
          updated_at: '2024-06-25T15:53:50.528636+00:00',
          location_id: 'e366cd93-6025-3067-995e-cf267efb41dc',
          material_id: 'e158d3d2-d7a9-12b0-4b66-b8945f6fb251',
          production_id: 'eeafba84-b028-8a6c-f075-67d775c19bea',
          consumption_id: 'baeb1dfe-357d-26c9-aa6e-7124ced293d7'
        }
      ]
    }
    await delay(500)
    return HttpResponse.json(production)
  }),
  http.get('/api/obius/boms', async ({ request }) => {
    const url = new URL(request.url)
    const limit = parseInt(url.searchParams.get('limit') || '50')
    const skip = parseInt(url.searchParams.get('skip') || '0')
    const totalBoms = 61
    const allBoms = createBoms(totalBoms)
    const paginatedBoms = allBoms.slice(skip, skip + limit)
    await delay(1500)
    return HttpResponse.json({
      boms: paginatedBoms,
      total: totalBoms
    })
  }),
  http.get('/api/job/cron-jobs?company_id=2&job_type=replenishment_alert&fields=uuid,data,active', async () => {
    const crons = [{
      id: 2,
      name: 'Mock Cron Job 2',
      category: 'shopify',
      job_type: 'replenishment_alert',
      schedule: '0 2 * * 2',
      company_id: 2,
      uuid: objectToId({ companyId: 2, jobType: 'replenishment_alert' }),
      data: { email: 'job@done.com' },
      active: true
    }]
    await delay(2500)
    return HttpResponse.json({ cronJobs: crons, total: 1 })
  }),
  http.get('/api/katana-api/purchase-orders', async () => {
    const purchaseOrders = [purchaseOrderStub]
    const locations = Array(10).fill(locationStub)
    const suppliers = Array(10).fill(supplierStub)
    const products = Array(10).fill(productStub)
    const taxRates = Array(10).fill(taxRateStub)
    await delay(1500)
    return HttpResponse.json({
      purchaseOrders: {
        purchaseOrders,
        total: purchaseOrders.length
      },
      locations: {
        locations,
        total: locations.length
      },
      suppliers: {
        suppliers,
        total: suppliers.length
      },
      products: {
        products,
        total: products.length
      },
      taxRates: {
        taxRates,
        total: taxRates.length
      }
    })
  }),
  http.get('/api/katana-api/purchase-orders-error-400-simulation', async () => {
    await delay(1500)
    return HttpResponse.json(
      {
        error: {
          message: "Missing required field 'order_no' in row 1"
        }
      },
      { status: 400 }
    )
  }),
  http.get('/api/katana-api/purchase-orders-error-500-simulation', async () => {
    await delay(1500)
    return HttpResponse.json(
      {
        errors: [
          {
            message: "Missing required field 'id' in row 1"
          },
          {
            message: "Missing required field 'order_no' in row 2"
          }
        ]
      },
      { status: 500 }
    )
  }),
  http.get('/api/katana-api/suppliers', async ({ request }) => {
    const url = new URL(request.url)
    const limit = url.searchParams.get('limit')
    const parsedLimit = parseInt(limit)
    const count = !isNaN(parsedLimit) ? parsedLimit : 10
    const suppliers = Array(count).fill(supplierStub)
    await delay(1500)
    return HttpResponse.json({
      suppliers,
      total: suppliers.length
    })
  }),
  http.post('/api/katana-api/purchase-orders', async () => {
    const purchaseOrders = [purchaseOrderStub]
    await delay(1500)
    return HttpResponse.json({
      purchaseOrders,
      total: purchaseOrders.length
    })
  }),
  http.post('/api/katana-api/suppliers', async () => {
    const suppliers = [supplierStub]
    await delay(1500)
    return HttpResponse.json({
      suppliers,
      total: suppliers.length
    })
  }),
  http.get('/api/obius/backups', async () => {
    await delay(1500)
    return HttpResponse.json(backupMetadataStubs)
    // Simulate no data
    // return new HttpResponse(null, {
    //   status: 404,
    //   statusText: 'Not Found'
    // })
  }),
  http.get('/api/obius/backups/:id', async () => {
    await delay(1500)
    return HttpResponse.json(backupUrlStub)
  }),
  http.put('api/job/cron-jobs/:cronJobId', async () => {
    await delay(2500)
    return HttpResponse.json({
      id: 2,
      name: 'Mock Cron Job 2',
      category: 'shopify',
      job_type: 'replenishment_alert',
      schedule: '0 9 * * 5',
      company_id: 2,
      uuid: objectToId({ companyId: 2, jobType: 'replenishment_alert' }),
      data: { email: 'con@done.com' },
      active: true
    })
  }),
  http.post('/api/auth/shopify-login', async () => {
    if (testShopify401) {
      return new HttpResponse('Unauthorized', {
        status: 401,
        headers: {
          'Content-Type': 'text/plain'
        }
      })
    }
    await delay(2000)
    return HttpResponse.json({ message: 'ok' })
  }),
  http.post('/api/auth/signup', async () => {
    await delay(500)
    if (testInvalidSignUp) {
      return HttpResponse.json(
        { error: { message: 'A user with this email already exists' } },
        { status: 409 }
      )
    }
    return HttpResponse.json({ token: authTokenStub })
  }),
  http.post('/api/auth/login', async () => {
    await delay(500)
    if (testInvalidLogin) {
      return HttpResponse.json(
        { error: { message: 'Invalid email or password' } },
        { status: 400 }
      )
    }
    return HttpResponse.json({ token: authTokenStub })
  }),
  http.put('/api/user/54/password', async () => {
    await delay(500)
    if (testInvalidResetPassword) {
      return HttpResponse.json(
        { error: { message: 'Bad request' } },
        { status: 400 }
      )
    }
    return HttpResponse.json({ user: { users_id: 54 } })
  }),
  http.post('/api/auth/reset-password', async () => {
    await delay(500)
    if (testInvalidResetPassword) {
      return HttpResponse.json(
        { error: { message: 'Bad request' } },
        { status: 400 }
      )
    }
    return HttpResponse.json({})
  }),
  http.post('/api/shopify/subscriptions', () =>
    HttpResponse.json({ confirmation_url: 'https://admin.shopify.com/store/quickstart-c6bc741d', return_url: '/shopify/subscription/return?subscriptionId=test' })
  ),
  http.patch('/api/shopify/subscriptions/:subscriptionId', (req) =>
    HttpResponse.json({ status: 'active', plan: 'premium' })
  ),
  http.delete('/api/obius/boms/:bomId', async ({ params }) => {
    const { bomId } = params
    await delay(1000)

    return HttpResponse.json(
      { message: `BOM with ID ${bomId} has been successfully deleted` },
      { status: 200 }
    )
  }),
  http.patch('/api/obius/boms/:bomId', async ({ params }) => {
    const { bomId } = params
    await delay(1000)

    return HttpResponse.json(
      { message: `BOM with ID ${bomId} has been successfully patched` },
      { status: 200 }
    )
  })
]

export { handlers }
