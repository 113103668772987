import axios from 'axios'
import config from '@src/config'
import { getSessionToken } from '@shopify/app-bridge/utilities'

const axiosInstance = axios.create({
  baseURL: `${config.apiBaseUrl}`,
  withCredentials: true,
})

// Axios instance with custom defaults
const axiosWithCredentials = axios.create({
  baseURL: `${config.apiBaseUrl}`,
  withCredentials: true, // Enable credentials globally
  headers: {
    'Content-Type': 'application/json',
  },
})

const createAxiosInstanceWithHeaders = (customHeaders = {}) => {
  return axios.create({
    baseURL: `${config.apiBaseUrl}`,
    withCredentials: true,
    headers: setHeaders(customHeaders),
  })
}

const setHeaders = (customHeaders) => {
  return {
    'Content-Type': 'application/json',
    ...customHeaders,
  }
}

const backendRequest = async ({ method, url, appBridge, data, requestHeaders = {} }) => {
  let headers = {
    'Content-Type': 'application/json',
    ...requestHeaders,
  }
  if (appBridge) {
    const sessionToken = await getSessionToken(appBridge)
    headers = {
      ...headers,
      Authorization: `Bearer ${sessionToken}`,
      'X-Shopify-App': process.env.REACT_APP_SHOPIFY_APP,
    }
  }
  return axiosInstance.request({ method, url, data, headers })
}

// Instance without credentials
const axiosWithoutCredentials = axios.create({
  baseURL: `${config.apiBaseUrl}`,
})

export { axiosWithCredentials, axiosWithoutCredentials, backendRequest, createAxiosInstanceWithHeaders }
