const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue
    if (dateAsString === null) return -1
    const dateParts = dateAsString.split('-')
    const cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2]),
    )

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
    return 0
  },
  minValidYear: 1984,
  maxValidYear: new Date().getFullYear(),
  inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
  defaultOption: 'lessThan',
}

export default dateFilterParams
