import { useEffect, useMemo, useState, useRef, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { formatter, currencyFormatter } from '@shared/utils/formatter'
import { DataUpdateContext } from '@shared/context/context'
import { reportService } from '@shared/services/reportService'
import { katanaService } from '@src/shared/services/katanaService'
import { ReportTitle } from '@src/apps/katana/components/report/ReportTitle'
import { ReportActionButtons } from '@src/apps/katana/components/report/ReportActionButtons'
import { ReportGrid } from '@src/apps/katana/components/report/ReportGrid'
import {
  parseQuantityString,
  convertIsoStringToDate,
} from '@src/apps/katana/utils'
import {
  BASE_COL_CONFIG,
  NUMERIC_COL_CONFIG,
} from '@src/apps/katana/utils/constants'
import { SnapshotDateSelector } from '@src/apps/katana/components/report/SnapshotDateSelector'

const InventoryReportView = () => {
  const { t, i18n } = useTranslation()
  const [inventoryHistories, setInventoryHistories] = useState([])
  const { lastUpdate, companyId } = useContext(DataUpdateContext)
  const [error, setError] = useState(null)
  const [snapshotDates, setSnapshotDates] = useState([])
  const [selectedSnapshotDate, setSelectedSnapshotDate] = useState('')
  const [isSnapshotDateDropdownOpen, setIsSnapshotDateDropdownOpen] = useState(false)
  const [isSnapshotDateLoading, setIsSnapshotDateLoading] = useState(true)
  const gridApi = useRef(null)
  const prevLang = useRef(i18n.language)

  const fetchInventoryHistories = useCallback(async (asOfDate = null) => {
    if (gridApi.current && !gridApi.current.isDestroyed()) {
      gridApi.current.showLoadingOverlay()
    }

    try {
      if (!lastUpdate) return
      const { inventoryHistories: _inventoryHistories } = await reportService.fetchInventoryHistories({
        historyDate: convertIsoStringToDate([undefined, null].includes(asOfDate) ? lastUpdate : asOfDate),
        companyId,
      })
      if (!_inventoryHistories) throw new Error('Failed to fetch Inventory Allocations')
      setInventoryHistories(_inventoryHistories)
      setError(null)
    } catch (error) {
      setInventoryHistories([])
      console.error('Failed to fetch Inventory Histories:', error)
      setError(error)
    } finally {
      if (gridApi.current) gridApi.current.hideOverlay()
    }
  }, [companyId, lastUpdate])

  const fetchSnapshotDates = useCallback(async () => {
    if (!companyId) return
    try {
      setIsSnapshotDateLoading(true)
      const { jobs } = await katanaService.fetchSnapshotDates({
        companyId,
        limit: 10000,
      })
      if (!jobs) throw new Error('Failed to fetch Snapshot Dates')
      const _snapshotDates = Array.isArray(jobs)
        ? jobs
          .reduce((unique, job) => {
            const dateStr = job.start_date.split('T')?.[0]
            if (!unique.some((item) => item.label === dateStr)) {
              unique.push({ label: dateStr, value: job.start_date })
            }
            return unique
          }, [])
        : []
      setSnapshotDates(_snapshotDates)
      const latestSnapshotDate = _snapshotDates[0]?.label
      await fetchInventoryHistories(latestSnapshotDate)
      setSelectedSnapshotDate(latestSnapshotDate)
      setError(null)
    } catch (error) {
      setSnapshotDates([])
      console.error('Failed to fetch Snapshot Dates:', error)
      setError(error)
    } finally {
      setIsSnapshotDateLoading(false)
    }
  }, [companyId, fetchInventoryHistories])

  useEffect(() => {
    fetchSnapshotDates()
  }, [fetchSnapshotDates])

  const onGridReady = useCallback(async (params) => {
    gridApi.current = params.api

    if (!inventoryHistories.length) gridApi.current.showLoadingOverlay()

    const columnState = [
      {
        colId: 'location.location_name',
        rowGroup: true,
        hide: true,
      },
      {
        colId: 'allocated_inventory_quantity',
        aggFunc: 'sum',
        hide: false,
      },
      {
        colId: 'allocated_inventory_value',
        aggFunc: 'sum',
        hide: false,
      },
      {
        colId: 'unallocated_inventory_quantity',
        aggFunc: 'sum',
        hide: false,
      },
      {
        colId: 'unallocated_inventory_value',
        aggFunc: 'sum',
        hide: false,
      },
    ]
    gridApi.current.applyColumnState({ state: columnState, applyOrder: true })
    gridApi.current.onFilterChanged()
  }, [gridApi, inventoryHistories.length])

  const gridOptions = useMemo(() => {
    return {
      enableCharts: true,
      enableRangeSelection: true,
      defaultColDef: {
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
      },
      groupDefaultExpanded: 1,
      suppressAggFuncInHeader: true,
    }
  }, [])

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: 'columns',
    }
  }, [])

  const getAllocatedInventoryQuantity = ({ data }) => {
    const qtyStock = parseQuantityString(data.qty_stock)
    const qtyCommitted = parseQuantityString(data.qty_committed)
    return Math.min(qtyStock, qtyCommitted)
  }

  const getUnallocatedInventoryQuantity = ({ data }) => {
    const qtyStock = parseQuantityString(data.qty_stock)
    const qtyCommitted = parseQuantityString(data.qty_committed)
    return (qtyStock - Math.min(qtyStock, qtyCommitted))
  }

  const columns = useMemo(() => {
    const baseColConfig = BASE_COL_CONFIG
    const numericColConfig = NUMERIC_COL_CONFIG

    const baseColumns = [
      {
        headerName: t('inventory_history.data.product_name'),
        field: 'product.product_name',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_history.data.product_variant_name'),
        field: 'product.product_variant_name',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_history.data.product_sku'),
        field: 'product.product_sku',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_history.data.is_purchasable'),
        field: 'product.is_purchasable',
        ...baseColConfig,
        cellDataType: 'boolean',
      },
      {
        headerName: t('inventory_history.data.location_name'),
        field: 'location.location_name',
        ...baseColConfig,
        cellDataType: 'text',
        rowGroup: true,
        hide: true,
      },
      {
        headerName: t('inventory_history.data.qty_stock'),
        field: 'qty_stock',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.qty_stock),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_history.data.qty_committed'),
        field: 'qty_committed',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.qty_committed),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_history.data.qty_incoming'),
        field: 'qty_incoming',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.qty_incoming),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_history.data.cost'),
        field: 'cost',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.cost),
        valueFormatter: ({ value }) => currencyFormatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_history.data.allocated_inventory_quantity'),
        field: 'allocated_inventory_quantity',
        ...numericColConfig,
        valueGetter: ({ data }) => getAllocatedInventoryQuantity({ data }),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
        aggFunc: 'sum',
      },
      {
        headerName: t('inventory_history.data.allocated_inventory_value'),
        field: 'allocated_inventory_value',
        ...numericColConfig,
        valueGetter: ({ data }) => {
          const allocatedInventoryQuantity = getAllocatedInventoryQuantity({ data })
          const cost = parseQuantityString(data?.cost)
          return allocatedInventoryQuantity * cost
        },
        valueFormatter: ({ value }) => currencyFormatter.format(value),
        cellDataType: 'number',
        aggFunc: 'sum',
      },
      {
        headerName: t('inventory_history.data.unallocated_inventory_quantity'),
        field: 'unallocated_inventory_quantity',
        ...numericColConfig,
        valueGetter: ({ data }) => getUnallocatedInventoryQuantity({ data }),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
        aggFunc: 'sum',
      },
      {
        headerName: t('inventory_history.data.unallocated_inventory_value'),
        field: 'unallocated_inventory_value',
        ...numericColConfig,
        valueGetter: ({ data }) => {
          const unallocatedInventoryQuantity = getUnallocatedInventoryQuantity({ data })
          const cost = parseQuantityString(data?.cost)
          return unallocatedInventoryQuantity * cost
        },
        valueFormatter: ({ value }) => currencyFormatter.format(value),
        cellDataType: 'number',
        aggFunc: 'sum',
      },
      {
        headerName: t('inventory_history.data.inventory_value'),
        field: 'inventory_value',
        ...numericColConfig,
        valueGetter: ({ data }) => {
          const qtyStock = parseQuantityString(data?.qty_stock)
          const cost = parseQuantityString(data?.cost)
          return qtyStock * cost
        },
        valueFormatter: ({ value }) => currencyFormatter.format(value),
        cellDataType: 'number',
      },
    ]
    return [...baseColumns]
  }, [t])

  useEffect(() => {
    if (prevLang.current === i18n.language || !gridApi.current || gridApi.current.isDestroyed()) return
    prevLang.current = i18n.language
    gridApi.current.setColumnDefs([...columns])
  }, [i18n.language, columns])

  const handleSnapshotDateChange = async (value) => {
    if (value === selectedSnapshotDate) return
    setSelectedSnapshotDate(value)
    await fetchInventoryHistories(value)
  }

  const toggleSnapshotDateDropdownOpen = () => setIsSnapshotDateDropdownOpen((prev) => !prev)

  return (
    <div className='dashboard-container'>
      <ReportTitle
        lastUpdate={lastUpdate}
        title={t('inventory_history.report.page_title')}
      />
      <SnapshotDateSelector
        label={t('inventory_allocation.snapshot_date_selector.label')}
        options={snapshotDates}
        onChange={handleSnapshotDateChange}
        onClick={toggleSnapshotDateDropdownOpen}
        value={selectedSnapshotDate}
        open={isSnapshotDateDropdownOpen}
        isLoading={isSnapshotDateLoading}
      />
      <ReportActionButtons
        gridRef={gridApi}
      />
      <ReportGrid
        error={error}
        noDataTextPlaceholder={t('inventory_history.error.no_data')}
        data={inventoryHistories}
        columns={columns}
        sideBar={sideBar}
        onGridReady={onGridReady}
        gridOptions={gridOptions}
      />
    </div>
  )
}

export default InventoryReportView
