exports.parseQuantityString = (data) => {
  return data !== undefined ? +data : null
}

exports.convertIsoStringToDate = (isoString) => {
  return isoString.split('T')[0]
}

exports.getBannerStatus = ({ bannerState }) => {
  switch (bannerState.status) {
    case 'info': return 'Info'
    case 'critical': return 'Error'
    case 'success': return 'Success'
    default: return ''
  }
}
