import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  ChartVerticalIcon,
  ChartVerticalFilledIcon,
  ImportIcon,
  ExitIcon,
  DatabaseIcon,
} from '@shopify/polaris-icons'
import levelOpsLogo from '@katana/img/logo_levelops_black.png'
import '@shared/styles/sidebar.css'
import Cookies from 'js-cookie'
import { getCookieDomain } from '@shared/utils'
import { getUserId } from '@shared/utils/getUserInfo'
import { axiosWithCredentials } from '@shared/utils/axiosInstance'
import { LanguageSelector } from './LanguageSelector'
import {
  Text,
  Button,
  Box,
  BlockStack,
  Icon,
  InlineStack,
} from '@shopify/polaris'
import { LEVELOPS_URL } from '@src/shared/utils/constant'

const Sidebar = () => {
  const { t, i18n } = useTranslation()
  const [userInfo, setUserInfo] = useState([])

  const handleLogout = () => {
    const cookieDomain = getCookieDomain()
    Cookies.remove('authToken', { domain: cookieDomain, path: '/' })

    window.location.href = '/login'
  }

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language)
    Cookies.set('preferredLanguage', language, { expires: 365 })
  }

  useEffect(() => {
    const savedLanguage = Cookies.get('preferredLanguage')
    if (savedLanguage) i18n.changeLanguage(savedLanguage)
    else i18n.changeLanguage('en')
  }, [i18n])

  useEffect(() => {
    const userID = getUserId()

    axiosWithCredentials
      .get(`/user/${userID}`)
      .then((response) => {
        if (!response.data) {
          throw new Error('Failed to fetch data')
        }
        setUserInfo(response.data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  const printWelcome = ({ userInfo }) => {
    const welcome = `👋 ${t('sidebar.katana.welcome')}`
    const separator = ', '

    const legacyFullName = userInfo.users_name

    if (legacyFullName) return welcome + separator + legacyFullName // Only for Legacy users, modern sign up uses logic below

    const firstName = userInfo.first_name
    const lastName = userInfo.last_name

    if (firstName && lastName) return `${welcome + separator + firstName} ${lastName}` // Modern sign up should always reach here
    if (firstName && !lastName) return welcome + separator + firstName // Could only arise upon custom injection
    return welcome // Could only arise upon custom injection
  }

  return (
    <Box width="270px" padding="600">
      <BlockStack className='sidebar-bg'>
        <Box paddingBlockEnd="500" align='left'>
          <a href={LEVELOPS_URL} target='_blank' rel='noreferrer'>
            <img src={levelOpsLogo} alt='Logo' />
          </a>
        </Box>
        <div className='obius-buttons'>
          <NavLink className='nav-button' to='/inventory-allocation-report'>
            <span className='icon'><ChartVerticalFilledIcon /></span>{' '}
            {t('sidebar.katana.inventory_allocation_report')}
          </NavLink>
          <NavLink className='nav-button' to='/inventory-report'>
            <span className='icon'><ChartVerticalIcon /></span>{' '}
            {t('sidebar.katana.inventory_report')}
          </NavLink>
          <NavLink className='nav-button' to='/procurement-report'>
            <span className='icon'><ChartVerticalFilledIcon /></span>{' '}
            {t('sidebar.katana.procurement_report')}
          </NavLink>
          <NavLink className='nav-button' to='/import-export'>
            <span className='icon'><ImportIcon /></span>{' '}
            {t('sidebar.katana.data_import_export')}
          </NavLink>
          <NavLink className='nav-button' to='/data-backup'>
            <span className='icon'><DatabaseIcon /></span>{' '}
            {t('sidebar.katana.data_backup')}
          </NavLink>
        </div>
        <Box padding="300" paddingBlockStart="2000">
          <BlockStack gap="200">
            <Text variant="headingMd" tone="text-inverse" as="h6">
              {printWelcome({ userInfo })}
            </Text>
            <InlineStack direction="row" align="left" wrap={false} blockAlign="center" gap='150'>
              <Text tone="subdued">
                <span className='logout-text'>
                  <Icon
                    source={ExitIcon}
                    tone="monochromePlain"
                  />
                </span>
              </Text>
              <Text tone="subdued">
                <Button
                  variant="monochromePlain"
                  onClick={handleLogout}
                >
                  {t('common.logout')}
                </Button>
              </Text>
            </InlineStack>
            <LanguageSelector
              value={i18n.language}
              onChange={handleLanguageChange}
            />
          </BlockStack>
        </Box>
      </BlockStack>
    </Box>
  )
}

export default React.memo(Sidebar)
