import React, { useEffect, useState, useContext } from 'react'
import {
  Card,
  Text,
  Banner,
  BlockStack,
  ResourceList,
  ResourceItem,
  InlineStack,
  Icon,
  Spinner,
  EmptyState,
  Box,
} from '@shopify/polaris'
import { dataBackupService } from '@src/shared/services/dataBackupService'
import { DatabaseIcon } from '@shopify/polaris-icons'
import { DataUpdateContext } from '@shared/context/context'
import { dateTimeFormatter } from '@shared/utils/formatter'
import { useTranslation } from 'react-i18next'
import { getBannerStatus } from '@katana/utils'

const DataBackupCard = () => {
  const { companyId } = useContext(DataUpdateContext)
  const [bannerState, setBannerState] = useState({
    show: false,
    status: '',
    message: '',
  })
  const [backupsMetadata, setBackupsMetadata] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [downloadingItems, setDownloadingItems] = useState({})
  const { i18n, t } = useTranslation()

  const downloadBlob = ({ blob, key }) => {
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = key.includes('/') ? key.split('/').pop() : key // soft key check to evade any errors
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(link.href)
  }

  const handleDownloadBackup = async ({ key, id }) => {
    setDownloadingItems((prev) => ({ ...prev, [id]: true }))

    try {
      const { url } = await dataBackupService.fetchBackup({ id, companyId })
      const azureUrlFetchResponse = await fetch(url)
      if (!azureUrlFetchResponse.ok) throw new Error(`Failed to download backup ${id}:${key}`)
      const blob = await azureUrlFetchResponse.blob()
      downloadBlob({ blob, key })
      setBannerState({
        show: true,
        status: 'success',
        message: 'Backup successfully downloaded!',
      })
    } catch (error) {
      setBannerState({
        show: true,
        status: 'critical',
        message: error.message,
      })
    } finally {
      setDownloadingItems((prev) => ({ ...prev, [id]: false }))
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        let _backupsMetadata = await dataBackupService.fetchBackupsMetadata({ companyId })
        _backupsMetadata = _backupsMetadata.sort((a, b) => b.id - a.id) // latest first
        setBackupsMetadata(_backupsMetadata)
      } catch (error) {
        setBannerState({
          show: true,
          status: 'critical',
          message: error.message,
        })
      } finally {
        setIsLoading(false)
      }
    }

    if (companyId) fetchData()
  }, [companyId])

  const renderMedia = (isDownloading) => isDownloading ? <Spinner size="small" /> : <Icon source={DatabaseIcon} tone="base" />

  const renderBackup = (backupMetadata) => {
    const { id, created_at: createdAt, key } = backupMetadata
    const createdAtDate = dateTimeFormatter(createdAt, i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language || 'en')
    const isDownloading = downloadingItems[id]
    return (
      <ResourceItem
        id={id}
        onClick={() => handleDownloadBackup({ key, id })}
        media={renderMedia(isDownloading)}
      >
        <InlineStack>
          <Text variant="bodyMd" fontWeight="bold" as="h3">
            {createdAtDate}
          </Text>
        </InlineStack>
      </ResourceItem>
    )
  }

  const emptyStateMarkup = (
    <EmptyState
      heading="No backup available right now"
      image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
    />
  )

  const backupsListMarkup = () => {
    if (isLoading) {
      return (
        <Box padding='1000'>
          <InlineStack align="center">
            <Spinner />
          </InlineStack>
        </Box>
      )
    }
    if (!backupsMetadata.length) {
      return emptyStateMarkup
    }
    return (
      <ResourceList
        resourceName={{ singular: 'backup', plural: 'backups' }}
        items={backupsMetadata}
        renderItem={renderBackup}
      />
    )
  }

  return (
    <Card>
      <BlockStack gap="400">
        <Text variant="heading2xl" as="h2">
          {t('data_backup.subtitle')}
        </Text>
        {bannerState.show && (
          <Banner
            title={getBannerStatus({ bannerState })}
            tone={bannerState.status}
            onDismiss={() => setBannerState({ ...bannerState, show: false })}
          >
            <Text as='p'>{bannerState.message}</Text>
          </Banner>
        )}
        {backupsListMarkup()}
      </BlockStack>
    </Card>
  )
}

export default DataBackupCard
