export const TIGHT_LAYOUT = {
  maxWidth: 1000,
  autoHeight: true,
  wrapText: true,
}

export const BASE_COL_CONFIG = ({ isTightLayout = false }) => {
  const baseColumnConfig = {
    filter: 'agSetColumnFilter',
    enableRowGroup: true,
    enablePivot: true,
  }
  if (!isTightLayout) return baseColumnConfig
  return { ...baseColumnConfig, ...TIGHT_LAYOUT }
}

export const NUMERIC_COL_CONFIG = ({ isTightLayout = false }) => {
  return {
    enableValue: true,
    allowedAggFuncs: ['avg', 'count', 'first', 'last', 'max', 'min', 'sum'],
    filter: 'agNumberColumnFilter',
    ...BASE_COL_CONFIG({ isTightLayout }),
  }
}
