import React, { useState, useCallback, useEffect } from 'react'
import {
  Page,
  InlineGrid,
  SkeletonPage,
  SkeletonBodyText,
  Frame,
  BlockStack,
  Box,
  Banner,
  InlineStack,
  Button,
  Text,
  Toast,
} from '@shopify/polaris'
import { DeleteIcon } from '@shopify/polaris-icons'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ProductSelectionModal } from '@bom/components/ProductSelectionModal'
import { useBomDetails } from '@bom/hooks/useBomDetails'
import { AddMaterialsCard } from '@bom/components/AddMaterialsCard'
import { BomCard } from '@bom/components/BomCard'
import { StatusCard } from '@bom/components/StatusCard'
import { InventoryType } from '@bom/components/InventoryType'
import { DeleteBomConfirmationModal } from '@src/apps/bom/components/DeleteBomConfirmationModal'
import { UpgradeButton } from '@shared/components/UpgradeButton'
import { HELP_DESK_URL } from '@shared/utils/constant'

export const BundleDetailsView = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [showToast, setShowToast] = useState({
    active: false,
    message: '',
    doRedirectOnToast: false,
    status: null,
  })
  const [showDeleteBomConfirmationModal, setShowDeleteBomConfirmationModal] = useState(false)
  const [showBundleNewlyCreatedBanner, setShowBundleNewlyCreatedBanner] = useState(false)
  const [forbiddenMaterialIds, setForbiddenMaterialIds] = useState([])
  const [forbiddenProductIds, setForbiddenProductIds] = useState([])
  const [usedMaterialIds, setUsedMaterialIds] = useState([])
  const [usedProductIds, setUsedProductIds] = useState([])

  const {
    bom,
    products,
    isLoading,
    isSaveButtonDisabled,
    error,
    handleSave,
    handleDelete,
    handleQuantityChange,
    handleOutputProductSave,
    handleRemoveOutputProduct,
    handleRemoveMaterial,
    handleMaterialModalSave,
    handleStatusChange,
    handleInventoryTypeChange,
    showOutputProductRequiredError,
    showMaterialRequiredError,
    showBanner,
    showErrorBanner,
    setShowBanner,
    setShowErrorBanner,
    showUpgradeButton,
    showActiveStatusBanner,
    setShowActiveStatusBanner,
  } = useBomDetails(
    id,
    setIsSaving,
    setForbiddenMaterialIds,
    forbiddenProductIds,
    setForbiddenProductIds,
    setUsedMaterialIds,
    setUsedProductIds,
    setShowBundleNewlyCreatedBanner,
  )

  useEffect(() => {
    if (!showToast.doRedirectOnToast) return

    const deletionTimeoutMs = 1500
    const timer = setTimeout(() => {
      navigate('/bundles')
    }, deletionTimeoutMs)

    return () => clearTimeout(timer)
  }, [showToast.doRedirectOnToast, navigate])

  const [showMaterialModal, setShowMaterialModal] = useState(false)
  const [showOutputProductModal, setShowOutputProductModal] = useState(false)
  const [outputProductSearchValue, setOutputProductSearchValue] = useState('')
  const [materialSearchValue, setMaterialSearchValue] = useState('')

  const handleAddMaterial = useCallback((value) => {
    if (value && !showMaterialModal) {
      setShowMaterialModal(true)
    }
  }, [showMaterialModal])

  const handleMaterialSearch = useCallback((value) => {
    setMaterialSearchValue(value)
    setShowMaterialModal(true)
  }, [])

  const handleMaterialModalClose = useCallback(() => {
    setShowMaterialModal(false)
    setMaterialSearchValue('')
  }, [])

  const handleOutputProductSearch = useCallback((value) => {
    setOutputProductSearchValue(value)
    setShowOutputProductModal(true)
  }, [])

  const handleOutputProductModalClose = useCallback(() => {
    setShowOutputProductModal(false)
    setOutputProductSearchValue('')
  }, [])

  const handleSaveWithBanner = useCallback(async () => {
    setIsSaving(true)

    try {
      const newBomId = await handleSave()

      setShowActiveStatusBanner(false)
      setShowBundleNewlyCreatedBanner(true)

      if (newBomId && id === 'new') navigate(`/bundles/${newBomId}`)
    } catch (error) {
      console.error('Error saving bundle:', error)
    }
  }, [handleSave, navigate, id, setShowActiveStatusBanner])

  const handleDeleteWithToast = useCallback(async () => {
    setIsDeleting(true)

    try {
      const isDeleted = await handleDelete(true)
      if (!isDeleted) throw new Error('BOM Deletion Failure')

      setShowToast({
        active: true,
        message: t('bundles.bundle_deleted'),
        doRedirectOnToast: true,
        status: null,
      })
    } catch (error) {
      console.error('Error saving bundle:', error)
    }
  }, [handleDelete, t])

  const toggleShowBundleNewlyCreatedBanner = useCallback(() => setShowBundleNewlyCreatedBanner((active) => !active), [setShowBundleNewlyCreatedBanner])
  const toggleShowActiveStatusBanner = useCallback(() => setShowActiveStatusBanner((active) => !active), [setShowActiveStatusBanner])
  const toggleToast = useCallback(() => setShowToast((active) => !active), [])

  const handleBackClick = useCallback(() => {
    navigate('/bundles')
  }, [navigate])

  if (isLoading) {
    return (
      <SkeletonPage primaryAction>
        <InlineGrid columns={['1fr', '2fr']} gap="400">
          <SkeletonBodyText lines={3} />
          <SkeletonBodyText lines={5} />
        </InlineGrid>
      </SkeletonPage>
    )
  }

  if (error) {
    return <Page title={t('bundles.create_bundle')}>{error}</Page>
  }

  const pageTitle = bom?.output_product
    ? `${bom.output_product.product_name}${bom.output_product.product_variant_name ? ` - ${bom.output_product.product_variant_name}` : ''}`
    : t('bundles.create_bundle')

  const toastMarkup = () => {
    if (!showToast.active) return null

    return (
      <Toast
        content={showToast.message}
        onDismiss={toggleToast}
        {...(showToast.status ? { [showToast.status]: true } : {})}
      />
    )
  }

  const doShowBomDeletionModal = () => {
    setShowDeleteBomConfirmationModal(true)
  }
  const addedBundleSuccessBanner = showBundleNewlyCreatedBanner
    ? (
      <Box paddingBlockEnd='300'>
        <Banner
          title={t('bundles.bundle_saved')}
          onDismiss={toggleShowBundleNewlyCreatedBanner}
          tone='success'
        >
          <Text as='p'>
            {t('banner.bom.addition_success')
              .split('\n')
              .map((line, _) => (
                <Text key={line}>
                  {line}
                  <br />
                </Text>
              ))}
          </Text>
        </Banner>
      </Box>
    )
    : null
  const activeStatusBanner = showActiveStatusBanner
    ? (
      <Box paddingBlockEnd='300'>
        <Banner
          title={t('bundles.bundle_active_info')}
          onDismiss={toggleShowActiveStatusBanner}
          tone='info'
        >
          <Text as='p'>
            {t('banner.bom.active_status')
              .split('\n')
              .map((line, _) => (
                <Text key={line}>
                  {line}
                  <br />
                </Text>
              ))}
          </Text>
        </Banner>
      </Box>
    )
    : null

  const getInitialSelectedProductMaterials = bom.materials ? bom.materials : []
  const getInitialSelectedOutputProduct = bom.output_product ? [bom.output_product] : []

  return (
    <Frame>
      <Page
        title={pageTitle}
        primaryAction={{
          content: t('bundles.save'),
          onAction: handleSaveWithBanner,
          loading: isSaving,
          disabled: isSaveButtonDisabled,
        }}
        secondaryActions={
          <InlineStack blockAlign='center'>
            <UpgradeButton hidden={!showUpgradeButton} />
            <div
              hidden={id === 'new'}
            >
              <Button
                icon={DeleteIcon}
                tone='critical'
                loading={isDeleting}
                onClick={doShowBomDeletionModal}
              >
                {t('bundles.delete')}
              </Button>
            </div>
          </InlineStack>
        }
        backAction={{
          content: t('bundles.back'),
          onAction: handleBackClick,
        }}
      >
        {addedBundleSuccessBanner}
        {activeStatusBanner}
        {showBanner && (
          <div>
            <Box paddingBlock='400'>
              <Banner
                title={t('banner.bom.job_status')}
                onDismiss={() => setShowBanner(false)}
                tone='warning'
              />
            </Box>
          </div>
        )}
        {showErrorBanner && (
          <div>
            <Box paddingBlock='400'>
              <Banner
                title={t('error_banner.title')}
                onDismiss={() => setShowErrorBanner(false)}
                tone='warning'
                action={{
                  content: t('error_banner.button'),
                  url: HELP_DESK_URL,
                }}
              >
                <Box paddingBlockStart='400' paddingBlockEnd='400'>
                  {t('error_banner.content')}
                </Box>
              </Banner>
            </Box>
          </div>
        )}
        <InlineGrid
          columns={['oneThird', 'twoThirds']}
          gap="400"
          alignItems="start"
        >
          <BlockStack gap="200">
            <BomCard
              bom={bom}
              onSelectOutputProduct={handleOutputProductSearch}
              onRemoveOutputProduct={handleRemoveOutputProduct}
              showRequiredError={showOutputProductRequiredError}
            />
            <StatusCard
              onChange={handleStatusChange}
              initialValue={bom.status}
            />
            <InventoryType
              onChange={handleInventoryTypeChange}
              initialValue={bom?.output_product?.inventory_type}
            />
          </BlockStack>
          <AddMaterialsCard
            materials={bom?.materials || []}
            onSearchChange={handleAddMaterial}
            onQuantityChange={handleQuantityChange}
            onRemoveMaterial={handleRemoveMaterial}
            onSelectProduct={handleMaterialSearch}
            searchLabel={t('bundles.add_materials')}
            showRequiredError={showMaterialRequiredError}
          />
        </InlineGrid>
        <ProductSelectionModal
          open={showOutputProductModal}
          onClose={handleOutputProductModalClose}
          onSave={handleOutputProductSave}
          products={products}
          title={t('bundles.all_products')}
          allowMultiple={false}
          initialSelectedProducts={getInitialSelectedOutputProduct}
          initialSearchValue={outputProductSearchValue}
          forbiddenProductIds={forbiddenProductIds}
          forbiddenUsedProductIds={usedProductIds}
          onUpdateForbiddenIds={setForbiddenMaterialIds}
        />
        <ProductSelectionModal
          open={showMaterialModal}
          onClose={handleMaterialModalClose}
          onSave={handleMaterialModalSave}
          products={products}
          title={t('bundles.all_products')}
          allowMultiple
          initialSearchValue={materialSearchValue}
          initialSelectedProducts={getInitialSelectedProductMaterials}
          forbiddenProductIds={forbiddenMaterialIds}
          forbiddenUsedProductIds={usedMaterialIds}
          onUpdateForbiddenIds={setForbiddenProductIds}
        />
        <DeleteBomConfirmationModal
          open={showDeleteBomConfirmationModal}
          setIsOpen={setShowDeleteBomConfirmationModal}
          onDelete={handleDeleteWithToast}
        />
        {toastMarkup()}
      </Page>
    </Frame>
  )
}
