import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import KatanaLayout from '@src/apps/katana/layouts/KatanaLayout'
import LoginView from '@src/shared/views/LoginView'
import { DataUpdateProvider } from '@shared/context/context'
import './App.css'
import InventoryAllocationReportView from '@src/apps/katana/views/report/InventoryAllocationReportView'
import InventoryReportView from '@src/apps/katana/views/report/InventoryReportView'
import ImportExportView from '@src/apps/katana/views/import-export/ImportExportView'
import DataBackupView from '@src/apps/katana/views/data-backup/DataBackupView'
import ResetPasswordView from '@shared/views/ResetPasswordView'
import SignUpView from '@src/shared/views/SignUpView'
import SendResetPasswordEmailView from '@shared/views/SendResetPasswordEmailView'
import { getCompanyId } from '@shared/utils/getUserInfo'
import ConnectView from '@src/apps/katana/views/connect/ConnectView'
import { useTranslation } from 'react-i18next'

const ProtectedRoute = ({ isAuthenticated, loginPath = '/login', connectPath = '/connect' }) => {
  if (!isAuthenticated) return <Navigate to={loginPath} replace />
  if (getCompanyId(Cookies.get('authToken')) === null) return <Navigate to={connectPath} replace />
  return <Outlet />
}
function App () {
  return (
    <div className='App'>
      <DataUpdateProvider>
        <RouteManager />
      </DataUpdateProvider>
    </div>
  )
}

function RouteManager () {
  let isAuthenticated = !!Cookies.get('authToken')
  if (process.env.NODE_ENV === 'development') isAuthenticated = true
  const defaultRoute = isAuthenticated ? <Navigate to='/inventory-report' /> : <Navigate to='/login' />
  const { t } = useTranslation()
  return (
    <Router>
      <Routes>
        <Route
          path='*'
          element={
            defaultRoute
          }
        />
        <Route
          path='/login'
          element={
            <LoginView />
          }
        />
        <Route
          path='/send-reset-password-email'
          element={
            <SendResetPasswordEmailView />
          }
        />
        <Route
          path='/reset-password'
          element={
            <ResetPasswordView />
          }
        />
        <Route
          path='/sign-up'
          element={
            <SignUpView />
          }
        />
        <Route
          path='/connect'
          element={
            <KatanaLayout
              includeTopMenu={false}
            >
              <ConnectView />
            </KatanaLayout>
          }
        />
        <Route
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
            />
          }
        >
          <Route
            index
            element={
              <Navigate
                to='/inventory-allocation-report'
                replace
              />}
          />
          <Route
            path='/inventory-allocation-report'
            element={
              <KatanaLayout>
                <InventoryAllocationReportView
                  rowGroups={new Set(['project_name'])}
                  values={new Set(['calculated_total_cost'])}
                  reportTitle={t('inventory_allocation.report.page_title')}
                  itemAvailabilities={new Set()}
                  columnsToHide={new Set()}
                  isPivotModeEnabled
                  areGroupedColumnsAutoPinned
                  gridSize='l'
                  isTightLayout={false}
                />
              </KatanaLayout>
            }
          />
          <Route
            path='/inventory-report'
            element={
              <KatanaLayout>
                <InventoryReportView />
              </KatanaLayout>
            }
          />
          <Route
            path='/procurement-report'
            element={
              <KatanaLayout>
                <InventoryAllocationReportView
                  rowGroups={new Set()}
                  values={new Set(['calculated_total_cost', 'item_calculated_quantity'])}
                  reportTitle={t('procurement.report.page_title')}
                  itemAvailabilities={new Set(['NOT_AVAILABLE', 'EXPECTED', 'IN_STOCK'])}
                  columnsToHide={new Set(['mo_name', 'location_name', 'mo_status', 'mo_product_sku', 'mo_product_name', 'mo_product_variant_name', 'mo_planned_quantity', 'mo_actual_quantity', 'mo_calculated_quantity', 'item_is_purchasable', 'item_product_variant_name', 'item_planned_quantity_per_unit', 'item_actual_quantity', 'inventory_value_per_unit', 'item_total_cost', 'order_date', 'done_date', 'value_type'])}
                  isPivotModeEnabled={false}
                  areGroupedColumnsAutoPinned={false}
                  gridSize='xxl'
                  isTightLayout
                />
              </KatanaLayout>
            }
          />
          <Route
            path='/import-export'
            element={
              <KatanaLayout>
                <ImportExportView />
              </KatanaLayout>
            }
          />
          <Route
            path='/data-backup'
            element={
              <KatanaLayout>
                <DataBackupView />
              </KatanaLayout>
            }
          />
        </Route>
      </Routes>
    </Router>
  )
}

export default App
