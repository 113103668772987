import { backendRequest } from '@shared/utils/axiosInstance'

export const dataBackupService = {
  fetchBackupsMetadata: async ({ companyId }) => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: `/obius/backups?company_id=${companyId}&source=katana`,
      })
      return response.data.backupsMetadata
    } catch (error) {
      if (error?.response?.status === 404) return []
      console.error(error)
      throw new Error(`Katana Service: Error fetching backups metadata for company: ${companyId}`)
    }
  },
  fetchBackup: async ({ id, companyId }) => {
    try {
      const response = await backendRequest({
        method: 'GET',
        url: `/obius/backups/${id}?company_id=${companyId}`,
      })
      return response.data
    } catch (error) {
      console.error(error)
      throw new Error(`Katana Service: Error fetching backup with id: ${id} for company: ${companyId}`)
    }
  },
}
