import {
  Card,
  BlockStack,
  IndexTable,
  Text,
  EmptyState,
  SkeletonPage,
  SkeletonBodyText,
  InlineGrid,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export const MaterialsCard = ({
  materials,
  isReadonly,
  outputQuantity,
  isLoadingComponents = false,
}) => {
  const { t } = useTranslation()
  const totalQuantityFactor = Math.trunc(parseInt(outputQuantity))

  const emptyStateMarkup = (
    <EmptyState
      heading={t('productions.empty_state.title')}
      image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      imageContained
    >
      <Text as="p" variant="bodyMd" color="subdued">
        {t('productions.empty_state.description')}
      </Text>
    </EmptyState>
  )

  const LoadingComponentsSkeleton = () => {
    return (
      <SkeletonPage primaryAction>
        <InlineGrid columns={['twoThirds', 'oneThird']} gap='100'>
          <SkeletonBodyText lines={5} />
          <SkeletonBodyText lines={5} />
        </InlineGrid>
      </SkeletonPage>
    )
  }

  const displayUnitQuantity = (material) => isReadonly
    ? (Math.trunc(material.quantity / totalQuantityFactor)).toString()
    : (material.quantity).toString()

  const displayTotalQuantity = (material) => isReadonly
    ? (material.quantity).toString()
    : (material.quantity * totalQuantityFactor).toString()

  const MaterialsIndexTable = () => {
    return (
      <IndexTable
        itemCount={materials.length}
        emptyState={emptyStateMarkup}
        headings={[
          { title: t('productions.product') },
          { title: t('productions.unit_quantity') },
          { title: t('productions.total_quantity') },
        ]}
        selectable={false}
      >
        {materials.map((material, index) => (
          <IndexTable.Row
            id={material.material_id}
            key={material.material_id}
            position={index}
          >
            <IndexTable.Cell>
              <BlockStack gap="100">
                <Text variant="bodyMd" fontWeight="bold">
                  {material.product.product_name}
                </Text>
                {material.product.product_variant_name && (
                  <Text variant="bodySm" color="subdued">
                    {material.product.product_variant_name}
                  </Text>
                )}
              </BlockStack>
            </IndexTable.Cell>
            <IndexTable.Cell alignment="center">
              <Text variant="bodySm" color="subdued">
                {displayUnitQuantity(material)}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell alignment="center">
              <Text variant="bodySm" color="subdued">
                {displayTotalQuantity(material)}
              </Text>
            </IndexTable.Cell>
          </IndexTable.Row>
        ))}
      </IndexTable>
    )
  }

  const content = isLoadingComponents ? <LoadingComponentsSkeleton /> : <MaterialsIndexTable />

  return (
    <Card title={t('productions.materials')}>
      <BlockStack gap='400'>
        <Text as="h2" variant="headingMd">
          {t('productions.materials')}
        </Text>
        {content}
      </BlockStack>
    </Card>
  )
}
