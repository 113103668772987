import { useEffect, useMemo, useState, useRef, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { formatter, currencyFormatter, integerFormatter } from '@shared/utils/formatter'
import { DataUpdateContext } from '@shared/context/context'
import { reportService } from '@shared/services/reportService'
import { katanaService } from '@src/shared/services/katanaService'
import { ReportTitle } from '@src/apps/katana/components/report/ReportTitle'
import { ReportActionButtons } from '@src/apps/katana/components/report/ReportActionButtons'
import { ReportGrid } from '@src/apps/katana/components/report/ReportGrid'
import {
  parseQuantityString,
  convertIsoStringToDate,
} from '@src/apps/katana/utils'
import {
  BASE_COL_CONFIG,
  NUMERIC_COL_CONFIG,
} from '@src/apps/katana/utils/constants'
import { SnapshotDateSelector } from '@src/apps/katana/components/report/SnapshotDateSelector'

const InventoryAllocationReportView = ({
  rowGroups = new Set(),
  values = new Set(),
  itemAvailabilities = new Set(),
  columnsToHide = new Set(),
  reportTitle = '',
  isPivotModeEnabled = true,
  areGroupedColumnsAutoPinned = true,
  gridSize = 'l',
  isTightLayout = false,
}) => {
  const { t, i18n } = useTranslation()
  const { lastUpdate, companyId } = useContext(DataUpdateContext)
  const [inventoryAllocations, setInventoryAllocations] = useState([])
  const [error, setError] = useState(null)
  const [snapshotDates, setSnapshotDates] = useState([])
  const [selectedSnapshotDate, setSelectedSnapshotDate] = useState('')
  const [isSnapshotDateDropdownOpen, setIsSnapshotDateDropdownOpen] = useState(false)
  const [isSnapshotDateLoading, setIsSnapshotDateLoading] = useState(true)
  const gridApi = useRef(null)
  const prevLang = useRef(i18n.language)
  const columnState = useMemo(() => {
    return {
      rowGroup: ['project_name'],
      pivot: ['item_availability'],
      value: ['item_total_cost'],
    }
  }, [])

  useEffect(() => {
    if (!gridApi?.current || columnsToHide.size === 0) return
    const allColumnStates = gridApi.current.getColumnDefs().map((column) => {
      return {
        colId: column.field,
        hide: columnsToHide.has(column.field),
      }
    })
    gridApi.current.applyColumnState({
      state: allColumnStates,
      ...columnState,
    })
  }, [columnsToHide, columnState])

  useEffect(() => {
    if (!gridApi?.current || areGroupedColumnsAutoPinned) return
    gridApi.current.setAutoGroupColumnDef({
      pinned: null,
    })
  }, [areGroupedColumnsAutoPinned])

  useEffect(() => {
    if (!gridApi?.current) return
    gridApi.current.setPivotMode(isPivotModeEnabled)
  }, [isPivotModeEnabled])

  const fetchAllocations = useCallback(async (asOfDate = null) => {
    if (gridApi.current && !gridApi.current.isDestroyed()) {
      gridApi.current.showLoadingOverlay()
    }

    try {
      if (!lastUpdate) return
      const { inventoryAllocations: _inventoryAllocations } = await reportService.fetchInventoryAllocations({
        historyDate: convertIsoStringToDate([undefined, null].includes(asOfDate) ? lastUpdate : asOfDate),
        companyId,
      })
      if (!_inventoryAllocations) throw new Error('Failed to fetch Inventory Allocations')
      if (itemAvailabilities.size > 0) {
        let i = 0
        while (i < _inventoryAllocations.length) {
          if (!itemAvailabilities.has(_inventoryAllocations[i]?.item_availability)) {
            _inventoryAllocations.splice(i, 1)
          } else {
            i++
          }
        }
      }
      setInventoryAllocations(_inventoryAllocations)
      setError(null)
    } catch (error) {
      setInventoryAllocations([])
      console.error('Failed to fetch Inventory Allocations:', error)
      setError(error)
    }
  }, [lastUpdate, companyId, itemAvailabilities])

  const fetchSnapshotDates = useCallback(async () => {
    if (!companyId) return
    try {
      setIsSnapshotDateLoading(true)
      const { jobs } = await katanaService.fetchSnapshotDates({
        companyId,
        limit: 10000,
      })
      if (!jobs) throw new Error('Failed to fetch Snapshot Dates')
      const _snapshotDates = Array.isArray(jobs)
        ? jobs
          .reduce((unique, job) => {
            const dateStr = job.start_date.split('T')?.[0]
            if (!unique.some((item) => item.label === dateStr)) {
              unique.push({ label: dateStr, value: job.start_date })
            }
            return unique
          }, [])
        : []
      setSnapshotDates(_snapshotDates)
      const latestSnapshotDate = _snapshotDates[0]?.label
      await fetchAllocations(latestSnapshotDate)
      setSelectedSnapshotDate(latestSnapshotDate)
      setError(null)
    } catch (error) {
      setSnapshotDates([])
      console.error('Failed to fetch Snapshot Dates:', error)
      setError(error)
    } finally {
      setIsSnapshotDateLoading(false)
    }
  }, [companyId, fetchAllocations])

  useEffect(() => {
    fetchSnapshotDates()
  }, [fetchSnapshotDates])

  const onGridReady = useCallback(async (params) => {
    gridApi.current = params.api
    if (!inventoryAllocations.length) gridApi.current.showLoadingOverlay()
    gridApi.current.applyColumnState(columnState)
    gridApi.current.onFilterChanged()
    gridApi.current.setPivotMode(isPivotModeEnabled)

    if (!areGroupedColumnsAutoPinned) {
      gridApi.current.setAutoGroupColumnDef({
        pinned: null,
      })
    }

    const allColumnStates = gridApi.current.getColumnDefs().map((column) => {
      return {
        colId: column.field,
        hide: columnsToHide.has(column.field),
      }
    })
    gridApi.current.applyColumnState({
      state: allColumnStates,
      ...columnState,
    })
    gridApi.current.refreshHeader()
  }, [
    gridApi, inventoryAllocations.length, isPivotModeEnabled,
    areGroupedColumnsAutoPinned, columnsToHide, columnState,
  ])

  const gridOptions = useMemo(() => {
    return {
      enableCharts: true,
      enableRangeSelection: true,
      defaultColDef: {
        filter: 'agSetColumnFilter',
        enableRowGroup: true,
        enablePivot: true,
      },
      groupDefaultExpanded: 0,
      suppressAggFuncInHeader: false,
    }
  }, [])

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: 'columns',
    }
  }, [])

  const columns = useMemo(() => {
    const baseColConfig = BASE_COL_CONFIG({ isTightLayout })
    const numericColConfig = NUMERIC_COL_CONFIG({ isTightLayout })

    const baseColumns = [
      {
        headerName: t('inventory_allocation.data.mo_name'),
        field: 'mo_name',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.item_availability'),
        field: 'item_availability',
        ...baseColConfig,
        pivot: true,
        cellDataType: 'text',
        pivotComparator: (valueA, valueB) => {
          const availabilityOrder = {
            NOT_AVAILABLE: 0,
            EXPECTED: 1,
            IN_STOCK: 2,
            PICKED: 3,
            PROCESSED: 4,
            DONE: 5,
            OTHER: 999,
          }
          const orderA = availabilityOrder[valueA] ?? availabilityOrder.OTHER
          const orderB = availabilityOrder[valueB] ?? availabilityOrder.OTHER
          return orderA - orderB
        },
      },
      {
        headerName: t('inventory_allocation.data.supplier_name'),
        field: 'supplier_name',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.project_name'),
        field: 'project_name',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.location_name'),
        field: 'location_name',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.mo_status'),
        field: 'mo_status',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.mo_product_name'),
        field: 'mo_product_name',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.mo_product_sku'),
        field: 'mo_product_sku',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.mo_product_variant_name'),
        field: 'mo_product_variant_name',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.mo_planned_quantity'),
        field: 'mo_planned_quantity',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.mo_planned_quantity),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_allocation.data.mo_actual_quantity'),
        field: 'mo_actual_quantity',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.mo_actual_quantity),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_allocation.data.mo_calculated_quantity'),
        field: 'mo_calculated_quantity',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.mo_calculated_quantity),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_allocation.data.item_is_purchasable'),
        field: 'item_is_purchasable',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.item_product_name'),
        field: 'item_product_name',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.item_product_sku'),
        field: 'item_product_sku',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.item_product_variant_name'),
        field: 'item_product_variant_name',
        ...baseColConfig,
        cellDataType: 'text',
      },
      {
        headerName: t('inventory_allocation.data.item_planned_quantity_per_unit'),
        field: 'item_planned_quantity_per_unit',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.item_planned_quantity_per_unit),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_allocation.data.item_actual_quantity'),
        field: 'item_actual_quantity',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.item_actual_quantity),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_allocation.data.lead_time'),
        field: 'lead_time',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.lead_time),
        valueFormatter: ({ value }) => integerFormatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_allocation.data.item_expected_date'),
        field: 'item_expected_date',
        ...baseColConfig,
        cellDataType: 'date',
        valueGetter: ({ data }) => data?.item_expected_date ? new Date(data.item_expected_date) : null,
        valueFormatter: ({ value }) => value ? value.toLocaleDateString() : '',
      },
      {
        headerName: t('inventory_allocation.data.item_calculated_quantity'),
        field: 'item_calculated_quantity',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.item_calculated_quantity),
        valueFormatter: ({ value }) => formatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_allocation.data.inventory_value_per_unit'),
        field: 'inventory_value_per_unit',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.inventory_value_per_unit),
        valueFormatter: ({ value }) => currencyFormatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_allocation.data.item_total_cost'),
        field: 'item_total_cost',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.item_total_cost),
        valueFormatter: ({ value }) => currencyFormatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_allocation.data.calculated_total_cost'),
        field: 'calculated_total_cost',
        ...numericColConfig,
        valueGetter: ({ data }) => parseQuantityString(data?.calculated_total_cost),
        valueFormatter: ({ value }) => currencyFormatter.format(value),
        cellDataType: 'number',
      },
      {
        headerName: t('inventory_allocation.data.order_date'),
        field: 'order_date',
        ...baseColConfig,
        cellDataType: 'date',
        valueGetter: ({ data }) => data?.order_date ? new Date(data.order_date) : null,
        valueFormatter: ({ value }) => value ? value.toLocaleDateString() : '',
      },
      {
        headerName: t('inventory_allocation.data.done_date'),
        field: 'done_date',
        ...baseColConfig,
        cellDataType: 'date',
        valueGetter: ({ data }) => data?.done_date ? new Date(data.done_date) : null,
        valueFormatter: ({ value }) => value ? value.toLocaleDateString() : '',
      },
      {
        headerName: t('inventory_allocation.data.value_type'),
        field: 'value_type',
        ...baseColConfig,
        cellDataType: 'text',
      },
    ]

    baseColumns.forEach((column) => {
      column.rowGroup = false
      column.aggFunc = null
    })

    rowGroups.forEach((field) => {
      const column = baseColumns.find((col) => col.field === field)
      if (!column) return
      column.rowGroup = true
    })

    values.forEach((field) => {
      const column = baseColumns.find((col) => col.field === field)
      if (!column) return
      column.aggFunc = 'sum'
    })

    return [...baseColumns]
  }, [t, rowGroups, values, isTightLayout])

  useEffect(() => {
    if (prevLang.current === i18n.language || !gridApi.current || gridApi.current.isDestroyed()) return
    prevLang.current = i18n.language
    gridApi.current.setColumnDefs([...columns])
  }, [i18n.language, columns])

  const handleSnapshotDateChange = async (value) => {
    if (value === selectedSnapshotDate) return
    setSelectedSnapshotDate(value)
    await fetchAllocations(value)
  }

  const toggleSnapshotDateDropdownOpen = () => setIsSnapshotDateDropdownOpen((prev) => !prev)

  return (
    <div className='dashboard-container'>
      <ReportTitle
        lastUpdate={lastUpdate}
        title={reportTitle}
      />
      <SnapshotDateSelector
        label={t('inventory_allocation.snapshot_date_selector.label')}
        options={snapshotDates}
        onChange={handleSnapshotDateChange}
        onClick={toggleSnapshotDateDropdownOpen}
        value={selectedSnapshotDate}
        open={isSnapshotDateDropdownOpen}
        isLoading={isSnapshotDateLoading}
      />
      <ReportActionButtons
        gridRef={gridApi}
      />
      <ReportGrid
        error={error}
        noDataTextPlaceholder={t('inventory_allocation.error.no_data')}
        data={inventoryAllocations}
        columns={columns}
        sideBar={sideBar}
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        gridSize={gridSize}
      />
    </div>
  )
}

export default InventoryAllocationReportView
