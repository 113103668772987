import {
  BlockStack,
  Layout,
  Page,
  Text,
} from '@shopify/polaris'
import { getCompanyId } from '@src/shared/utils/getUserInfo'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import ConnectCard from '@katana/components/connect/ConnectCard'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function ConnectView () {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    if (getCompanyId(Cookies.get('authToken')) !== null) return navigate('/login', { replace: true })
  }, [navigate])

  return (
    <Page>
      <BlockStack gap="400">
        <Text variant="heading3xl" as="h1">
          {t('connect.title')}
        </Text>
        <Layout>
          <Layout.Section>
            <ConnectCard />
          </Layout.Section>
        </Layout>
      </BlockStack>
    </Page>
  )
}

export default ConnectView
