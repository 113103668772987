import {
  Box,
  Text,
} from '@shopify/polaris'
import { Fragment } from 'react'
import { LEVELOPS_URL } from '@shared/utils/constant'
import levelOpsLogo from '@katana/img/logo_levelops_brand.png'

function LoginHeader ({ title, subtitle }) {
  return (
    <Fragment>
      <Box width='125px'>
        <a href={LEVELOPS_URL} target='_blank' rel='noreferrer'>
          <img src={levelOpsLogo} alt='Logo' />
        </a>
      </Box>
      <Box paddingBlock='600' maxWidth='400px'>
        <Text as='h2' variant='headingXl'>
          {title}
        </Text>
        <Text as='h3' variant='bodyMd' tone='subdued'>
          {subtitle}
        </Text>
      </Box>
    </Fragment>
  )
}

export default LoginHeader
