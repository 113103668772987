import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useNavigate, useLocation } from 'react-router-dom'
import levelOpsLogo from '@katana/img/logo_levelops_brand.png'
import {
  BlockStack,
  Card,
  Box,
  Text,
  TextField,
  Form,
  FormLayout,
  Button,
  InlineStack,
  Banner,
  Page,
} from '@shopify/polaris'
import '@src/shared/styles/login.css'
import { useTranslation } from 'react-i18next'
import { LanguageSelector } from '@katana/components/shared/LanguageSelector'
import { isTokenExpired, getUserId, getUserEmail } from '@shared/utils/getUserInfo'
import { BACKTRACK_STATES, LEVELOPS_URL } from '@shared/utils/constant'
import { userService } from '@shared/services/userService'
import LoginFooter from '@shared/components/LoginFooter'
import LoginEmptyState from '@shared/components/LoginEmptyState'

function ResetPasswordView () {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  const [email, setEmail] = useState(null)
  const [token, setToken] = useState(null)
  const [userId, setUserId] = useState(null)
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(null)
  const [isResetPasswordDisabled, setIsResetPasswordDisabled] = useState(true)
  const [isResetPasswordButtonLoading, setIsResetPasswordButtonLoading] = useState(false)
  const [bannerState, setBannerState] = useState({
    show: false,
    status: '',
    message: '',
  })
  const [isEmptyState, setIsEmptyState] = useState(true)

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language)
    Cookies.set('preferredLanguage', language, { expires: 365 })
  }

  useEffect(() => {
    if (Cookies.get('authToken')) {
      navigate('/dashboard')
    }
  }, [navigate])

  const isTokenValid = ({ token }) => {
    if (!token) return false
    const _isTokenExpired = isTokenExpired(token)
    if (_isTokenExpired) return false
    const userId = getUserId(token)
    if (!userId) return false
    const email = getUserEmail(token)
    if (!email) return false
    setToken(token)
    setUserId(userId)
    setEmail(email)
    return true
  }

  useEffect(() => {
    const loadTimeMs = 400
    const timer = setTimeout(() => {
      setIsEmptyState(false)
      const params = new URLSearchParams(window.location.search)
      const tokenParam = params.get('token')
      if (!isTokenValid({ token: tokenParam })) {
        const errorStateParam = encodeURIComponent(BACKTRACK_STATES.RESET_PASSWORD.ERROR)
        navigate(`/login?backtrack_state=${errorStateParam}`)
      }
    }, loadTimeMs)
    return () => clearTimeout(timer)
  }, [navigate])

  useEffect(() => {
    const savedLanguage = Cookies.get('preferredLanguage')
    if (savedLanguage) i18n.changeLanguage(savedLanguage)
    else i18n.changeLanguage('en')
  }, [i18n])

  useEffect(() => {
    if (newPassword === confirmNewPassword || !newPassword || !confirmNewPassword) return setConfirmNewPasswordError(null)
    setConfirmNewPasswordError(t('reset_password.confirm_new_password_error'))
  }, [t, newPassword, confirmNewPassword])

  useEffect(() => {
    if (newPassword && confirmNewPassword && !confirmNewPasswordError) return setIsResetPasswordDisabled(false)
    return setIsResetPasswordDisabled(true)
  }, [newPassword, confirmNewPassword, confirmNewPasswordError])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tokenParam = params.get('token')
    setToken(tokenParam)
  }, [location])

  const handleResetPassword = async () => {
    try {
      if (!userId) throw new Error('User ID must be defined')

      setBannerState({ ...bannerState, show: false })
      setIsResetPasswordButtonLoading(true)

      await userService.modifyUserPassword({
        id: userId,
        data: {
          user_new_password: newPassword,
        },
        requestHeaders: {
          Authorization: `Bearer ${token}`,
        },
      })

      const successStateParam = encodeURIComponent(BACKTRACK_STATES.RESET_PASSWORD.SUCCESS)
      navigate(`/login?backtrack_state=${successStateParam}`)
    } catch (error) {
      console.error('Reset password error:', error)
      setBannerState({
        show: true,
        status: 'critical',
        message: t('reset_password.update_password_failure'),
      })
    } finally {
      setIsResetPasswordButtonLoading(false)
    }
  }

  const content = (
    <Page
      primaryAction={
        <LanguageSelector
          value={i18n.language}
          onChange={handleLanguageChange}
        />
      }
    >
      <Card padding='1000'>
        <BlockStack gap='4' inlineAlign='center'>
          <BlockStack align='center'>
            <Box width='125px'>
              <a href={LEVELOPS_URL} target='_blank' rel='noreferrer'>
                <img src={levelOpsLogo} alt='Logo' />
              </a>
            </Box>
            <Box paddingBlock='600' maxWidth='400px'>
              <Text as='h2' variant='headingXl'>
                {t('reset_password.title')}
              </Text>
              {email && (
                <Text as='h3' variant='bodyMd' tone='subdued'>
                  {t('reset_password.subtitle')} {email}
                </Text>
              )}
            </Box>
            {bannerState.show && (
              <Box paddingBlockEnd='600' maxWidth='400px'>
                <Banner
                  title={bannerState.message}
                  onDismiss={() => setBannerState({ ...bannerState, show: false })}
                  tone={bannerState.status}
                />
              </Box>
            )}
            <Box width='400px'>
              <Form onSubmit={handleResetPassword}>
                <FormLayout>
                  <TextField
                    label={t('reset_password.new_password')}
                    type='password'
                    value={newPassword}
                    onChange={setNewPassword}
                    autoComplete='new-password'
                    inputMode='text'
                  />
                  <TextField
                    label={t('reset_password.confirm_new_password')}
                    type='password'
                    value={confirmNewPassword}
                    onChange={setConfirmNewPassword}
                    autoComplete='off'
                    inputMode='text'
                    error={confirmNewPasswordError}
                  />
                </FormLayout>
                <Box paddingBlock='600'>
                  <InlineStack align='center'>
                    <Button
                      variant='primary'
                      fullWidth
                      size='large'
                      disabled={isResetPasswordDisabled}
                      loading={isResetPasswordButtonLoading}
                      submit
                    >
                      {t('reset_password.reset_password')}
                    </Button>
                  </InlineStack>
                </Box>
              </Form>
              <LoginFooter />
            </Box>
          </BlockStack>
        </BlockStack>
      </Card>
    </Page>
  )

  const displayContent = () => isEmptyState ? <LoginEmptyState nBodyLines={4} nFooterLines={0} /> : content

  return (
    <div id='login-container'>
      {displayContent()}
    </div>
  )
}

export default ResetPasswordView
