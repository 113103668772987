import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'

export function getCompanyId (jwtToken = null) {
  try {
    const _jwtToken = jwtToken !== null ? jwtToken : Cookies.get('authToken')
    if (!_jwtToken) return null
    const decodedToken = jwtDecode(_jwtToken)
    if (typeof decodedToken.company_id === 'number') {
      return decodedToken.company_id
    }
    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

export function getUserId (jwtToken = null) {
  try {
    const _jwtToken = jwtToken !== null ? jwtToken : Cookies.get('authToken')
    if (!_jwtToken) return null
    const decodedToken = jwtDecode(_jwtToken)
    if (typeof decodedToken.user_id === 'number') {
      return decodedToken.user_id
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export function getUserEmail (jwtToken = null) {
  try {
    const _jwtToken = jwtToken !== null ? jwtToken : Cookies.get('authToken')
    if (!_jwtToken) return null
    const decodedToken = jwtDecode(_jwtToken)
    return decodedToken.user_email
  } catch (error) {
    console.error(error)
    return null
  }
}

export function isTokenExpired (jwtToken = null) {
  try {
    const _jwtToken = jwtToken !== null ? jwtToken : Cookies.get('authToken')
    if (!_jwtToken) return true

    const decodedToken = jwtDecode(_jwtToken)
    const currentTime = Math.floor(Date.now() / 1000)

    return !decodedToken.exp || decodedToken.exp < currentTime
  } catch (error) {
    console.error(error)
    return true
  }
}
