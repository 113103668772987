import {
  useState,
  useCallback,
  Fragment,
  useContext,
} from 'react'
import {
  Card,
  BlockStack,
  IndexTable,
  Button,
  EmptyState,
  Text,
  Autocomplete,
  InlineGrid,
  Icon,
  InlineError,
  Link,
} from '@shopify/polaris'
import { SearchIcon, XSmallIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import { EditableCell } from '@shared/components/EditableCell'
import { DataUpdateContext } from '@src/shared/context/context'
import { getProductsUrl } from '@src/shared/utils'
import { COMPONENT_PRODUCT_MAX_LENGTH } from '@bom/utils/constants'
import { trimProductName } from '@bom/utils'

export const AddMaterialsCard = ({
  materials,
  onSearchChange,
  onRemoveMaterial,
  searchLabel,
  onSelectProduct,
  showRequiredError,
  onQuantityChange = () => {},
  isQuantityReadonly = false,
}) => {
  const { t } = useTranslation()
  const { shopName } = useContext(DataUpdateContext)
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = useCallback((value) => {
    setInputValue(value)
    if (value.length > 0) {
      onSelectProduct(value)
      setInputValue('')
    }
  }, [onSelectProduct])

  const emptyStateMarkup = (
    <EmptyState
      heading={t('bundles.empty_state.title')}
      image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
      imageContained
    >
      <Text as="p" variant="bodyMd" color="subdued">
        {t('bundles.empty_state.description')}
      </Text>
    </EmptyState>
  )

  const outputProductDescription = (material) => {
    const productName = (
      <Text variant="bodyMd" fontWeight="bold">
        {trimProductName(material.product.product_name, COMPONENT_PRODUCT_MAX_LENGTH)}
      </Text>
    )

    const variantName = (
      <Fragment>
        {material.product.product_variant_name && (
          <Text variant="bodySm" color="subdued">
            {material.product.product_variant_name}
          </Text>
        )}
      </Fragment>
    )

    const textContent = (
      <Fragment>
        {productName}
        {variantName}
      </Fragment>
    )

    if (!shopName) return textContent

    const externalProductId = material?.product?.external_product_id
    if (!externalProductId) return textContent

    const externalVariantId = material?.product?.external_variant_id
    if (!externalVariantId) return textContent

    const productsUrl = getProductsUrl(shopName)
    if (!productsUrl) return textContent

    return (
      <Fragment>
        <Link
          monochrome
          removeUnderline
          url={`${productsUrl}/${externalProductId}/variants/${externalVariantId}`}
          target="_blank"
        >
          {productName}
        </Link>
        {variantName}
      </Fragment>
    )
  }

  const displayQuantityContent = (material) => isQuantityReadonly
    ? (
      <Text>
        {material.quantity}
      </Text>
    )
    : (
      <>
        <EditableCell
          fieldID={`quantity_${material.material_id}`}
          initialValue={material.quantity.toString()}
          onSave={(newValue) => onQuantityChange(material.material_id, newValue)}
          minValue="1"
        />
        {(!material.quantity || isNaN(material.quantity)) && (
          <InlineError
            message={t('bundles.quantity_required')}
            fieldID={`quantity_${material.material_id}`}
          />
        )}
      </>
    )

  return (
    <Card title={t('bundles.materials')}>
      <BlockStack gap='400'>
        <BlockStack gap='200'>
          <Text as="h2" variant="headingMd">
            {t('bundles.add_materials')}
          </Text>
          <BlockStack gap="100">
            <InlineGrid columns="1fr auto" gap="100">
              <Autocomplete
                id="materialSearch"
                options={[]}
                selected={[]}
                onSelect={() => {}}
                textField={
                  <Autocomplete.TextField
                    onChange={handleInputChange}
                    value={inputValue}
                    placeholder={t('bundles.search_for_products')}
                    autoComplete="off"
                    prefix={<Icon source={SearchIcon} />}
                  />
                }
              />
              <Button
                onClick={onSearchChange}
                accessibilityLabel={searchLabel}
                size='large'
              >
                {t('button.browse')}
              </Button>
            </InlineGrid>
            { showRequiredError && (
              <InlineError
                message={t('bundles.material_required')}
                fieldID="materialSearch"
              />
            )}
          </BlockStack>
        </BlockStack>
        <IndexTable
          itemCount={materials.length}
          emptyState={emptyStateMarkup}
          headings={[
            { title: t('bundles.product') },
            { title: t('bundles.quantity') },
            { title: '' },
          ]}
          selectable={false}
        >
          {materials.map((material, index) => (
            <IndexTable.Row
              id={material.material_id}
              key={material.material_id}
              position={index}
            >
              <IndexTable.Cell>
                <BlockStack gap="100">
                  {outputProductDescription(material)}
                </BlockStack>
              </IndexTable.Cell>
              <IndexTable.Cell>
                {displayQuantityContent(material)}
              </IndexTable.Cell>
              <IndexTable.Cell>
                <Button
                  onClick={() => onRemoveMaterial(material.material_id, material.product_id)}
                  plain
                  icon={XSmallIcon}
                  variant="tertiary"
                  accessibilityLabel={t('bundles.remove_material', { name: material.product.product_name })}
                />
              </IndexTable.Cell>
            </IndexTable.Row>
          ))}
        </IndexTable>
      </BlockStack>
    </Card>
  )
}
