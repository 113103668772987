import React from 'react'
import PurchaseOrdersImportExportCard from '@src/apps/katana/components/import-export/PurchaseOrdersImportExportCard'
import SuppliersImportExportCard from '@src/apps/katana/components/import-export/SuppliersImportExportCard'
import {
  Page,
  Text,
  Layout,
  BlockStack,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const ImportExportView = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <BlockStack gap="400">
        <Text variant="heading3xl" as="h1">
          {t('data_import_export.title')}
        </Text>
        <Layout>
          <Layout.Section>
            <PurchaseOrdersImportExportCard />
          </Layout.Section>
          <Layout.Section>
            <SuppliersImportExportCard />
          </Layout.Section>
        </Layout>
      </BlockStack>
    </Page>
  )
}

export default ImportExportView
