import React from 'react'
import '@shared/styles/top-navigation.css'
import { Box, InlineStack } from '@shopify/polaris'
import { CompanySelector } from '@src/shared/components/CompanySelector'

const TopNavigation = () => {
  return (
    <InlineStack align='end' blockAlign='center'>
      <Box as='div' paddingInlineEnd='500'>
        <CompanySelector />
      </Box>
    </InlineStack>
  )
}

export default TopNavigation
