import { backendRequest } from '@shared/utils/axiosInstance'

export const authService = {
  login: async ({ data }) => {
    try {
      const response = await backendRequest({
        method: 'POST',
        url: '/auth/login',
        data,
      })
      return response.data
    } catch (error) {
      console.error('Error with login:', error.message)
      throw error
    }
  },
  signUp: async ({ data }) => {
    try {
      const response = await backendRequest({
        method: 'POST',
        url: '/auth/signup',
        data,
      })
      return response.data
    } catch (error) {
      console.error('Error with sign up:', error.message)
      throw error
    }
  },
  resetPassword: async ({ data, requestHeaders }) => {
    try {
      const response = await backendRequest({
        method: 'POST',
        url: '/auth/reset-password',
        data,
        requestHeaders,
      })
      return response.data
    } catch (error) {
      console.error('Error with reset password:', error.message)
      throw error
    }
  },
}
