import { backendRequest } from '@shared/utils/axiosInstance'

export const fetchBomsList = async (companyId, limit, offset, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/boms?company_id=${companyId}&limit=${limit}&skip=${offset}&populate=output_product&status=active,draft&sort=-updated_at`,
      data: {},
      appBridge,
    })
    return response.data
  } catch (error) {
    throw new Error(`Error fetching BOMs list: ${error.message}`)
  }
}

export const fetchBomDetails = async (bomId, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/boms/${bomId}?populate=output_product,materials`,
      data: {},
      appBridge,
    })
    return response.data
  } catch (error) {
    throw new Error(`Error fetching BOMs details: ${error.message}`)
  }
}

export const fetchActiveBoms = async (companyId, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/boms?company_id=${companyId}&populate=output_product&status=active`,
      data: {},
      appBridge,
    })
    return response.data
  } catch (error) {
    throw new Error(`Error fetching BOMs Finished Products: ${error.message}`)
  }
}

export const fetchBomMaterials = async (bomId, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/boms/${bomId}?populate=materials`,
      data: {},
      appBridge,
    })
    return response.data
  } catch (error) {
    throw new Error(`Error fetching BOMs Materials: ${error.message}`)
  }
}

export const createBom = async (bomData, appBridge) => {
  const payload = {
    bom_id: bomData.bom_id,
    company_id: bomData.company_id,
    output_quantity: bomData.output_quantity,
    output_product_id: bomData.output_product_id,
    output_product: {
      inventory_type: 'finished_product',
    },
    materials: bomData.materials.map((material) => ({
      material_id: material.material_id,
      product_id: material.product_id,
      company_id: material.company_id,
      quantity: material.quantity,
      product: {
        inventory_type: 'material',
      },
    })),
  }
  try {
    const response = await backendRequest({
      method: 'PUT',
      url: `/obius/boms/${payload.bom_id}`,
      data: payload,
      appBridge,
    })
    return response.data
  } catch (error) {
    throw new Error(`Error creating BOM: ${error.message}`)
  }
}

export const updateBom = async (bomData, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'PUT',
      url: `/obius/boms/${bomData.bom_id}`,
      data: bomData,
      appBridge,
    })
    return response.data
  } catch (error) {
    throw new Error(`Error updating BOM: ${error.message}`)
  }
}

export const patchBom = async ({ bomId, update, appBridge }) => {
  try {
    const response = await backendRequest({
      method: 'PATCH',
      url: `/obius/boms/${bomId}`,
      data: update,
      appBridge,
    })
    return response.data
  } catch (error) {
    throw new Error(`Error patching BOM: ${error.message}`)
  }
}

export const deleteBom = async (bomId, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'DELETE',
      url: `/obius/boms/${bomId}`,
      appBridge,
    })
    return response.data
  } catch (error) {
    throw new Error(`Error deleting BOM: ${error.message}`)
  }
}

export const importBoms = async (csvData, companyId, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'PUT',
      url: '/obius/boms',
      requestHeaders: {
        'x-obius-format': 'csv',
      },
      data: { csvData, companyId },
      appBridge,
    })
    return response.data
  } catch (error) {
    throw new Error(`Error importing BOMs: ${error.message}`)
  }
}

export const exportBoms = async (companyId, limit, appBridge) => {
  try {
    const response = await backendRequest({
      method: 'GET',
      url: `/obius/boms?company_id=${companyId}&limit=${limit}&skip=0&populate=materials,output_product&status=active,draft`,
      requestHeaders: {
        'x-obius-format': 'csv',
      },
      data: {},
      appBridge,
    })
    return response.data
  } catch (error) {
    throw new Error(`Error exporting BOMs: ${error.message}`)
  }
}
