import { useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import { useTranslation } from 'react-i18next'
import { Loader } from '@standalone/components/common/Loader'

const autoSizeStrategy = {
  type: 'fitCellContents',
}

export const AgGridTable = ({ rowData, colDefs, defaultColDef, onCellValueChanged, grandTotalRow, gridOptions, initialGroupOrderComparator, onFirstDataRendered, onGridReady, autoGroupColumnDef, sideBar, pivotMode }) => {
  const { i18n } = useTranslation()

  const defaultDefaultColDef = useMemo(() => {
    return {
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      suppressMenu: true,
    }
  }, [])

  const defaultAutoGroupColumnDef = useMemo(() => {
    return {
      filter: 'agGroupColumnFilter',
      pinned: 'left',
    }
  }, [])
  return (
    <div
      className='ag-theme-quartz'
      style={{ height: '100%', width: '100%' }}
    >

      <AgGridReact
        key={i18n.language}
        defaultColDef={defaultColDef || defaultDefaultColDef}
        columnDefs={colDefs}
        rowData={rowData}
        loadingOverlayComponent={Loader}
        autoSizeStrategy={autoSizeStrategy}
        columnMenu='new'
        onCellValueChanged={onCellValueChanged}
        grandTotalRow={grandTotalRow}
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        suppressAggFuncInHeader
        autoGroupColumnDef={autoGroupColumnDef || defaultAutoGroupColumnDef}
        initialGroupOrderComparator={initialGroupOrderComparator}
        onFirstDataRendered={onFirstDataRendered}
        sideBar={sideBar}
        pivotMode={pivotMode}
        removePivotHeaderRowWhenSingleValueColumn
        groupDisplayType='multipleColumns'
      />
    </div>
  )
}
