import '@shopify/polaris/build/esm/styles.css'
import { useContext, useState, useCallback } from 'react'
import {
  Page,
  Card,
  SkeletonPage,
  InlineStack,
  Box,
  BlockStack,
} from '@shopify/polaris'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { backendRequest } from '@shared/utils/axiosInstance'
import { DataUpdateContext } from '@shared/context/context'
import { PlanCard } from '@shared/components/PlanCard'
import BillingInterval from '@shared/components/BillingInterval'
import { BILLING_INTERVAL } from '@shared/utils/constant'
import { convertPlanPriceToText, saveMoney } from '@shared/utils'

export const PlanSelectionView = () => {
  const navigate = useNavigate()
  const { companyId, appBridge, subscriptionPlan } = useContext(DataUpdateContext)
  const [loadingFreePlan, setLoadingFreePlan] = useState(false)
  const [loadingPremiumPlan, setLoadingPremiumPlan] = useState(false)
  const [billingInterval, setBillingInterval] = useState(BILLING_INTERVAL.YEARLY.id)
  const [priceFactor, setPriceFactor] = useState(BILLING_INTERVAL.YEARLY.factor)
  const { t, i18n } = useTranslation()

  const handleMonthlyClick = useCallback(() => {
    if (billingInterval === BILLING_INTERVAL.MONTHLY.id) return
    setBillingInterval(BILLING_INTERVAL.MONTHLY.id)
    setPriceFactor(BILLING_INTERVAL.MONTHLY.factor)
  }, [billingInterval])

  const handleYearlyClick = useCallback(() => {
    if (billingInterval === BILLING_INTERVAL.YEARLY.id) return
    setBillingInterval(BILLING_INTERVAL.YEARLY.id)
    setPriceFactor(BILLING_INTERVAL.YEARLY.factor)
  }, [billingInterval])

  const handleFreePlanSelection = async () => {
    setLoadingFreePlan(true)
    try {
      const { data: { return_url: returnUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'free',
        },
      })
      navigate(`/shopify/subscription/return?${returnUrl.split('?')[1]}`)
    } catch (error) {
      console.error('Failed to create free subscription.', error)
    } finally {
      setLoadingFreePlan(false)
    }
  }

  const handlePremiumPlanSelection = async () => {
    setLoadingPremiumPlan(true)
    try {
      const { data: { confirmation_url: confirmationUrl } } = await backendRequest({
        method: 'POST',
        url: '/shopify/subscriptions',
        appBridge,
        data: {
          company_id: companyId,
          plan: 'premium',
          billing_interval: billingInterval,
        },
      })
      if (confirmationUrl) {
        window.open(confirmationUrl, '_top')
      }
    } catch (error) {
      console.error('Failed to create premium subscription.', error)
    } finally {
      setLoadingPremiumPlan(false)
    }
  }

  const freePlanItems = [
    t('plan.replen.free.items.variants'),
    t('plan.replen.free.items.alerts'),
    t('plan.replen.free.items.email'),
    t('plan.replen.free.items.data_refresh'),
  ]

  const starterPlanItems = [
    t('plan.replen.starter.items.variants'),
    t('plan.replen.starter.items.alerts'),
    t('plan.replen.starter.items.email'),
    t('plan.replen.starter.items.export'),
    t('plan.replen.starter.items.data_refresh'),
    t('plan.replen.starter.items.customize_settings'),
  ]

  const freePlanButtonLabel = subscriptionPlan === 'free' ? t('button.current_plan') : t('button.downgrade')

  const premiumPlanButtonLabel = subscriptionPlan === 'premium' ? t('button.current_plan') : t('button.upgrade')
  const isFreePlanDisabled = subscriptionPlan === 'free' || !subscriptionPlan
  const isPremiumPlanDisabled = subscriptionPlan === 'premium'

  const content = companyId
    ? (
      <BlockStack
        align='center'
        inlineAlign='center'
        gap='500'
      >
        <BillingInterval
          billingInterval={billingInterval}
          handleMonthlyClick={handleMonthlyClick}
          handleYearlyClick={handleYearlyClick}
        />
        <InlineStack gap="400" align="center">
          <PlanCard
            title={t('plan.replen.free.title')}
            price={
              convertPlanPriceToText(
                t('plan.replen.free.price'),
                t('plan.replen.free.price'),
                priceFactor,
                i18n.language,
              )
            }
            priceFactor={priceFactor}
            items={freePlanItems}
            buttonLabel={freePlanButtonLabel}
            handleLoading={loadingFreePlan}
            handlePlanSelection={handleFreePlanSelection}
            disabled={isFreePlanDisabled}
          />
          <PlanCard
            title={t('plan.replen.starter.title')}
            price={
              convertPlanPriceToText(
                t('plan.replen.starter.price'),
                t('plan.replen.free.price'),
                priceFactor,
                i18n.language,
              )
            }
            priceFactor={priceFactor}
            frequency={t('plan.replen.starter.month')}
            saveMoney={saveMoney(t('plan.replen.starter.price'), priceFactor, t('plan.save_money'), i18n.language.startsWith('en'))}
            isSaveMoneyShown={billingInterval === BILLING_INTERVAL.YEARLY.id}
            items={starterPlanItems}
            trial={t('plan.replen.starter.trial')}
            buttonLabel={premiumPlanButtonLabel}
            handleLoading={loadingPremiumPlan}
            handlePlanSelection={handlePremiumPlanSelection}
            disabled={isPremiumPlanDisabled}
          />
        </InlineStack>
      </BlockStack>
    )
    : (
      <SkeletonPage>
        <InlineStack gap="400" align="center">
          <Card>
            <Box minHeight='100px' minWidth="350px" padding='300' />
          </Card>
        </InlineStack>
      </SkeletonPage>
    )

  return (
    <Page>
      {content}
    </Page>
  )
}
